import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/language.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { FirebaseService } from '../../../shared/firebase.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  isAdmin: boolean;
  userEmail: string;

  constructor(
    public authService: AuthService,
    private firebase: FirebaseService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private languageService: LanguageService
  ) {
    this.matIconRegistry.addSvgIcon(
      'navLogo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.navLogo)
    );
  }

  ngOnInit() {
    this.checkAdminAccount();
  }

  navigateToHome() {
    this.router.navigate(['app-home']);
  }

  navigateToPlayers() {
    this.router.navigate(['players']);
  }

  navigateToTeams() {
    this.router.navigate(['teams']);
  }

  navigateToUsers() {
    this.router.navigate(['users']);
  }

  navigateToUsersInfo() {
    this.router.navigate(['users-info']);
  }

  navigateToSkills() {
    this.router.navigate(['skills']);
  }

  navigateToPlayerDrills() {
    this.router.navigate(['player-drills']);
  }

  navigateToSkillList() {
    this.router.navigate(['skill-list']);
  }

  navigateToSupporteds() {
    this.router.navigate(['supporteds']);
  }

  navigateToProfile() {
    this.router.navigate(['profile']);
  }

  checkAdminAccount() {
    this.authService.afAuth.authState.subscribe((user) => {
      this.userEmail = user.email;
      // console.log(this.userEmail);

      this.firebase.getEmailAdmin(this.userEmail).subscribe((data) => {
        this.isAdmin = false;
        const resultQty = data.length;
        // console.log(resultQty);
        if (resultQty > 0) {
          this.isAdmin = true;
        }
        // console.log(this.isAdmin);
      });
    });
  }

  get languages(): string[][] {
    return this.languageService.languages;
  }

  get language(): string {
    return this.languageService.language;
  }

  set language(value: string) {
    this.languageService.language = value;
  }

  get navLogo() {
    return environment.versions.navLogo;
  }

  onLanguageChange(e): void {
    console.log(e.value);
  }
}
