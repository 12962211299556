import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Skill } from '../models/skill';
import { FirebaseService } from '../shared/firebase.service';
import { NotificationService } from '../shared/notification.service';


@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public displayedColumns: string[] = ['select', 'category', 'code', 'type', 'icon', 'active', 'en-displayCatgory',
                                        'en-displaySkill', 'es-displayCatgory', 'es-displaySkill', 'actions'];
  public dataSource = new MatTableDataSource();
  private initialSelection = [];
  private allowMultiSelect = true;
  private selection = new SelectionModel<any>(this.allowMultiSelect, this.initialSelection);

  private skillList: Skill[];

  public title = 'Skills';

  public isLoadingResults = true;

  constructor(private firebase: FirebaseService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private notification: NotificationService) {}


  ngOnInit() {
    this.firebase.getSkills().subscribe(data => {
      this.skillList = [];
      data.forEach(s => {
        this.skillList.push(s as Skill);
      });
      this.skillList.forEach(sk => {
        this.firebase.getSkillLanguages(sk.skillId).subscribe(lang => {
          lang.forEach(l  => {
            if (l.langId === 'en') {
              sk.enDisplayCategory = l.displayCategory;
              sk.enDisplaySkill = l.displaySkill;
            } else {
              sk.esDisplayCategory = l.displayCategory;
              sk.esDisplaySkill = l.displaySkill;
            }
          });
        });
      });
      this.dataSource = new MatTableDataSource(this.skillList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.selection = new SelectionModel<Skill>(this.allowMultiSelect, this.initialSelection);
      this.isLoadingResults = false;
    });
  }

  doFilter(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

    /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Skill): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} skill ${row.code}`;
  }

  onEditActive(row) {
    this.isLoadingResults = true;
    this.firebase.editActiveSkill(row.skillId, !row.active);
    this.isLoadingResults = false;
  }

  /*onNewSkill(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '55%';
    const dialogRef = this.dialog.open(SkillDialogFormComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.notification.success('Skill added');
      }
    });
  }*/

  /*onEditSkill(row) {
    const dialogRef = this.dialog.open(SkillFormComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.notification.success('Skill edited');
      }
    });
  }*/

  /*onDeleteSkill(row) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '30%',
      disableClose: true,
      autoFocus: true,
      data: { action: 'delete skill', object: row }
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.notification.success('Skill deleted');
      }
    });
  }*/

 /*translateActive(value: string) {
    if (value === '2') {
      return false;
    } else {
      return true;
    }
  }*/

}

