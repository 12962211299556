import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.css']
})
export class DeleteConfirmationDialogComponent implements OnInit {

  public changingObjectName: string;
  public actionName: string;
  private confirmationState = false;

  constructor(public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data.action === 'delete player') {
      this.changingObjectName = this.data.object.firstName + ' ' + this.data.object.lastName;
      this.actionName = 'delete';
    }
    if (this.data.action === 'delete team') {
      this.changingObjectName = this.data.object.name;
      this.actionName = 'delete';
    }
    if (this.data.action === 'delete user') {
      this.changingObjectName = this.data.object.name;
      this.actionName = 'delete';
    }
    if (this.data.action === 'delete user info') {
      this.changingObjectName = this.data.object.name + ' info';
      this.actionName = 'delete';
    }
    if (this.data.action === 'delete drill') {
      this.changingObjectName = this.data.object.drillName;
      this.actionName = 'Borrar';
    }
  }

  onClose() {
    this.dialogRef.close(this.confirmationState);
  }

  onConfirm() {
    this.confirmationState = true;
    this.onClose();
  }

}
