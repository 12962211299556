<app-header></app-header>

<div class="container">
  <div class="row mt-4">
    <h1 i18n="@@profile-title">User Profile</h1>
  </div>

    <div *ngIf="step === 1">
      <div class="row">
        <h2 i18n="@@profile-info-header">User information</h2>
      </div>
        <form #form="ngForm" class="normal-form py-4">
          <input class="collapse" type="file" accept="image/*" (change)="onFileChangeEvent($event)" #file>
          <div>
            <img [src]="profilePictureUrl" width="200" height="200" class="md-card-image rounded-circle mb-3"
              [ngClass]="{'opacity-50':isProfilePhotoChanged}" alt="profile picture" (click)="file.click()">
            <div *ngIf="isProfilePhotoChanged" class="spinner-border profile-pic-spinner" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        <!--!!TODO<fire-photo i18n="@@profile-pic" class="profile-pic mb-3" [value]="profilePictureUrl" [thumbHeight]="200" [thumbWidth]="200" (complete)="profilePhotoDisabled = true" (error)="onError($event)" (file)="onFile()" (valueChange)="onValueChange($event)" [paramDir]="paramDir">
        </fire-photo>-->
        <mat-grid-list cols="1">
          <div class="row">
            <mat-form-field color="accent" class="col-md-6 col-12">
              <input i18n-placeholder="@@first-name-title" matInput placeholder="First Name" [(ngModel)]="firstName"
                name="firstName" required />
              <mat-error i18n="@@required-title">This field is required.</mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field color="accent" class="col-md-6 col-12">
              <input i18n-placeholder="@@last-name-title" matInput placeholder="Last Name" [(ngModel)]="lastName"
                name="lastName" required />
              <mat-error i18n="@@required-title">This field is required.</mat-error>
            </mat-form-field>
          </div>
          <div class="row my-2">
            <div class="col-md-6">
              <span i18n="@@about-type-title">You are a:</span>
            </div>
          </div>
          <div class="row mb-2">
            <mat-radio-group color="accent" class="col-md-6 col-12" [(ngModel)]="type" name="type" [disabled]="disableRole" required>
              <mat-radio-button i18n="@@about-type-player-title" class="mr-3" value="PL">Player</mat-radio-button>
              <mat-radio-button i18n="@@about-type-parent-title" class="mr-3" value="PA">Parent</mat-radio-button>
              <mat-radio-button i18n="@@about-type-coach-title" class="" value="CO">Coach</mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="isPlayer">
            <div class="row mt-4 mb-2">
              <div>
                <h5 i18n="@@about-player-title">Player Information</h5>
              </div>
            </div>
            <div class="row">
              <mat-form-field color="primary" class="col-md-6 col-12">
                <input matInput i18n-placeholder="@@about-birthday-title"
                  [(ngModel)]="birthday"
                  placeholder="Birthday" [matDatepicker]="picker"
                  name="birthday" required />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field color="accent" class="col-md-6 col-12">
                <mat-select i18n-placeholder="@@about-position-title" placeholder="Preferred Position"
                  [(ngModel)]="preferredPosition" name="preferredPosition" required>
                  <mat-option i18n="@@about-position-goalkeeper-title" value="GK'">
                    GK
                  </mat-option>
                  <mat-option i18n="@@about-position-centralback-title" value="CB">
                    CB
                  </mat-option>
                  <mat-option i18n="@@about-position-rb-title" value="RB">
                    RB
                  </mat-option>
                  <mat-option i18n="@@about-position-lb-title" value="LB">
                    LB
                  </mat-option>
                  <mat-option i18n="@@about-position-cdm-title" value="CDM">
                    CDM
                  </mat-option>
                  <mat-option i18n="@@about-position-cm-title" value="CM">
                    CM
                  </mat-option>
                  <mat-option i18n="@@about-position-rm-title" value="RM - RW">
                    RM / RW
                  </mat-option>
                  <mat-option i18n="@@about-position-lm-title" value="LM - LW">
                    LM / LW
                  </mat-option>
                  <mat-option i18n="@@about-position-fw-title" value="FW - ST">
                    FW / ST
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field color="accent" class="col-md-6 col-12">
                <mat-select i18n-placeholder="@@about-side-title" placeholder="Strong Side" [(ngModel)]="strongerSide"
                  name="strongerSide" required>
                  <mat-option i18n="@@about-side-left-title" value="2">
                    Left
                  </mat-option>
                  <mat-option i18n="@@about-side-right-title" value="1">
                    Right
                  </mat-option>
                  <mat-option i18n="@@about-side-both-title" value="3">
                    Both
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-grid-list>
        <div class="button-row mt-4" mat-dialog-actions>
          <button i18n="@@update-title" mat-raised-button color="accent" class="py-2" (click)="save()"
            [disabled]="form.invalid">
            Update
          </button>
        </div>
      </form>
    </div>
    <hr />
    <div *ngIf="step === 1" class="mb-5">
      <h2 i18n="@@profile-security-header">User security</h2>
      <p class="lead" i18n="@@profile-security-reset-password-header">Reset password</p>
      <p i18n="@@profile-security-reset-password-explanation">We will send you an email with a link to reset your password</p>
      <div class="button-row mt-4">
        <button i18n="@@profile-security-reset-password-button" class="py-2 btn btn-outline-dark" (click)="resetPassword()">
          Reset password
        </button>
      </div>
      <div *ngIf="showResetPasswordResult">
        <ng-container *ngIf="resetPasswordResult">
          <p class="small font-weight-light mt-2" i18n="@@profile-security-reset-password-result-success">Email sent! Check your inbox and click on the link to reset your password.</p>
        </ng-container>
        <ng-container *ngIf="!resetPasswordResult">
          <p class="small font-weight-light mt-2" i18n="@@profile-security-reset-password-result-failure">Could not reset your password, contact support.</p>
        </ng-container>
      </div>
    </div>

  <mat-spinner [style.display]="false ? 'block' : 'none'"></mat-spinner>
</div>


