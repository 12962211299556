<app-header></app-header>
<div class="container main-div">
  <div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <ng-container *ngTemplateOutlet="dashboardHeader"></ng-container>
  <ng-template #dashboardHeader>
    <div class="row dashboardHeader">
      <div
        *ngIf="profilePictureUrl"
        class="my-md-auto col-md-3 mr-md-auto pb-md-auto"
      >
        <img
          [src]="profilePictureUrl"
          class="profile-pic-player-dashboard img-fluid"
        />
      </div>
      <div class="col-md-9 dashboardInfo mt-3 my-md-auto col-12">
        <div class="row">
          <div class="col-12">
            <p class="dashboardName" i18n="@@player-dashboard-header-title">Athlete stats</p>
            <h1 class="dashboardTitle">{{ playerName }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2">
            <span class="headerLabel" i18n="@@player-dashboard-header-laterality">Strong side: </span>
            <span class="headerValue">{{ strongFoot }}</span>
          </div>
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2">
            <span class="headerLabel" i18n="@@player-dashboard-header-position">Position: </span>
            <span class="headerValue">{{ position }}</span>
          </div>
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2" *ngIf="birthDate">
            <span class="headerLabel" i18n="@@player-dashboard-header-birthdate">Birthdate: </span>
            <span class="headerValue">{{ birthDate }}</span>
          </div>
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2">
            <span class="statisticLabel" i18n="@@player-dashboard-header-evaluations">Training sessions: </span>
            <span class="statisticValue"> {{ totalEvaluations }} </span>
            <!--<a href="#" class="statisticValue"> {{ totalEvaluations }} </a>-->
          </div>
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2">
            <span class="statisticLabel" i18n="@@player-dashboard-header-absences">Abscences: </span>
            <span class="statisticValue"> {{ totalAbsences }} </span>
          </div>
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2" *ngIf="inviteCode">
            <span class="statisticLabel" i18n="@@player-dashboard-header-code">Code: </span>
            <span class="statisticValue"> {{ inviteCode }} </span>
            <button mat-icon-button ngxClipboard (cbOnSuccess)="copiedCode($event)" [cbContent]="inviteCode"
            aria-label="copy athlete code to clipboard"
            i18n-aria-label="@@player-dashboard-header-code-button-aria-label">
              <mat-icon aria-hidden="true" class="sm-icon-button align-self-center">content_copy</mat-icon>
            </button>
          </div>
        </div>
        
      </div>
      
    </div>
  </ng-template>
  <div class="actions">
    <app-dashboard-actions-panel [entityId]="playerId" [type]="'player'" [editable]="false" [isCoach]="isCoach" (result)="update($event)">
    </app-dashboard-actions-panel>
  </div>
  <ng-container *ngTemplateOutlet="playerMetrics"></ng-container>
  <ng-template #playerMetrics>
    <div class="dashboardCharts">
      <h2 i18n="@@player-dashboard-metrics-title">Performance metrics</h2>
      <app-metrics-list
        [playerId]="playerId"
      ></app-metrics-list>
    </div>
  </ng-template>
  <div class="dashboardCharts">
    <h2 i18n="@@player-dashboard-stats-title">Stats</h2>
    <div class="d-flex flex-wrap flex-md-row justify-content-md-around">
      <div
        class="col-md-5 col-lg-4 dashboardThird my-2 py-3"
        *ngIf="validOverall"
      >
        <h3 class="lightText text-center"
        i18n="@@player-dashboard-charts-overall-title">Overall Stats</h3>
        <app-category-dashboard
          [behavioral]="overallScoreBehavioral"
          [physical]="overallScorePhysical"
          [technical]="overallScoreTechnical"
          [tactical]="overallScoreTactical"
        ></app-category-dashboard>
        <div>
          <mat-accordion>
            <mat-expansion-panel class="dashboardThird lightText">
              <mat-expansion-panel-header class="dark-expansion-header">
                <mat-panel-title 
                  class="lightText accordion-chart-title"
                  i18n="@@player-dashboard-charts-overall-explainer-title">
                  What does this mean?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p i18n="@@player-dashboard-charts-overall-explainer-body">
                The chart shows a breakdown of the player's performance by category through time.
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div
        class="col-md-5 col-lg-4 dashboardThird my-2 py-3"
        *ngIf="validEvolution"
      >
        <h3 class="lightText text-center"
        i18n="@@player-dashboard-charts-evolutions-title">Evolution</h3>
        <app-evolution-dashboard
          [evolutionValues]="evolutionValues"
          [evolutionDates]="evolutionDates"
        ></app-evolution-dashboard>
        <div>
          <mat-accordion>
            <mat-expansion-panel class="dashboardThird lightText">
              <mat-expansion-panel-header class="dark-expansion-header">
                <mat-panel-title 
                  class="lightText accordion-chart-title"
                  i18n="@@player-dashboard-charts-evolutions-explainer-title">
                  What does this mean?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p i18n="@@player-dashboard-charts-evolutions-explainer-body">
                The chart shows the overall score received by a player for every scored training.
                A positive value means that the overall performance for the date was good.
                A negative value means that more improvement areas were recorded (not necessarily the performance was bad).
                The scale may differ to the other charts because its an independent chart.
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div *ngIf="!validStrengths && !validImprovements && !validEvolution" class="col-12">
        <p class="text-center" i18n="@@player-dashboard-stats-empty">No stats yet</p>
      </div>
    </div>
  </div>
  <div class="dashboardSkillCategoryDetails" *ngIf="validStrengths && validImprovements">
    <h2 i18n="@@player-dashboard-skill-category-title">Skills</h2>
    <div class="row">
      <div class="col-sm-6 col-lg-3 skillCatDetail">
        <app-skill-category-details
          [category]="'Físicas'"
          [playerId]="playerId"
          [detailType]="'player'"
          [isCoach]="isCoach"
        >
        </app-skill-category-details>
      </div>
      <div class="col-sm-6 col-lg-3 skillCatDetail">
        <app-skill-category-details
          [category]="'Mentalidad'"
          [playerId]="playerId"
          [detailType]="'player'"
          [isCoach]="isCoach"
        >
        </app-skill-category-details>
      </div>
      <div class="col-sm-6 col-lg-3 skillCatDetail">
        <app-skill-category-details
          [category]="'Táctica'"
          [playerId]="playerId"
          [detailType]="'player'"
          [isCoach]="isCoach"
        >
        </app-skill-category-details>
      </div>
      <div class="col-sm-6 col-lg-3">
        <app-skill-category-details
          [category]="'Técnica'"
          [playerId]="playerId"
          [detailType]="'player'"
          [isCoach]="isCoach"
        >
        </app-skill-category-details>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <mat-accordion>
          <mat-expansion-panel class="">
            <mat-expansion-panel-header>
              <mat-panel-title i18n="@@player-dashboard-skill-category-explainer-title">
                What does this mean?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p i18n="@@player-dashboard-skill-category-explainer-body">
              The numbers shown next to each skill are the times the skill has been evaluated (TE) and the average percentage (AVG).
              The percentage is calculated based on the average of scores and the qty of times the skill has been scored.
              Use these scores to determine which aspects of the game to use for advantage or which ones should be the focus for improvement.
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="dashboardSkillCategoryDetails">
    <div class="d-flex justify-content-between">
      <h2 class="text-wrap"
      i18n="@@player-dashboard-training-title">Player homework</h2>
      <div class="col" *ngIf="isCoach">
        <button type="button" (click)="onNewDrill()" class="btn btn btn-outline-dark mb-1"
        i18n="@@player-dashboard-training-add-button">
          Add training
        </button>
      </div>
    </div>
    <div class="row" *ngIf="!isLoadingResults">
      <div class="col skillCatDetail">
        <app-drill-category-list  [playerId]="playerId" [teamId]="teamId"
        [isTeamList]=false
        [editable]="isCoach">
        </app-drill-category-list>
      </div>
    </div>
  </div>
  <div class="row dashboardDatesDetail"  *ngIf="totalAbsences > 0 || totalEvaluations > 0">
    <div class="col-md-6" *ngIf="totalAbsences > 0">
      <h2 i18n="@@player-dashboard-absence-dates-title">Absence Details</h2>
      <div class="table-responsive">
        <table class="table table-borderless table-striped table-sm">
          <tbody>
            <ng-container *ngFor="let absence of absencesList">
              <tr>
                <td>
                  {{
                    absence.toLocaleString(locale, {
                      year: "numeric",
                      month: "long",
                      day: "numeric"
                    })
                  }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-6" *ngIf="totalEvaluations > 0">
      <h2 i18n="@@player-dashboard-eval-dates-title">Training Dates</h2>
      <div class="table-responsive">
        <table class="table table-borderless table-striped table-sm">
          <tbody>
            <ng-container *ngFor="let evalDate of evaluationDates">
              <tr>
                <td>
                  <a href="/evaluation/{{playerId}}/{{getEvalDateLink(evalDate)}}"
                    class="mat-row-link">
                    {{
                      evalDate.toLocaleString(locale, {
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                      })
                    }}
                  </a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="dashboardPhotoGalleryHeader">
    <div class="d-flex justify-content-between">
      <h2 i18n="@@player-dashboard-pictures-title">Photos</h2>
      <button
        mat-raised-button
        color="accent"
        class="upload-button"
        (click)="goToUploadFiles()"
        i18n="@@player-dashboard-pictures-add-button"
      >Add photo</button>
    </div>
  </div>
  <div width="100%" class="dashboardSection">
    <div class="dashboardPhotoGallery">
      <ng-container
        *ngFor="let pic of picAlbum; let i = index"
      >
        <div class="picContainer">
          <img
            class="galleryPic"
            src="{{ pic.src }}"
          />
          <div class="middle-image-container">
            <div class="see-image-label mb-3" (click)="openPicture(i)"
            i18n="@@player-dashboard-pictures-view-picture">View</div>
            <div class="see-image-label" (click)="deletePicture(i)"
            i18n="@@player-dashboard-pictures-delete-picture">Delete</div>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="picAlbum.length != playersMediaPicsMetadata.length" align="center">
      <button class="show-more-button" (click)="showMorePics()">
        <mat-icon font>more_horiz</mat-icon>
      </button>
    </div>
    <div *ngIf="picAlbum.length===0">
      <p class="text-center" i18n="@@player-dashboard-pictures-empty">No pictures yet, feel free to add your best!</p>
    </div>
  </div>
  <div class="dashboardPhotoGalleryHeader">
    <div class="d-flex justify-content-between">
      <h2 i18n="@@player-dashboard-videos-title">Videos</h2>
      <div class="col-auto">
        <button mat-raised-button class="upload-button"
            color="accent" (click)="goToUploadFiles()"
            i18n="@@player-dashboard-videos-add-button"
          >Add video</button>
      </div>
    </div>
  </div>
  <div width="100%" class="dashboardSection">
    <div class="dashboardPhotoGallery">
      <ng-container *ngFor="let vid of vidAlbum; let i = index">
        <div>
          <video controls>
            <source src="{{ vid.src }}" type="video/mp4" />
          </video>
          <div class="d-flex mx-2 mb-2">
            <p class="my-auto mr-auto"><small>Uploaded by {{vid.uploadedBy}} <br /> <!-- On {{
              vid.uploadDate.toLocaleString(locale, {
                year: "numeric",
                month: "long",
                day: "numeric"
              })
            }}--></small></p>
            <button type="button" (click)="deleteVideo(i)" class="btn btn-sm btn-outline-dark">
              <mat-icon class="small-btn-icon" font>delete_outline</mat-icon>
              <span i18n="@@player-dashboard-videos-delete-button">Delete</span>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="vidAlbum.length != playersMediaVidsMetadata.length" align="center">
      <button class="show-more-button" (click)="showMoreVids()">
        <mat-icon font>more_horiz</mat-icon>
      </button>
    </div>
    <div *ngIf="vidAlbum.length===0">
      <p class="text-center" i18n="@@player-dashboard-videos-empty">No videos yet, feel free to add your best!</p>
    </div>
  </div>
  <div class="dashboardCharts" *ngIf="player">
    <div class="d-flex justify-content-between">
      <h2 i18n="@@player-dashboard-messages-title">Messages</h2>
      <div class="col-auto" *ngIf="isCoach">
        <button mat-raised-button class="upload-button"
          color="accent" (click)="addPlayerMessage()"
          i18n="@@player-dashboard-messages-add-button"
        >Add message</button>
      </div>
    </div>
    <app-view-messages [type]="'player'" [audience]="getMessageAudience()"></app-view-messages>
  </div>
</div>
