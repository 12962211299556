<div class="row mt-2 justify-content-md-center" *ngIf="!isLoadingResults">
    <ng-container *ngFor="let msg of receivedMessagesWithAuthors">
        <mat-card class="col-lg-6 col-md-8 px-md-2 mx-2 mb-2">
            <mat-card-header>
                <img mat-card-avatar [src]="msg.authorInfo.profilePictureUrl"/>
                <mat-card-title class="h4">{{msg.authorInfo.name}} {{msg.authorInfo.lastName}}</mat-card-title>
                <mat-card-subtitle>
                    <ng-container *ngIf="msg.authorInfo.email">
                        <a [href]="getUserEmailLink(msg.authorInfo.email)" class="emailInMssg">{{msg.authorInfo.email}}</a>&nbsp;-&nbsp;
                    </ng-container>
                    {{msg.dateTime.toMillis() | date:'medium'}}
                </mat-card-subtitle>
              </mat-card-header>
            <mat-card-content>
                <ng-container *ngIf="msg.subject">
                    <p class="h5">{{msg.subject}}</p>
                </ng-container>
                <p class="messageBody">{{msg.message}}</p>
            </mat-card-content>
        </mat-card>
    </ng-container>
    <div *ngIf="receivedMessagesWithAuthors.length===0">
        <p i18n="@@player-dashboard-messages-empty">No messages received yet</p>
    </div>
</div>