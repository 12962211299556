import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireFunctionsModule } from "@angular/fire/compat/functions";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ChartsModule } from "ng2-charts";
import { ClipboardModule } from "ngx-clipboard";
import { LightboxModule } from "ngx-lightbox";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CategoryDashboardComponent } from "./category-dashboard/category-dashboard.component";
import { CategoryEvolutionDashboardComponent } from "./category-evolution-dashboard/category-evolution-dashboard.component";
import { CommonUiModule } from "./common-ui/common-ui.module";
import { ConfirmDialogComponent } from "./common-ui/components/confirm-dialog/confirm-dialog";
import { CreateDrillAdminsComponent } from "./create-drill-admins/create-drill-admins.component";
import { DashboardActionsPanelComponent } from "./dashboard-actions-panel/dashboard-actions-panel.component";
import { DeleteConfirmationDialogComponent } from "./delete-confirmation-dialog/delete-confirmation-dialog.component";
import { DrillCategoryListComponent } from "./drill-category-list/drill-category-list.component";
import { DrillComponent } from "./drill/drill.component";
import { EvalPanelComponent } from "./evaluation/eval-panel/eval-panel.component";
import { OrderByPipe } from "./evaluation/eval-panel/order-by.pipe";
import { EvaluationComponent } from "./evaluation/evaluation.component";
import { EvolutionDashboardComponent } from "./evolution-dashboard/evolution-dashboard.component";
// import { HeaderComponent } from './common-ui/components/header/header.component';
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { MessagesModule } from "./messages/messages.module";
import { PlayerDashboardComponent } from "./player-dashboard/player-dashboard.component";
import { PlayerDialogFormComponent } from "./player-dialog-form/player-dialog-form.component";
import { PlayerDrillDialogFormComponent } from "./player-drill-dialog-form/player-drill-dialog-form.component";
import { PlayerDrillsComponent } from "./player-drills/player-drills.component";
import { PlayersComponent } from "./players/players.component";
import { ProfileComponent } from "./profile/profile.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { DropzoneDirective } from "./shared/dropzone.directive";
import { FirebaseService } from "./shared/firebase.service";
import { InvitationService } from "./shared/invitation.service";
import { RoleService } from "./shared/role.service";
import { SignUpProfileComponent } from "./sign-up-profile/sign-up-profile.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { SkillCategoryDetailsComponent } from "./skill-category-details/skill-category-details.component";
import { SkillListDialogFormComponent } from "./skill-list-screen/skill-list-dialog-form/skill-list-dialog-form.component";
import { CategoryPipe } from "./skill-list-screen/skill-list-edit/category.pipe";
import { SkillListEditComponent } from "./skill-list-screen/skill-list-edit/skill-list-edit.component";
import { SkillListComponent } from "./skill-list-screen/skill-list/skill-list.component";
import { SkillScoreDetailComponent } from "./skill-score-detail/skill-score-detail.component";
import { SkillsComponent } from "./skills/skills.component";
import { SupportedDialogFormComponent } from "./supported-dialog-form/supported-dialog-form.component";
import { SupportedsComponent } from "./supporteds/supporteds.component";
import { TeamDashboardComponent } from "./team-dashboard/team-dashboard.component";
import { TeamUserListComponent } from "./team-user-list/team-user-list.component";
import { TeamDialogFormComponent } from "./teams-screen/team-dialog-form/team-dialog-form.component";
import { TeamsComponent } from "./teams-screen/teams/teams.component";
import { UploadTaskComponent } from "./upload-task/upload-task.component";
import { UploaderComponent } from "./uploader/uploader.component";
import { UserDialogFormComponent } from "./user-dialog-form/user-dialog-form.component";
import { UserInfoDialogFormComponent } from "./user-info-dialog-form/user-info-dialog-form.component";
import { UserInfoComponent } from "./user-info/user-info.component";
import { UsermapComponent } from "./usermap/usermap.component";
import { ViewMetricsModule } from "./view-metrics/view-metrics.module";

const firebaseConfig = {
  apiKey: "AIzaSyDFreh7PGbjbugqQXCAspeoOVXWymnLK7A",
  authDomain: "cantera-dev.firebaseapp.com",
  databaseURL: "https://cantera-dev.firebaseio.com",
  projectId: "cantera-dev",
  storageBucket: "cantera-dev.appspot.com",
  messagingSenderId: "174473535830",
  appId: "1:174473535830:web:b17439c886dbe9c6",
  measurementId: "G-HJXLYWR3YV",
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TeamsComponent,
    // HeaderComponent,
    LoginComponent,
    PlayersComponent,
    PlayerDialogFormComponent,
    DeleteConfirmationDialogComponent,
    TeamDialogFormComponent,
    UsermapComponent,
    TeamDashboardComponent,
    PlayerDashboardComponent,
    CategoryDashboardComponent,
    EvolutionDashboardComponent,
    UserDialogFormComponent,
    UserInfoComponent,
    UserInfoDialogFormComponent,
    SkillsComponent,
    SkillCategoryDetailsComponent,
    CategoryEvolutionDashboardComponent,
    PlayerDrillsComponent,
    PlayerDrillDialogFormComponent,
    DrillCategoryListComponent,
    DropzoneDirective,
    UploaderComponent,
    UploadTaskComponent,
    SkillListComponent,
    SkillListDialogFormComponent,
    SkillListEditComponent,
    CategoryPipe,
    SupportedsComponent,
    SupportedDialogFormComponent,
    CreateDrillAdminsComponent,
    TeamUserListComponent,
    DashboardActionsPanelComponent,
    DrillComponent,
    SignUpComponent,
    SignUpProfileComponent,
    ProfileComponent,
    EvaluationComponent,
    EvalPanelComponent,
    OrderByPipe,
    SkillScoreDetailComponent,
    ConfirmDialogComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    HttpClientModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatCardModule,
    MatDialogModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatRadioModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    ChartsModule,
    LightboxModule,
    MatDividerModule,
    ViewMetricsModule,
    MessagesModule,
    MatExpansionModule,
    CommonUiModule,
    MatExpansionModule,
    ClipboardModule,
  ],
  exports: [CategoryPipe],
  entryComponents: [
    PlayerDialogFormComponent,
    PlayerDrillDialogFormComponent,
    DeleteConfirmationDialogComponent,
    TeamDialogFormComponent,
    UserDialogFormComponent,
    UserInfoDialogFormComponent,
    SkillListDialogFormComponent,
    SupportedDialogFormComponent,
    CreateDrillAdminsComponent,
    ConfirmDialogComponent,
  ],
  providers: [
    FirebaseService,
    InvitationService,
    RoleService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
