<mat-toolbar color="accent">
    <span>{{ title }}</span>
    <span class="spacer"></span>
    <button
        class="btn-dialog-close"
        mat-stroked-button
        (click)="onClose()"
        tabIndex="-1"
    >
    <mat-icon>clear</mat-icon>
    </button>
</mat-toolbar>
<form [formGroup]="playerDrillForm" class="normal-form">
    <mat-grid-list cols="2" rowHeight="17rem">
        <mat-grid-tile>
            <div class="controles-container">
                <mat-form-field color="accent">
                    <input formControlName="playerId" matInput placeholder="Player ID" />
                    <mat-error>This field is mandatory.</mat-error>
                </mat-form-field>
                <mat-form-field color="accent">
                    <input formControlName="drillName" matInput placeholder="Drill Name" />
                    <mat-error>This field is mandatory.</mat-error>
                </mat-form-field>
                <mat-form-field color="accent">
                    <input formControlName="drillCategory" matInput placeholder="Drill Category (TE-BE-TA-PH)" />
                    <mat-error>This field is mandatory.</mat-error>
                </mat-form-field>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="controles-container">
                <mat-form-field color="accent">
                    <input formControlName="drillLink" matInput placeholder="Drill Link" />
                    <mat-error>This field is mandatory.</mat-error>
                </mat-form-field>
                <mat-form-field color="accent">
                    <input formControlName="linkLanguage" matInput placeholder="Language" />
                    <mat-error>This field is mandatory.</mat-error>
                </mat-form-field>
                <mat-checkbox formControlName="active">Active</mat-checkbox>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
    <div class="button-row" mat-dialog-actions>
        <!-- <button mat-raised-button color="warn" (click)="onClear()">Clear</button>
        <span class="spacer"></span> -->
        <button
            mat-raised-button
            color="accent"
            type="submit"
            [disabled]="playerDrillForm.invalid"
            (click)="onSubmit()"
        >
        Submit
        </button>
    </div>
</form>