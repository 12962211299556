import { Component, OnInit, Input, LOCALE_ID, Inject  } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';

@Component({
  selector: 'app-evolution-dashboard',
  templateUrl: './evolution-dashboard.component.html',
  styleUrls: ['./evolution-dashboard.component.css']
})
export class EvolutionDashboardComponent implements OnInit {
  @Input() evolutionValues: number[];
  @Input() evolutionDates: string[];

  private barColor = '#b2ffdb';
  scoreLabel = 'Puntaje';

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales : {
      xAxes: [{
        ticks: {
            min: 0,
            display: false,
            fontColor: '#fbfbfb',
            fontFamily: '\'Roboto\', sans-serif'
        },
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        ticks: {
          display: true,
          maxTicksLimit: 3,
          beginAtZero: true,
          fontColor: '#fbfbfb',
          fontFamily: '\'Roboto\', sans-serif'
        },
        gridLines: {
          zeroLineWidth: 1,
          zeroLineColor: '#ffffff',
          lineWidth: 0

        }
      }]
    }
  };
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;

  public barChartData: ChartDataSets[] = [
    { data: [1, 2, -2, 3, 4]
    , backgroundColor: '#b2ffdb' // evolucion
    , label: 'Puntaje'
    , hoverBackgroundColor: '#00ff87'
    , hoverBorderColor: '#00140B'
    }
  ];

  public barChartLabels: string[] = ['Físicas', 'Mentales', 'Tácticas', 'Técnicas'];

  constructor(@Inject(LOCALE_ID) public locale: string) { }

  ngOnInit() {
    this.setLocalizedMessages();
    this.barChartData[0].label = this.scoreLabel;
    this.barChartData[0].data = this.evolutionValues;
    this.barChartLabels = this.evolutionDates;
    // this.calculateYaxisLimits(); -> this was substituted with begintatzero property

  }

  setLocalizedMessages() {
    if (this.locale.includes('en')) {
      this.scoreLabel = 'Score';
      this.barChartLabels = ['Physical', 'Behavioral', 'Tactical', 'Technical'];
    }
  }

  calculateYaxisLimits() {
    const min = Math.min(...this.evolutionValues);
    if (min >= 0) {
      this.barChartOptions.scales.yAxes[0].ticks.min = 0;
    } else {
      this.barChartOptions.scales.yAxes[0].ticks.min = min - 1;
    }

    const max = Math.max(...this.evolutionValues);
    if (max >= 0) {
      this.barChartOptions.scales.yAxes[0].ticks.max = max + 1;
    } else {
      this.barChartOptions.scales.yAxes[0].ticks.max = 0;
    }
  }

}
