import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../shared/notification.service';

@Component({
  selector: 'app-user-info-dialog-form',
  templateUrl: './user-info-dialog-form.component.html',
  styleUrls: ['./user-info-dialog-form.component.css']
})
export class UserInfoDialogFormComponent {

  public title: string;

  public userInfoForm = new FormGroup({
    action: new FormControl('new'),
    name: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    language: new FormControl('1', Validators.required),
    userId: new FormControl('', Validators.required),
    profilePicturePath: new FormControl('', Validators.required),
    email: new FormControl('', Validators.compose([Validators.email, Validators.required]))
  });

  constructor(private notification: NotificationService,
              public dialogRef: MatDialogRef<UserInfoDialogFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                if (this.data) {
                  this.title = 'Edit User Info';
                  this.populateForm(this.data);
                } else {
                  this.title = 'New User Info';
                }
              }


  initializeFormGroup() {
    this.userInfoForm.setValue({
      action: 'new',
      name: '',
      lastName: '',
      language: '1',
      userId: '',
      profilePicturePath: '',
      email: ''
    });
  }

  onClear() {
    this.userInfoForm.reset();
    this.initializeFormGroup();
    this.notification.success('Cleared successfully');
  }

  onClose() {
    this.userInfoForm.reset();
    this.initializeFormGroup();
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.title === 'Edit User Info') {
      this.dialogRef.close({userinfoId: this.data.userinfoId, ... this.userInfoForm.value});
    } else {
      this.dialogRef.close(this.userInfoForm.value);
    }
  }

  populateForm(userInfo) {
    this.initializeFormGroup();
    this.userInfoForm.setValue({
      action: 'edit',
      name: userInfo.name,
      lastName: userInfo.lastName,
      language: this.checkLanguage(userInfo.language),
      userId: userInfo.userId,
      profilePicturePath: userInfo.profilePicturePath,
      email: userInfo.email
    });
  }

  checkLanguage(userInfoLanguage: string) {
    if (userInfoLanguage === 'es') {
      return '2';
    } else {
      return '1';
    }
  }

}

