import { Component, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { User } from '../models/user';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig
} from '@angular/material/dialog';
import { UserDialogFormComponent } from '../user-dialog-form/user-dialog-form.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationService } from '../shared/notification.service';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-usermap',
  templateUrl: './usermap.component.html',
  styleUrls: ['./usermap.component.css']
})
export class UsermapComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public displayedColumns: string[] = ['select', 'name', 'role', 'teamId', 'userId', 'playerId', 'year', 'default', 'actions'];
  public dataSource = new MatTableDataSource();
  private initialSelection = [];
  private allowMultiSelect = true;
  private selection = new SelectionModel<any>(this.allowMultiSelect, this.initialSelection);

  private userList: User[];

  public title = 'User Map';

  public isLoadingResults = true;

  constructor(private firebase: FirebaseService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private notification: NotificationService) {}

  ngOnInit() {
    this.firebase.getUsers().subscribe(data => {
      this.userList = [];
      data.forEach(u => {
        this.userList.push(u as User);
      });
      this.dataSource = new MatTableDataSource(this.userList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.selection = new SelectionModel<User>(this.allowMultiSelect, this.initialSelection);
      this.isLoadingResults = false;
    });
  }

  doFilter(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

    /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: User): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} user ${row.name}`;
  }

  onNewUser(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '55%';
    const dialogRef = this.dialog.open(UserDialogFormComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.firebase.addUserMap(result.name, result.teamId, result.userId, result.playerId, result.year,
          this.translateDefault(result.default), this.translateRole(result.role));
        this.notification.success('User added');
      }
    });
  }

  onEditUser(row) {
    const dialogRef = this.dialog.open(UserDialogFormComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.firebase.editUserMap(result.usermapId, result.name, result.teamId, result.userId, result.playerId, result.year,
          this.translateDefault(result.default), this.translateRole(result.role));
        this.notification.success('User edited');
      }
    });
  }

  onDeleteUser(row) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '30%',
      disableClose: true,
      autoFocus: true,
      data: { action: 'delete user', object: row }
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.firebase.deleteUserMap(row.usermapId);
        this.notification.success('User deleted');
      }
    });
  }

  translateDefault (value: string) {
    if (value === '1') {
      return true;
    } else {
      return false;
    }
  }

  translateRole (value: string) {
    if (value === '1') {
      return 'Coach';
    } else if (value === '2') {
      return 'Player';
    } else {
      return 'Supporter';
    }
  }

}
