<mat-toolbar color="primary" class="menu-all">
  <span class="logo">
    <mat-icon svgIcon="navLogo"></mat-icon>
  </span>
  <span class="menu-button">
    <button mat-icon-button [matMenuTriggerFor]="menu" >
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <button mat-menu-item (click)="navigateToHome()" color="accent">
          <mat-icon>sports_soccer</mat-icon>
          <span i18n="@@header-home">Home</span>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="adminMenu" *ngIf='isAdmin'>
        <mat-icon>build</mat-icon>
        <span>Admin</span>
      </button>
    </mat-menu>
    <mat-menu #adminMenu="matMenu">
      <button mat-menu-item (click)="navigateToPlayers()" color="accent">
        <mat-icon>person</mat-icon>
        <span>Players</span>
      </button>
      <button mat-menu-item (click)="navigateToTeams()">
        <mat-icon>group</mat-icon>
        <span>Teams</span>
      </button>
      <button mat-menu-item (click)="navigateToUsers()">
        <mat-icon>face</mat-icon>
        <span>User Map</span>
      </button>
      <button mat-menu-item (click)="navigateToUsersInfo()">
        <mat-icon>info</mat-icon>
        <span>User Info</span>
      </button>
      <button mat-menu-item (click)="navigateToSkills()">
        <mat-icon>star</mat-icon>
        <span>Skills</span>
      </button>
      <button mat-menu-item (click)="navigateToPlayerDrills()">
        <mat-icon>assignment</mat-icon>
        <span>Player Drills</span>
      </button>
      <button mat-menu-item (click)="navigateToSkillList()">
        <mat-icon>list</mat-icon>
        <span>Skill List</span>
      </button>
      <button mat-menu-item (click)="navigateToSupporteds()">
        <mat-icon>visibility</mat-icon>
        <span>Supporteds</span>
      </button>
    </mat-menu>
  </span>
  <span class="menu-language item-width">
    <mat-form-field class="w-100">
      <mat-select class=""
        [(ngModel)]="language" name="language" (selectionChange)="onLanguageChange($event)" required>
        <mat-option *ngFor="let language of languages" [value]="language[0]">
          {{language[1]}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </span>
  <span class="menu-profile">
      <button mat-icon-button (click)="navigateToProfile()">
        <mat-icon>person</mat-icon>
        <!--<span class="separator d-none d-lg-block" i18n="@@header-my-profile">My Profile</span>-->
      </button>
  </span>
  <span>
    <button mat-icon-button (click)="authService.logout()">
      <mat-icon>logout</mat-icon>
      <!--<span class="separator d-none d-lg-block" i18n="@@header-logout">Logout</span>-->
    </button>
  </span>
</mat-toolbar>
