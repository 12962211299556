<div class="shadow p-3 mb-5 bg-white rounded">
    <h3>
        <span>{{localizedSkillName}}&nbsp;</span>
        <span i18n="@@metric-detail-component-header-evolution">Evolution</span>
    </h3>
    <div class="row my-4 px-2">
        <div class="col my-3" style="display: block;">
            <canvas baseChart
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins">
            </canvas>
        </div>
    </div>
    <div>
        <h4 i18n="@@metric-detail-component-detail-table-title">Detail</h4>
        <div class="table-responsive">
            <table class="table table-borderless table-striped table-sm">
                <thead>
                    <tr>
                        <th i18n="@@metric-detail-component-detail-table-header-date">Date</th>
                        <th i18n="@@metric-detail-component-detail-table-header-result">Result</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let result of metricResults">
                        <tr>
                            <td>
                                {{getEvaluationDate(result)}}
                            </td>
                            <td> {{result.skillResult}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
