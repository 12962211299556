import { Component, OnInit, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { of, from } from 'rxjs';
import { distinct, take } from 'rxjs/operators';
import {MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { MediaMetadata } from '../models/mediaMetadata';
import { OverallStatistics } from '../models/overallStatistics';
import { Player } from '../models/player';
import { Team } from '../models/team';
import { UserInfo } from '../models/userInfo';
import { FirebaseService } from '../shared/firebase.service';
import { NotificationService } from '../shared/notification.service';
import { ConfirmDialogComponent } from '../common-ui/components/confirm-dialog/confirm-dialog';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-player-dashboard',
  templateUrl: './player-dashboard.component.html',
  styleUrls: ['./player-dashboard.component.css']
})
export class PlayerDashboardComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public title = 'Estadísticas del futbolista';
  private notFound = 'Error: atleta no encontrado';
  private copiedCodeSuccess = 'Código copiado';
  private deleteMediaDialogTitle = 'Borrar archivo';
  private deleteMediaDialogContent = '¿Desea borrar el archivo?';
  private deleteMediaDialogYes = 'Borrar';
  private deleteMediaDialogNo = 'Cancelar';
  isLoadingResults = true;
  public isCoach = false;

  public player: Player;
  public playerId: string;
  private teamId: string;
  private userInfo: UserInfo;
  public playerName: string;
  public strongFoot: string;
  public position: string;
  public birthDate: string;
  public profilePictureUrl: string;
  public inviteCode: string;

  private overallStats: OverallStatistics;
  private strengthScoreBehavioral: number;
  private strengthScorePhysical: number;
  private strengthScoreTactical: number;
  private strengthScoreTechnical: number;
  public validStrengths = false;

  private overallScoreBehavioral: number;
  private overallScorePhysical: number;
  private overallScoreTactical: number;
  private overallScoreTechnical: number;
  public validOverall = false;

  private improvementScoreBehavioral: number;
  private improvementScorePhysical: number;
  private improvementScoreTactical: number;
  private improvementScoreTechnical: number;
  public validImprovements = false;

  public validEvolution: boolean;
  private evolutionStats: OverallStatistics[];
  private evolutionValues: number[];
  private evolutionDates: string[];

  public totalAbsences = 0;
  public absencesList: Date[] = [];

  public totalEvaluations = 0;
  public evaluationDates: Date[] = [];

  private team: Team;
  private teamList: Team[];
  public playersMediaPicsMetadata: MediaMetadata[] = [];
  public playersMediaVidsMetadata: MediaMetadata[] = [];
  public picAlbum = [];
  public vidAlbum = [];
  private shownPicturesCounter = 0;
  private shownVideosCounter = 0;
  private currentPhoto = -1;
  private currentVideo = -1;

  public showNegatives = true;

  constructor(
    private _route: ActivatedRoute,
    private firebase: FirebaseService,
    private authService: AuthService,
    private router: Router,
    private notification: NotificationService,
    private lightbox: Lightbox,
    public dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit() {
    this.setLocalizedMessages();
    this._route.params.subscribe(params => {
      this.playerId = params['playerId'];
      // console.log(params);

      this._route.queryParams.subscribe(queryParams => {
        // console.log(queryParams);
        if (queryParams['isCoach'] === 'true') {
          this.isCoach = true;
          // console.log(this.isCoach);
        } else {
          this.isCoach = false;
          // console.log(this.isCoach);
        }
      });
    });
    this.showNegatives = environment.playerDashboard.showNegative;
    // obtener datos del jugador
    this.firebase.getPlayer(this.playerId)
    .pipe(take(1))
    .subscribe(data => {
      this.isLoadingResults = true;
      this.player = data;
      this.teamId = this.player.teamId;
      if (this.player != null) {
        this.firebase
          .getUserInfoById(this.authService.user.uid)
          .pipe(take(1))
          .subscribe(userInfoQuery => {
            this.userInfo = userInfoQuery[0] as UserInfo;
          });
        this.playerName = this.player.firstName + ' ' + this.player.lastName;
        this.position = this.player.position;
        this.strongFoot = this.player.strongFoot;
        if ( this.player.birthDay && this.player.birthMonth && this.player.birthYear) {
          this.birthDate =
            this.player.birthDay +
            ' / ' +
            this.player.birthMonth +
            ' / ' +
            this.player.birthYear;
        }
        this.inviteCode = this.player.invitationCode;
        if (this.player.profilePicturePath) {
          this.firebase
            .getProfilePicture(this.player.profilePicturePath)
            .pipe(take(1))
            .subscribe(url => {
              this.profilePictureUrl = url;
            });
        } else {
          this.firebase
            .getProfilePicture('profilePictures/default.png')
            .pipe(take(1))
            .subscribe(url => {
              this.profilePictureUrl = url;
            });
        }
      } else {
        this.playerName = this.notFound;
        this.position = '';
        this.strongFoot = '';
        this.birthDate = '';
      }
      this.validateNullInfoData();
      this.isLoadingResults = false;
    });

    this.firebase.getPlayerOverallStatistics(this.playerId)
    .pipe(take(1))
    .subscribe(data => {
      this.isLoadingResults = true;
      this.overallStats = data[0];
      if (this.overallStats != null) {
        this.strengthScoreBehavioral = this.overallStats.strengthScoreBehavioral;
        this.strengthScorePhysical = this.overallStats.strengthScorePhysical;
        this.strengthScoreTactical = this.overallStats.strengthScoreTactical;
        this.strengthScoreTechnical = this.overallStats.strengthScoreTechnical;

        this.improvementScoreBehavioral = this.overallStats.improvementScoreBehavioral;
        this.improvementScorePhysical = this.overallStats.improvementScorePhysical;
        this.improvementScoreTactical = this.overallStats.improvementScoreTactical;
        this.improvementScoreTechnical = this.overallStats.improvementScoreTechnical;

        this.setOverallScores();
      }
      this.validateNullStatsData();
      this.isLoadingResults = false;
    });

    // calcular evolucion
    this.firebase.getPlayerDailyStatistics(this.playerId)
    .pipe(take(1))
    .subscribe(data => {
      this.isLoadingResults = true;

      if (data != null) {
        this.evolutionStats = data;
        // sort by dates
        this.evolutionStats.sort(this.compareDates);
        // enviar los valores
        // enviar las fechas
        this.evolutionValues = [];
        this.evolutionDates = [];
        this.evolutionStats.forEach(stat => {
          const dailyStat = this.calculateDailyStat(stat);
          this.evolutionValues.push(dailyStat);
          this.evolutionDates.push(
            stat.day + '/' + stat.month + '/' + stat.year
          );
        });
      }

      this.validateNullEvolution();
      this.isLoadingResults = false;
    });

    this.absencesList = [];
    this.firebase.getPlayerAbsences(this.playerId)
      .pipe(take(1))
      .subscribe(data => {
        this.isLoadingResults = true;

        if (data != null) {
          data.forEach(absence => {
            if (!absence.statusResult) {
              const absenceDate = new Date(
                absence.year,
                absence.month - 1,
                absence.day
              );
              this.absencesList.push(absenceDate);
              this.totalAbsences++;
            }
          });
        }
        this.absencesList.sort((a, b) => {
          return a > b ? -1 : a < b ? 1 : 0;
        });
        this.isLoadingResults = false;
    });

    // get evaluated dates
    this.evaluationDates = [];
    this.firebase.getPlayerEvaluatedDates(this.playerId)
      .pipe(take(1))
      .subscribe(data => {
        this.isLoadingResults = true;
        if (data != null) {
          const uniqueData = from(data).pipe(
            distinct(d => d.toDateString())
          );
          uniqueData.subscribe(evalDate => {
            // console.log(evalDate.toDateString());
            this.totalEvaluations = this.evaluationDates.push(evalDate);
          });
          this.evaluationDates.sort((a, b) => {
            return a > b ? -1 : a < b ? 1 : 0;
          });
        }
        this.isLoadingResults = false;
      });

    this.firebase
      .getPlayerMediaPicsUrls(this.playerId)
      .subscribe(async mediaPicsUrlsList => {
        if (this.picAlbum.length === 0) {
          mediaPicsUrlsList.forEach((picMetadata: MediaMetadata) => {
            this.playersMediaPicsMetadata.push(picMetadata as MediaMetadata);
          });
          for (
            const i = this.shownPicturesCounter;
            this.shownPicturesCounter < i + 12 &&
            this.shownPicturesCounter < this.playersMediaPicsMetadata.length;
            this.shownPicturesCounter++
          ) {
            if (this.playersMediaPicsMetadata[this.shownPicturesCounter].format) {
              const fileThumbUrl = await this.firebase.getFileByPath(
                this.playersMediaPicsMetadata[
                  this.shownPicturesCounter
                ].storagePath.slice(
                  0,
                  -this.playersMediaPicsMetadata[this.shownPicturesCounter].format
                    .length - 1
                ) +
                  '_150x150' +
                  '.' +
                  this.playersMediaPicsMetadata[this.shownPicturesCounter].format
              );
              this.picAlbum.push({
                id: this.playersMediaPicsMetadata[this.shownPicturesCounter].id,
                src: this.playersMediaPicsMetadata[this.shownPicturesCounter]
                  .downloadUrl,
                thumb: fileThumbUrl
              });
            } else {
              this.picAlbum.push({
                id: this.playersMediaPicsMetadata[this.shownPicturesCounter].id,
                src: this.playersMediaPicsMetadata[this.shownPicturesCounter]
                  .downloadUrl
              });
            }
          }
        }
      });

    this.firebase
      .getPlayerMediaVidsUrls(this.playerId)
      .subscribe(mediaVidsUrlsList => {
        if (this.vidAlbum.length === 0) {
          mediaVidsUrlsList.forEach((vidMetadata: MediaMetadata) => {
            this.playersMediaVidsMetadata.push(vidMetadata as MediaMetadata);
          });
          for (
            const i = this.shownVideosCounter;
            this.shownVideosCounter < i + 3 &&
            this.shownVideosCounter < this.playersMediaVidsMetadata.length;
            this.shownVideosCounter++
          ) {
            /*const tempDate = new Date((this.playersMediaVidsMetadata[this.shownVideosCounter].uploadDate as any).seconds);
            console.log(tempDate.toLocaleString(this.locale, {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }));*/
            this.vidAlbum.push({
              id: this.playersMediaVidsMetadata[this.shownVideosCounter].id,
              src: this.playersMediaVidsMetadata[this.shownVideosCounter].downloadUrl,
              uploadDate: this.playersMediaVidsMetadata[this.shownVideosCounter].uploadDate,
              uploadedBy: this.playersMediaVidsMetadata[this.shownVideosCounter].uploadedByName,
            });
          }
        }
        // console.log(this.vidAlbum);
      });

    // fin ngOnInit
  }

  setLocalizedMessages() {
    if (this.locale.includes('en')) {
      this.notFound = 'Athlete not found';
      this.copiedCodeSuccess = 'Code copied to clipboard!';
      this.deleteMediaDialogTitle = 'Delete media';
      this.deleteMediaDialogContent = 'Are you sure you want to delete this file?';
      this.deleteMediaDialogYes = 'Delete';
      this.deleteMediaDialogNo = 'Cancel';
    }
  }

  validateNullInfoData() {
    if (this.player.position == null) {
      this.position = 'N / A';
    }
    if (this.player.strongFoot == null) {
      this.strongFoot = 'N / A';
    }
    if (
      this.player.birthDay == null ||
      this.player.birthMonth == null ||
      this.player.birthYear == null
    ) {
      this.birthDate = 'N / A';
    }
  }

  validateNullStatsData() {
    if (
      this.strengthScoreBehavioral != null ||
      this.strengthScorePhysical != null ||
      this.strengthScoreTactical != null ||
      this.strengthScoreTechnical != null
    ) {
      this.validStrengths = true;
    }

    if (
      this.improvementScoreBehavioral != null ||
      this.improvementScorePhysical != null ||
      this.improvementScoreTactical != null ||
      this.improvementScoreTactical != null
    ) {
      this.validImprovements = true;
    }

    if (
      this.overallScoreBehavioral != null ||
      this.overallScorePhysical != null ||
      this.overallScoreTactical != null ||
      this.overallScoreTechnical != null
    ) {
      this.validOverall = true;
    }
  }

  setOverallScores() {

    if (this.showNegatives) {
      this.overallScoreBehavioral = this.overallStats.scoreBehavioral;
      this.overallScorePhysical = this.overallStats.scorePhysical;
      this.overallScoreTactical = this.overallStats.scoreTactical;
      this.overallScoreTechnical = this.overallStats.scoreTechnical;
    } else {
      this.overallScoreBehavioral = Math.abs(this.overallStats.improvementScoreBehavioral)
      + (this.overallStats.strengthScoreBehavioral * 2);
      this.overallScorePhysical = Math.abs(this.overallStats.improvementScorePhysical)
      + (this.overallStats.strengthScorePhysical * 2);
      this.overallScoreTactical = Math.abs(this.overallStats.improvementScoreTactical)
      + (this.overallStats.strengthScoreTechnical * 2);
      this.overallScoreTechnical = Math.abs(this.overallStats.improvementScoreTechnical)
      + (this.overallStats.strengthScoreTechnical * 2);
    }
  }

  validateNullEvolution() {
    if (this.evolutionStats !== null && this.evolutionStats.length > 0) {
      this.validEvolution = true;
    }
  }

  calculateDailyStat(dailyNumbers: OverallStatistics): number {
    if (this.showNegatives) {
      return dailyNumbers.overallScore;
    } else {
      let dailyScore;
      dailyScore = Math.abs(dailyNumbers.improvementScoreBehavioral) +
        Math.abs(dailyNumbers.improvementScorePhysical) +
        Math.abs(dailyNumbers.improvementScoreTactical) +
        Math.abs(dailyNumbers.improvementScoreTechnical);

      dailyScore += (dailyNumbers.strengthScoreBehavioral * 2) +
        (dailyNumbers.strengthScorePhysical * 2) +
        (dailyNumbers.strengthScoreTactical * 2) +
        (dailyNumbers.strengthScoreTechnical * 2);

      return dailyScore;
    }
  }

  compareDates(a: any, b: any) {
    const dateA = new Date(a.year, a.month - 1, a.day);
    const dateB = new Date(b.year, b.month - 1, b.day);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  public goToUploadFiles() {
    this.router.navigate(['/upload-media'], {
      queryParams: {
        playerId: this.playerId,
        teamId: this.player.teamId,
        uploadedBy: this.userInfo.name + ' ' + this.userInfo.lastName,
        isCoach: this.isCoach
      }
    });
  }

  openPicture(index: number): void {
    // open lightbox
    this.lightbox.open(this.picAlbum, index);
  }

  async deletePictureClick(metadata: any) {
    // Delete from collection
    await metadata.firebaseObj.deleteFileFromCollection(metadata.album[metadata.index].id);
    // Delete from firestore
    await metadata.firebaseObj.deleteFileFromStorage(metadata.album[metadata.index].src);
    // Delete from array
    metadata.album.splice(metadata.index, 1);
  }

  deletePicture(index: number) {
    this.currentPhoto = index;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        title: this.deleteMediaDialogTitle,
        content: this.deleteMediaDialogContent,
        buttonActionYes: this.deleteMediaDialogYes,
        buttonActionNo: this.deleteMediaDialogNo,
        buttonClick: this.deletePictureClick,
        metadata: {
          index,
          album: this.picAlbum,
          firebaseObj: this.firebase
        }
      },
    });
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  showMorePics() {
    for (
      const i = this.shownPicturesCounter;
      this.shownPicturesCounter < i + 12 &&
      this.shownPicturesCounter < this.playersMediaPicsMetadata.length;
      this.shownPicturesCounter++
    ) {
      this.picAlbum.push({
        src: this.playersMediaPicsMetadata[this.shownPicturesCounter]
          .downloadUrl
      });
    }
  }

  showMoreVids() {
    for (
      const i = this.shownVideosCounter;
      this.shownVideosCounter < i + 3 &&
      this.shownVideosCounter < this.playersMediaVidsMetadata.length;
      this.shownVideosCounter++
    ) {
      this.vidAlbum.push({
        src: this.playersMediaVidsMetadata[this.shownVideosCounter].downloadUrl
      });
    }
  }

  onNewDrill() {
    this.router.navigate(['/create-drill-admins'], {
      queryParams: {
        type: 'player',
        id: this.playerId,
        name: this.playerName
      }
    });
  }

  getEvalDateLink(evalDate: Date): string {
    let dateForLink = '';
    const year = evalDate.getFullYear();
    const month = ('0' + (evalDate.getMonth() + 1)).slice(-2);
    const day = ('0' + evalDate.getDate()).slice(-2);
    dateForLink = year + month + day;
    return dateForLink;
  }

  addPlayerMessage() {
    this.router.navigate(['/create-message'], {
      queryParams: {
        id: this.playerId,
        type: 'player',
        name: this.playerName
      }
    });
  }

  getMessageAudience() {
    if (this.player && this.player.userId) {
      return this.player.userId;
    }
    return this.playerId;
  }

  copiedCode(e: Event) {
    this.notification.success(this.copiedCodeSuccess);
  }

  update(e: Event) {
    console.log('updated');
  }

  deleteVideo(index: number) {
    this.currentVideo = index;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        title: this.deleteMediaDialogTitle,
        content: this.deleteMediaDialogContent,
        buttonActionYes: this.deleteMediaDialogYes,
        buttonActionNo: this.deleteMediaDialogNo,
        buttonClick: this.deletePictureClick,
        metadata: {
          index,
          album: this.vidAlbum,
          firebaseObj: this.firebase
        }
      },
    });
  }
}
