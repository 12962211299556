<div class="row mt-2">
  <ng-container *ngFor="let metricSkill of playerMetricSkillSummary">
    <mat-card class="col-4 col-md-2 pr-md-2 mr-2 mb-2 text-right"
    (click)="showDetailClick(metricSkill)">
      <mat-card-content>
        {{getMetricName(metricSkill)}}
      </mat-card-content>
      <mat-card-title>{{metricSkill.overallScore}}</mat-card-title>
    </mat-card>
  </ng-container>
</div>

<ng-container *ngTemplateOutlet="metricDetail"></ng-container>

<div *ngIf="playerMetricSkillSummary.length===0">
  <p class="text-center" i18n="@@metrics-list-empty">No stats yet</p>
</div>

<ng-template #metricDetail>
  <div *ngIf="showDetail">
    <app-metric-detail [playerId]="playerId"
    [skillCode]="selectedSkillCode" [skill]="selectedSkill"
    [localizedSkillName]="clickedSkillName">
    </app-metric-detail>
  </div>
</ng-template>
