<app-header></app-header>
<div class="container main-div">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
                <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!isLoadingResults" class="container">
                <nav aria-label="breadcrumb">
                        <ol class="breadcrumb actionsBg">
                                <li class="breadcrumb-item">
                                        <a [href]="playerDashboardLink"
                                                class="mat-row-link"
                                                i18n="@@evaluation-player-breadcrumb-player-dashboard-link">
                                                Player information
                                        </a>
                                </li>
                                <li class="breadcrumb-item active"
                                        aria-current="page"
                                        i18n="@@evaluation-player-breadcrumb-evaluation-label">
                                        Evaluation
                                </li>
                        </ol>
                </nav>
                <div class="row dashboardHeader">
                        <div
                          *ngIf="profilePictureUrl"
                                class="my-md-auto col-md-3 mr-md-auto pb-md-auto"
                        >
                                <img
                                [src]="profilePictureUrl"
                                class="profile-pic-player-dashboard img-fluid"
                                width="200"
                                height="200"
                                />
                        </div>
                        <div class="col-md-9 dashboardInfo mt-3 my-md-auto col-12">
                                <div class="row">
                                        <div class="col-12">
                                                <h1 class="dashboardTitle">{{ player.firstName + ' ' + player.lastName  }}</h1>
                                        </div>
                                </div>
                                <div class="row">
                                        <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2">
                                                <span class="headerLabel">{{ userInfo.email }}</span>
                                        </div>
                                        <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2">
                                                <span class="headerLabel" i18n="@@evaluation-player-header-age" *ngIf="age">Age: </span>
                                                <span class="headerValue">{{ age }}</span>
                                        </div>
                                        <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2">
                                                <span class="headerLabel pr-2"  i18n="@@evaluation-player-header-abscenses">Abscenses: </span>
                                                <span class="headerValue">{{ totalAbsences }}</span>
                                        </div>
                                        <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2">
                                                <span class="headerLabel pr-2"  i18n="@@evaluation-player-header-position">Position: </span>
                                                <span class="headerValue">{{ player.position }}</span>
                                        </div>
                                        <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2">
                                                <span class="headerLabel pr-2" i18n="@@evaluation-player-header-evaluations">Evaluations: </span>
                                                <span class="headerValue">{{ dateEvaluationList.length }}</span>
                                        </div>
                                        <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2">
                                                <span class="headerLabel pr-2" i18n="@@evaluation-player-header-laterality">Strong side: </span>
                                                <span class="headerValue">{{ player.strongFoot }}</span>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
        <div *ngIf="!isLoadingResults" class="dashboardCharts">
                <div class="row">
                        <h2 class="col-md-5" i18n="@@evaluation-details-header-title">Training journal</h2>
                        <p class="col-md-7 h5">
                                <span i18n="@@evaluation-details-header-evaluation-date">Evaluation date:&nbsp;</span>
                                <span>{{getEvaluationDate(day, month, year)}}</span>
                        </p>
                </div>
                <div class="row py-3">
                        <h3 class="col" i18n="@@evaluation-details-attendance-header">Attendance</h3>
                </div>
                <div class="row">
                        <p class="headerLabel col-sm-4" i18n="@@evaluation-details-attendance-label">The player attended:&nbsp;</p>
                        <mat-checkbox class="col-1" [(ngModel)]="attendance" color="primary" [disabled]="!newMode"></mat-checkbox>
                </div>
                <div class="row py-3">
                        <h3 class="col" i18n="@@evaluation-details-skills-header">Skills</h3>
                </div>
                <div class="row">
                        <div class=col-12>
                                <mat-accordion class="example-headers-align" multi>
                                        <ng-container *ngFor="let category of categories | orderBy:'name'">
                                                <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                          <mat-panel-title class="skill-title m-auto">{{category.name}}</mat-panel-title>
                                                          <mat-panel-description>
                                                                <div [className]="getCategoryBackground(category.code)">
                                                                        <img
                                                                                [src]="getCategoryIcon(category.code)"
                                                                                stretch="fill"
                                                                                class="statisticImage"
                                                                                width="40"
                                                                                height="40" />
                                                                </div>
                                                          </mat-panel-description>
                                                        </mat-expansion-panel-header>
                                                        <ng-template matExpansionPanelContent>
                                                                <app-eval-panel
                                                                        [evaluationList]="getEvaluationByCategory(category.name)"
                                                                        [mode]="newMode"
                                                                        [attendance]="attendance">
                                                                </app-eval-panel>
                                                        </ng-template>
                                                </mat-expansion-panel>
                                        </ng-container>
                                </mat-accordion>
                        </div>
                </div>
                <div *ngIf="newMode">
                        <div class="row py-3">
                                <h3 class="col" i18n="@@evaluation-metrics-title">Performance metrics</h3>
                        </div>
                        <ng-container *ngFor="let metric of getMetrics() | orderBy:'skillName'">
                                <div class="row">
                                        <mat-form-field class="col-xs-6 col-md-4">
                                                <mat-label>{{metric.skillName}}</mat-label>
                                                <input type="number" matInput [(ngModel)]="metric.quantitativeResult" >
                                        </mat-form-field>
                                </div>
                        </ng-container>
                        <div class="row">
                                <button mat-raised-button (click)="saveEvaluation()" color="accent" class="col-xs-11 col-md-4 py-2" i18n="@@evaluation-save-button">
                                        Finish evaluation
                                </button>
                        </div>
                </div>
                <div class="py-3" *ngIf="getMetrics().length > 0 && !newMode">
                        <div class=row>
                                <div class="col-12">
                                        <h3 i18n="@@evaluation-details-metrics-header">Performance metrics</h3>
                                </div>
                        </div>
                        <div class=row>
                                <div class="col-12">
                                        <app-metrics-list [playerId]="playerId" [evaluationMetricList]="getMetrics()" [fromEvaluation]="true"></app-metrics-list>
                                </div>
                        </div>
                </div>
        </div>
        <div *ngIf="!isLoadingResults" class="space-bottom">
        </div>
        <div *ngIf="!isLoadingResults && newMode" class="fixed-bottom sticky-bottom text-center">
                <div class="row">
                        <div class="col-md-11 col-lg-8 message-container">
                                <mat-form-field class="message-box" appearance="fill">
                                        <mat-label class="message-label" i18n="@@evaluation-send-message-button">Send a message</mat-label>
                                        <textarea [(ngModel)]="evaluationMsg" matInput placeholder="Input text" rows="2"></textarea>
                                </mat-form-field>
                        </div>
                </div>
        </div>
</div>