import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { DeleteConfirmationDialogComponent } from 'src/app/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { NotificationService } from 'src/app/shared/notification.service';
import { SkillList } from '../../models/skillList';
import { Table } from '../../models/table';
import { FirebaseService } from '../../shared/firebase.service';
import { SkillListDialogFormComponent } from '../skill-list-dialog-form/skill-list-dialog-form.component';

@Component({
  selector: 'app-skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: ['./skill-list.component.css']
})
export class SkillListComponent extends Table implements OnInit  {

  public displayedColumns: string[] = ['select', 'id', 'name', 'actions'];

  constructor(
    private firebaseService: FirebaseService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private notificationService: NotificationService) {
      super('Skill List', new Array<SkillList>(), [], true);
    }

  ngOnInit() {
    // this.firebaseService.assignDefaultSkillListToTeams('kEkh6rnS6RGuZqtFiQDW');
    this.firebaseService.getSkillList().subscribe(
      data => {
        this.dataSet = [...data];
        this.dataSource = new MatTableDataSource(this.dataSet);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isLoadingResults = false;
      },
      error => {
        console.error(error);
        this.notificationService.error('Skill list data could not be fetched');
        this.isLoadingResults = false;
      }
    );
  }

  onAdd() {
    this.dialogConfig.width = '40%';
    this.dialogConfig.data = null;
    const dialogRef = this.dialog.open(SkillListDialogFormComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoadingResults = true;
        this.firebaseService.addSkillList(result.name)
          .then(
            r => {
              if (r.status) {
                this.notificationService.success('Skill list added');
              } else {
                console.error(r.message);
                this.notificationService.error('Skill list could not be added');
              }
            })
          .finally(() => this.isLoadingResults = false);
      }
    });
  }

  onEdit(row: SkillList) {
    this.router.navigate(['skill-list-edit', row.id]);
  }

  onDelete(row: SkillList) {
    this.dialogConfig.width = '30%';
    this.dialogConfig.data = {action: 'delete team', object: row};
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.isLoadingResults = true;
        this.firebaseService.deleteSkillList(row.id)
          .then(
            r => {
              if (r.status) {
                this.notificationService.success('Skill list deleted');
              } else {
                console.error(r.message);
                this.notificationService.error('Skill list could not be deleted');
              }
            })
          .finally(() => this.isLoadingResults = false);
      }
    });
  }
}
