// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// dev_cantera
export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDFreh7PGbjbugqQXCAspeoOVXWymnLK7A',
    authDomain: 'cantera-dev.firebaseapp.com',
    databaseURL: 'https://cantera-dev.firebaseio.com',
    projectId: 'cantera-dev',
    storageBucket: 'cantera-dev.appspot.com',
    messagingSenderId: '174473535830',
    appId: '1:174473535830:web:b17439c886dbe9c6',
    measurementId: 'G-HJXLYWR3YV',
  },
  baseURL: 'https://clubs-dev.cantera.app/',
  defaultSkillListId: 'kEkh6rnS6RGuZqtFiQDW',
  defaultVideoCover: 'teams/videoCovers/defaultVideoCover.jpg',
  signUp: {
    enableParentOption: false,
  },
  home: {
    coach: {
      show: 'players',
    },
  },
  teamDashboard: {
    showStats: false,
  },
  playerDashboard: {
    showNegative: false,
  },
  versions: {
    navLogo: '../../../../assets/images/cantera_dark.svg',
    fullLogo: '../../../../assets/images/canteraLogo_full_black.svg',
    teamLogo: '../../../../assets/images/teamDefaults/defaultTeamLogo.png',
    teamVideoCover:
      '../../../../assets/images/teamDefaults/defaultVideoCover.jpg',
    teamColor: '#FD2A5C',
    teamName: 'Cantera',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
