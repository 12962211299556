<mat-toolbar color="accent">
    <span>{{ title }}</span>
    <span class="spacer"></span>
    <button
      class="btn-dialog-close"
      mat-stroked-button
      (click)="onClose()"
      tabIndex="-1"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </mat-toolbar>
  <form [formGroup]="supportedForm" class="normal-form">
    <mat-grid-list cols="1" rowHeight="20rem">
      <mat-grid-tile>
        <div class="controles-container column-form">
          <mat-form-field color="accent">
            <input formControlName="playerId" matInput placeholder="Player Id" />
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
          <mat-form-field color="accent">
            <input formControlName="userId" matInput placeholder="User Id" />
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <div class="button-row" mat-dialog-actions>
      <button mat-raised-button color="warn" (click)="onClear()">Clear</button>
      <span class="spacer"></span>
      <button
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="supportedForm.invalid"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </div>
  </form>
  
