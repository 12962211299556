<app-header></app-header>
<div class="container main-div">
  <div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <div>
    <div>
      <h1>
        {{ title }}
        <mat-icon>search</mat-icon>
      </h1>
    </div>  
    <div class="flex">
        <span>
            <mat-form-field fxFlex="40%" color="accent">
            <input
                matInput
                type="text"
                (keyup)="doFilter($event.target.value)"
                placeholder="Filter"
            />
            </mat-form-field>
        </span>
        <span class="spacer"></span>
        <span>
            <button mat-raised-button color="accent" (click)="onAdd()">
            <mat-icon>add</mat-icon>
            Add skill list
            </button>
        </span>
    </div> 

    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 row"
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="accent"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            color="accent"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Skill List ID</th>
        <td mat-cell *matCellDef="let skill">{{ skill.id }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Skill List Name</th>
        <td mat-cell *matCellDef="let skill">{{ skill.name }}</td>
      </ng-container>    

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button (click)="onEdit(row)">
            <mat-icon>launch</mat-icon>
          </button>
          <button mat-icon-button (click)="onDelete(row)">
            <mat-icon color="warn">delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
      color="accent"
    >
    </mat-paginator>

  </div>
</div>
