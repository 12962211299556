<app-header></app-header>
<div class="container main-div">
  <div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <div>
    <div>
      <h1>
        {{ title }}
        <mat-icon>search</mat-icon>
      </h1>
    </div>

    <div class="flex">
      <span class="spacer"></span>
      <span>
        <button mat-raised-button color="accent" (click)="onNewPlayer()">
          <mat-icon>add</mat-icon>
          Add player
        </button>
      </span>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 row"
    >
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="accent"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            color="accent"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              First Name
            </p>
            <mat-form-field class="shortFilter" color="accent">
              <input
                matInput
                class="form-field"
                [formControl]="firstNameFilter"
                placeholder="By first name"
              />
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let player">{{ player.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              Last Name
            </p>
            <mat-form-field class="shortFilter" color="accent">
              <input
                matInput
                class="form-field"
                [formControl]="lastNameFilter"
                placeholder="By last name"
              />
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let player">{{ player.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="teamId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              Team Id
            </p>
            <mat-form-field class="veryShortFilter" color="accent">
              <input
                matInput
                class="form-field"
                [formControl]="teamFilter"
                placeholder="By team id"
              />
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let player">{{ player.teamId }}</td>
      </ng-container>

      <ng-container matColumnDef="playerId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              Player Id
            </p>
            <mat-form-field class="shortFilter" color="accent">
              <input
                matInput
                class="form-field"
                [formControl]="playerIdFilter"
                placeholder="By player id"
              />
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let player">{{ player.playerId }}</td>
      </ng-container>

      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              User Id
            </p>
            <mat-form-field class="veryShortFilter" color="accent">
              <input
                matInput
                class="form-field"
                [formControl]="userIdFilter"
                placeholder="By user id"
              />
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let player">{{ player.userId }}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              State
            </p>
          </div>
        </th>
        <td mat-cell *matCellDef="let player"></td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              Position
            </p>
            <mat-form-field class="veryShortFilter" color="accent">
              <input
                matInput
                class="form-field"
                [formControl]="positionFilter"
                placeholder="By position"
              />
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let player">{{ player.position }}</td>
        s
      </ng-container>

      <ng-container matColumnDef="strongFoot">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              Strong Foot
            </p>
            <mat-form-field class="mediumFilter" color="accent">
              <input
                matInput
                class="form-field"
                [formControl]="strongFootFilter"
                placeholder="By strong foot"
              />
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let player">{{ player.strongFoot }}</td>
      </ng-container>

      <ng-container matColumnDef="birthDay">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              Birth Day
            </p>
            <mat-form-field class="shortFilter" color="accent">
              <input
                matInput
                class="form-field"
                [formControl]="birthDayFilter"
                placeholder="By birth day"
              />
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let player">{{ player.birthDay }}</td>
      </ng-container>

      <ng-container matColumnDef="birthMonth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              Birth Month
            </p>
            <mat-form-field class="mediumFilter" color="accent">
              <input
                matInput
                class="form-field"
                [formControl]="birthMonthFilter"
                placeholder="By birth month"
              />
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let player">{{ player.birthMonth }}</td>
      </ng-container>

      <ng-container matColumnDef="birthYear">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              Birth Year
            </p>
            <mat-form-field class="shortFilter" color="accent">
              <input
                matInput
                class="form-field"
                [formControl]="birthYearFilter"
                placeholder="By birth year"
              />
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let player">{{ player.birthYear }}</td>
      </ng-container>

      <ng-container matColumnDef="invitationCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              Invitation Code
            </p>
          </div>
        </th>
        <td mat-cell *matCellDef="let player">{{ player.invitationCode }}</td>
      </ng-container>

      <ng-container matColumnDef="profilePicturePath">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="headerContainer">
            <p class="bold">
              Profile picture path
            </p>
          </div>
        </th>
        <td mat-cell *matCellDef="let player">{{ player.profilePicturePath }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="actionCell">
          <button mat-icon-button (click)="onEditPlayer(row)">
            <mat-icon>launch</mat-icon>
          </button>
          <button mat-icon-button (click)="onDeletePlayer(row)">
            <mat-icon color="warn">delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
      color="accent"
    >
    </mat-paginator>
</div>
