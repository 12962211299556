<app-header></app-header>
<div class="container main-div">
    <div class="example-loading-shade" *ngIf="isLoadingResults">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <div *ngIf="!isLoadingResults" class="container">
        <nav aria-label="breadcrumb" class="mt-2">
            <ol class="breadcrumb actionsBg">
                    <li class="breadcrumb-item">
                            <a [href]="getPlayerDashboardLink()"
                                    class="mat-row-link"
                                    i18n="@@skill-detail-breadcrumb-player-dashboard-link">
                                    Player information
                            </a>
                    </li>
                    <li class="breadcrumb-item active"
                            aria-current="page"
                            i18n="@@skill-detail-breadcrumb-evaluation-label">
                            Skill detail
                    </li>
            </ol>
        </nav>
        <div class="row dashboardHeader">
            <div class="col-12 dashboardInfo mt-3 my-md-auto">
                <div class="row">
                    <div class="col-12">
                            <h1 class="dashboardTitle">{{ playerName }}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="d-flex flex-row">
                                    <div [className]="getCategoryBackground()">
                                        <img
                                            [src]="getCategoryIcon()"
                                            stretch="fill"
                                            class="statisticImage"
                                            width="40"
                                            height="40" />
                                    </div>
                                    <p class="headerLabel ml-2 my-auto">{{ displayedSkillCategory }}</p>
                                </div>
                                <div class="mb-2">
                                    <h2>{{ displayedSkillName }}</h2>
                                </div>
                            </div>
                            <div class="col-md-6 my-auto">
                                <div class="mb-2 text-center">
                                    <div class="row justify-content-end">
                                        <div class="col-4 col-md-3">
                                            <span class="headerLabel" i18n="@@skill-detail-component-header-ts">Sessions</span><br />
                                            <span class="h3"> {{ totalEvaluations }}</span>
                                        </div>
                                        <div class="col-4 col-md-3">
                                            <span class="headerLabel" i18n="@@skill-detail-component-header-te">TE</span><br />
                                            <span class="h3"> {{ skillScoredTimes }}</span>
                                        </div>
                                        <div class="col-4 col-md-3">
                                            <span class="headerLabel" i18n="@@skill-detail-component-header-avg">AVG</span><br />
                                            <span class="h3"> {{ skillPercentage | percent: '1.0-0' }}</span>
                                        </div>
                                    </div>
                                    <!--<span class="h3"> {{ totalActualPoints }} / {{ totalPossiblePoints }}</span>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shadow p-3 mb-5 bg-white rounded">
            <h3>
                <!--<span>{{displayedSkillName}}&nbsp;</span>-->
                <span i18n="@@skill-detail-component-header-evolution">Evolution</span>
            </h3>
            <div class="row my-4 px-2">
                <div class="col my-3" style="display: block;">
                    <canvas baseChart
                        [datasets]="lineChartData"
                        [labels]="lineChartLabels"
                        [options]="lineChartOptions"
                        [colors]="lineChartColors"
                        [legend]="lineChartLegend"
                        [chartType]="lineChartType"
                        [plugins]="lineChartPlugins">
                    </canvas>
                </div>
            </div>
            <div>
                <h4 i18n="@@skill-detail-component-detail-table-title">Detail</h4>
                <div class="table-responsive">
                    <table class="table table-borderless table-striped table-sm">
                        <thead>
                            <tr>
                                <th i18n="@@skill-detaill-component-detail-table-header-date">Date</th>
                                <th class="text-center" i18n="@@skill-detail-component-detail-table-header-result">Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let result of skillScores">
                                <tr>
                                    <td class="align-middle">
                                        {{getEvaluationDate(result)}}
                                    </td>
                                    <td class="align-middle text-center">
                                        <ng-container [ngSwitch]="result.skillResult">
                                            <img *ngSwitchCase="-2"
                                                src="assets/images/eval_very_disatisfied_outline.png"/>
                                            <img *ngSwitchCase="-1"
                                                src="assets/images/eval_disatisfied_outline.png"/>
                                            <img *ngSwitchCase="1"
                                                src="assets/images/eval_satisfied_outline.png"/>
                                            <img *ngSwitchCase="2"
                                                src="assets/images/eval_very_satisfied_outline.png"/>
                                        </ng-container>
                                        <span class="ml-1 align-self-center">({{result.skillResult}})</span>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
