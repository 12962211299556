import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppMessage } from '../models/app-message';
import { Evaluation } from '../models/evaluation';
import { EvaluationItem } from '../models/evaluationItem';
import { OverallStatistics } from '../models/overallStatistics';
import { Player } from '../models/player';
import { PlayerDrill } from '../models/playerDrill';
import { PlayerSkillSummary, SkillSummary } from '../models/playerSkillSummary';
import { Skill } from '../models/skill';
import { SkillLanguage } from '../models/skillLanguage';
import { SkillList } from '../models/skillList';
import { Supported } from '../models/supported';
import { Team } from '../models/team';
import { User } from '../models/user';
import { UserInfo } from '../models/userInfo';
import { UserMap } from '../models/userMap';
import { EvaluationItemResult } from '../models/evaluationItemResult';
import { MediaMetadata } from '../models/mediaMetadata';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private functions: AngularFireFunctions
  ) {}

  async createId(): Promise<string> {
    return await this.db.createId();
  }

  getEmailAdmin(userEmail: string) {
    return this.db
      .collection('admins', (ref) => ref.where('email', '==', userEmail))
      .valueChanges();
  }

  getTeams(): Observable<Team[]> {
    return this.db
      .collection('teams')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Team;
            data.id = a.payload.doc.id;
            return data;
          })
        )
      );
  }

  getMappedTeams(userId: string) {
    return this.db
      .collection('usermap', (ref) => ref.where('userId', '==', userId))
      .valueChanges();
  }

  getUserMapByTeamAndUser(userId: string, teamId: string) {
    return this.db
      .collection('usermap', (ref) => ref
        .where('userId', '==', userId)
        .where('teamId', '==', teamId))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as UserMap;
            const { id } = a.payload.doc;
            return { id, ...data };
          })
        )
      );
  }

  getTeam(teamId: string) {
    return this.db.collection('teams').doc<Team>(teamId).valueChanges();
  }

  getTeamPromise(teamId: string): Promise<Team> {
    return this.db.collection('teams').doc<Team>(teamId).valueChanges().toPromise();
  }

  getTeamPlayers(teamId: string) {
    return this.db
      .collection('players', (ref) => ref.where('teamId', '==', teamId))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Player;
            const playerId = a.payload.doc.id;
            return { playerId, ...data };
          })
        )
      );
  }

  getTeamUsers(teamId: string) {
    return this.db
      .collection('usermap', (ref) => ref.where('teamId', '==', teamId))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as User;
            const usermapId = a.payload.doc.id;
            return { usermapId, ...data };
          })
        )
      );
  }

  getPlayer(playerId: string) {
    return this.db.collection('players').doc<Player>(playerId).valueChanges();
  }

  getPlayerMediaPicsUrls(playerId: string) {
    return this.db
      .collection('playersmedia', (ref) =>
        ref
          .where('playerId', '==', playerId)
          .where('type', '==', 'image')
          .orderBy('uploadDate', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as MediaMetadata;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getPlayerMediaVidsUrls(playerId: string) {
    return this.db
      .collection('playersmedia', (ref) =>
        ref
          .where('playerId', '==', playerId)
          .where('type', '==', 'video')
          .orderBy('uploadDate', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as MediaMetadata;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
      // .valueChanges();
  }

  getPlayerByUserId(userId: string) {
    return this.db
      .collection('players', (ref) => ref.where('userId', '==', userId))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Player;
            const playerId = a.payload.doc.id;
            return { playerId, ...data };
          })
        )
      );
  }

  getUsers() {
    return this.db
      .collection('usermap')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as User;
            const usermapId = a.payload.doc.id;
            return { usermapId, ...data };
          })
        )
      );
  }

  getSupporteds(userId: string) {
    return this.db
      .collection('supporteds', (ref) => ref.where('userId', '==', userId))
      .valueChanges();
  }

  getAllSupporteds() {
    return this.db
      .collection('supporteds')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Supported;
            const supportedDocId = a.payload.doc.id;
            return { supportedDocId, ...data };
          })
        )
      );
  }

  getPlayers() {
    return this.db
      .collection('players')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Player;
            const playerId = a.payload.doc.id;
            return { playerId, ...data };
          })
        )
      );
  }

  getPlayerDrills() {
    return this.db
      .collection('playerdrills')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as PlayerDrill;
            const playerDrillId = a.payload.doc.id;
            return { playerDrillId, ...data };
          })
        )
      );
  }

  getUsersInfo() {
    return this.db
      .collection('userinfo')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as UserInfo;
            const userinfoId = a.payload.doc.id;
            return { userinfoId, ...data };
          })
        )
      );
  }

  getUserInfoById(userId: string) {
    return this.db
      .collection('userinfo', (ref) => ref.where('userId', '==', userId))
      .valueChanges();
  }

  async getUserInfoByIdWithPicURL(userId: string) {
    return this.db
      .collection('userinfo')
      .snapshotChanges()
      .pipe(
        map(async (actions) =>
          actions.map(async (a) => {
            const data = a.payload.doc.data() as UserInfo;
            data.userId = a.payload.doc.id;
            if (data.profilePicturePath) {
              data.profilePictureUrl = await this.getProfilePicturePromise(data.profilePicturePath);
            } else {
              data.profilePictureUrl = await this.getProfilePicturePromise('profilePictures/default.png');
            }

            return data;
          })
        )
      );
    const res = await this.db
      .collection('userinfo', (ref) => ref.where('userId', '==', userId))
      .snapshotChanges().toPromise();
    return res.map;
  }

  getSkills() {
    return this.db
      .collection('skills')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Skill;
            const skillId = a.payload.doc.id;
            return { skillId, ...data };
          })
        )
      );
  }

  getFilteredSkills(skillList: string[]) {
    const ids = skillList.join(',');
    return this.db
      .collection('skills')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Skill;
            const skillId = a.payload.doc.id;
            return { skillId, ...data };
          })
          .filter((s: Skill) => ids.includes(s.skillId))
        )
      );
  }

  getSkillsByCategory(category: string) {
    return this.db
      .collection('skills', (ref) => ref.where('category', '==', category))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Skill;
            const skillId = a.payload.doc.id;
            return { skillId, ...data };
          })
        )
      );
  }

  getSkillsByCode(code: string) {
    return this.db
      .collection('skills', (ref) => ref.where('code', '==', code))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Skill;
            const skillId = a.payload.doc.id;
            return { skillId, ...data };
          })
        )
      );
  }

  getSkillLanguages(skillId: string) {
    return this.db
      .collection('skills')
      .doc(skillId)
      .collection('languages')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const langId = a.payload.doc.id;
            const data = a.payload.doc.data();
            return { skillId, langId: langId, ...data } as SkillLanguage;
          })
        )
      );
  }

  getSkillList(): Observable<SkillList[]> {
    return this.db
      .collection('skillslist')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as SkillList;
            data.id = a.payload.doc.id;
            return data;
          })
        )
      );
  }

  getSkillListById(id: string) {
    return this.db
      .collection('skillslist')
      .doc(id)
      .get()
      .pipe(
        map((doc) => {
          const skillList = new SkillList();
          skillList.id = doc.id;
          skillList.name = (doc.data() as any).name;
          skillList.skills = (doc.data() as any).skills;
          return skillList;
        })
      );
  }

  changeUserForTeam(oldUserId: string, newUserId: string) {
    // Teams
    this.db
      .collection('teams')
      .get()
      .forEach((doc) => {
        doc.docs.forEach((t) => {
          if ((t.data() as any).userId === oldUserId) {
            this.db.collection('teams').doc(t.id).update({
              userId: newUserId,
            });
          }
        });
      });

    // Usermap
    this.db
      .collection('usermap')
      .get()
      .forEach((doc) => {
        doc.docs.forEach((t) => {
          if ((t.data() as any).userId === oldUserId) {
            this.db.collection('usermap').doc(t.id).update({
              userId: newUserId,
            });
          }
        });
      });
  }

  addPlayer(
    teamId: string,
    firstName: string,
    lastName: string,
    userId: string,
    position: string,
    strongFoot: string,
    birthDay: string,
    birthMonth: string,
    birthYear: string,
    invitationCode: string,
    profilePicturePath: string
  ) {
    const collection = this.db.collection('players');
    if (userId) {
      if (invitationCode) {
        profilePicturePath
          ? collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              userId: userId,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
              invitationCode: invitationCode,
              profilePicturePath: profilePicturePath,
            })
          : collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              userId: userId,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
              invitationCode: invitationCode,
            });
      } else {
        profilePicturePath
          ? collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              userId: userId,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
              profilePicturePath: profilePicturePath,
            })
          : collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              userId: userId,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
            });
      }
    } else {
      if (invitationCode) {
        profilePicturePath
          ? collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
              invitationCode: invitationCode,
              profilePicturePath: profilePicturePath,
            })
          : collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
              invitationCode: invitationCode,
            });
      } else {
        profilePicturePath
          ? collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
              profilePicturePath: profilePicturePath,
            })
          : collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
            });
      }
    }
  }

  addPlayerNoDate(
    teamId: string,
    firstName: string,
    lastName: string,
    userId: string,
    position: string,
    strongFoot: string,
    invitationCode: string,
    profilePicturePath: string
  ) {
    const collection = this.db.collection('players');
    if (userId) {
      if (invitationCode) {
        profilePicturePath
          ? collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              userId: userId,
              strongFoot: strongFoot,
              invitationCode: invitationCode,
              profilePicturePath: profilePicturePath,
            })
          : collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              userId: userId,
              strongFoot: strongFoot,
              invitationCode: invitationCode,
            });
      } else {
        profilePicturePath
          ? collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              userId: userId,
              strongFoot: strongFoot,
              profilePicturePath: profilePicturePath,
            })
          : collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              userId: userId,
              strongFoot: strongFoot,
            });
      }
    } else {
      if (invitationCode) {
        profilePicturePath
          ? collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              strongFoot: strongFoot,
              invitationCode: invitationCode,
              profilePicturePath: profilePicturePath,
            })
          : collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              strongFoot: strongFoot,
              invitationCode: invitationCode,
            });
      } else {
        profilePicturePath
          ? collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              strongFoot: strongFoot,
              profilePicturePath: profilePicturePath,
            })
          : collection.add({
              firstName: firstName,
              lastName: lastName,
              position: position,
              teamId: teamId,
              strongFoot: strongFoot,
            });
      }
    }
  }

  editPlayer(
    playerId: string,
    firstName: string,
    lastName: string,
    teamId: string,
    userId: string,
    position: string,
    strongFoot: string,
    birthDay: string,
    birthMonth: string,
    birthYear: string,
    invitationCode: string,
    profilePicturePath: string
  ) {
    const document = this.db.collection('players').doc(playerId);
    if (userId) {
      if (invitationCode !== null) {
        profilePicturePath
          ? document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              userId: userId,
              position: position,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
              invitationCode: invitationCode,
              profilePicturePath: profilePicturePath,
            })
          : document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              userId: userId,
              position: position,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
              invitationCode: invitationCode,
            });
      } else {
        profilePicturePath
          ? document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              userId: userId,
              position: position,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
              profilePicturePath: profilePicturePath,
            })
          : document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              userId: userId,
              position: position,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
            });
      }
    } else {
      if (invitationCode) {
        profilePicturePath
          ? document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              position: position,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
              invitationCode: invitationCode,
              profilePicturePath: profilePicturePath,
            })
          : document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              position: position,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
              invitationCode: invitationCode,
            });
      } else {
        profilePicturePath
          ? document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              position: position,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
              profilePicturePath: profilePicturePath,
            })
          : document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              position: position,
              strongFoot: strongFoot,
              birthDay: birthDay,
              birthMonth: birthMonth,
              birthYear: birthYear,
            });
      }
    }
  }

  editPlayerNoDate(
    playerId: string,
    firstName: string,
    lastName: string,
    teamId: string,
    userId: string,
    position: string,
    strongFoot: string,
    invitationCode: string,
    profilePicturePath: string
  ) {
    const document = this.db.collection('players').doc(playerId);
    if (userId) {
      if (invitationCode) {
        profilePicturePath
          ? document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              userId: userId,
              position: position,
              strongFoot: strongFoot,
              invitationCode: invitationCode,
              profilePicturePath: profilePicturePath,
            })
          : document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              userId: userId,
              position: position,
              strongFoot: strongFoot,
              invitationCode: invitationCode,
            });
      } else {
        profilePicturePath
          ? document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              userId: userId,
              position: position,
              strongFoot: strongFoot,
              profilePicturePath: profilePicturePath,
            })
          : document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              userId: userId,
              position: position,
              strongFoot: strongFoot,
            });
      }
    } else {
      if (invitationCode) {
        profilePicturePath
          ? document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              position: position,
              strongFoot: strongFoot,
              invitationCode: invitationCode,
              profilePicturePath: profilePicturePath,
            })
          : document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              position: position,
              strongFoot: strongFoot,
              invitationCode: invitationCode,
            });
      } else {
        profilePicturePath
          ? document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              position: position,
              strongFoot: strongFoot,
              profilePicturePath: profilePicturePath,
            })
          : document.update({
              firstName: firstName,
              lastName: lastName,
              teamId: teamId,
              position: position,
              strongFoot: strongFoot,
            });
      }
    }
  }

  deletePlayer(playerId: string) {
    this.db.collection('players').doc(playerId).delete();
  }

  addPlayerDrillLink(
    playerId: string,
    drillName: string,
    drillCategory: string,
    drillLink: string,
    linkLanguage: string,
    active: boolean
  ) {
    const collection = this.db.collection('playerdrills');
    collection
      .add({
        playerId: playerId,
        drillName: drillName,
        drillCategory: drillCategory,
        drillLink: drillLink,
        linkLanguage: linkLanguage,
        active: active,
        drillType: 'link',
        drillMediaURL: '',
        drillMediaPath: '',
        drillMediaFormat: ''
      })
      .then(() => {});
  }

  addCustomDrill(newDrill: PlayerDrill) {
    const collection = this.db.collection('playerdrills');
    collection.doc(newDrill.playerDrillId).set({
        playerId: newDrill.playerId,
        drillName: newDrill.drillName,
        drillCategory: newDrill.drillCategory,
        drillLink: newDrill.drillLink,
        linkLanguage: newDrill.linkLanguage,
        active: newDrill.active,
        drillMediaURL: newDrill.drillMediaURL,
        drillMediaPath: newDrill.drillMediaPath,
        drillMediaFormat: newDrill.drillMediaFormat,
        drillText: newDrill.drillText,
        drillType: newDrill.drillType
    });
  }

  addCustomCopiedDrill(newDrill: PlayerDrill) {
    const collection = this.db.collection('playerdrills');
    collection.add({
        playerId: newDrill.playerId ? newDrill.playerId : '',
        drillName: newDrill.drillName ? newDrill.drillName : '',
        drillCategory: newDrill.drillCategory ? newDrill.drillCategory : '',
        drillLink: newDrill.drillLink ? newDrill.drillLink : '',
        linkLanguage: newDrill.linkLanguage ? newDrill.linkLanguage : '',
        active: newDrill.active ? newDrill.active : true,
        drillMediaURL: newDrill.drillMediaURL ? newDrill.drillMediaURL : '',
        drillMediaPath: newDrill.drillMediaPath ? newDrill.drillMediaPath : '',
        drillMediaFormat: newDrill.drillMediaFormat ? newDrill.drillMediaFormat : '',
        drillType: newDrill.drillType ? newDrill.drillType : '',
        drillText: newDrill.drillText ? newDrill.drillText : '',
    });
  }

  addCustomCopiedDrillNoText(newDrill: PlayerDrill) {
    const collection = this.db.collection('playerdrills');
    collection.add({
        playerId: newDrill.playerId ? newDrill.playerId : '',
        drillName: newDrill.drillName ? newDrill.drillName : '',
        drillCategory: newDrill.drillCategory ? newDrill.drillCategory : '',
        drillLink: newDrill.drillLink ? newDrill.drillLink : '',
        linkLanguage: newDrill.linkLanguage ? newDrill.linkLanguage : '',
        active: newDrill.active ? newDrill.active : true,
        drillMediaURL: newDrill.drillMediaURL ? newDrill.drillMediaURL : '',
        drillMediaPath: newDrill.drillMediaPath ? newDrill.drillMediaPath : '',
        drillMediaFormat: newDrill.drillMediaFormat ? newDrill.drillMediaFormat : '',
        drillType: newDrill.drillType ? newDrill.drillType : ''
    });
  }

  editPlayerDrill(
    playerDrillId: string,
    playerId: string,
    drillName: string,
    drillCategory: string,
    drillLink: string,
    linkLanguage: string,
    active: boolean
  ) {
    const document = this.db.collection('playerdrills').doc(playerDrillId);
    document
      .update({
        playerId: playerId,
        drillName: drillName,
        drillCategory: drillCategory,
        drillLink: drillLink,
        linkLanguage: linkLanguage,
        active: active,
      })
      .then(() => {});
  }

  deletePlayerDrill(playerDrillId: string) {
    this.db.collection('playerdrills').doc(playerDrillId).delete();
  }

  getPlayerDrillsWherePlayerId(
    playerId: string
  ) {
    return this.db
      .collection('playerdrills', (ref) =>
        ref
          .where('playerId', '==', playerId)
          .where('active', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as PlayerDrill;
          const playerDrillId = a.payload.doc.id;
          return { playerDrillId, ...data};
        }))
      );
  }

  addSkillList(name: string) {
    const collection = this.db.collection('skillslist');
    return collection
      .add({ name })
      .then(() => ({ status: true, message: '' }))
      .catch((e) => ({ status: false, message: e }));
  }

  deleteSkillList(id: string) {
    return this.db
      .collection('skillslist')
      .doc(id)
      .delete()
      .then(() => ({ status: true, message: '' }))
      .catch((e) => ({ status: false, message: e }));
  }

  editSkillList(id: string, name: string, skills: string[]) {
    const document = this.db.collection('skillslist').doc(id);
    return document
      .update({
        name,
        skills,
      })
      .then(() => ({ status: true, message: '' }))
      .catch((e) => ({ status: false, message: e }));
  }

  getPlayerDrillsWhereTeamId(
    teamId: string
  ) {
    return this.db
      .collection('playerdrills', (ref) =>
        ref
          .where('playerId', '==', teamId)
          // .where('drillCategory', '==', drillCategory)
          .where('active', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as PlayerDrill;
          const playerDrillId = a.payload.doc.id;
          return { playerDrillId, ...data};
        }))
      );
  }

  getPlayerDrillsWhereAll() {
    return this.db
      .collection('playerdrills', (ref) =>
        ref
          .where('playerId', '==', 'all')
          // .where('drillCategory', '==', drillCategory)
          .where('active', '==', true)
      )
      .valueChanges();
  }

  getPlayerDrillFromId(id: string) {
    return this.db.collection('playerdrills').doc<PlayerDrill>(id).valueChanges();
  }


  async getPlayerDrillFromIdPromise(id: string): Promise<PlayerDrill> {
    const drillDocuments = await this.db.collection('playerdrills').doc<PlayerDrill>(id)
    .valueChanges().toPromise().then(res => {
      if (res) {
        return res;
      }
    }).catch( error => {
      return undefined;
    });
    return drillDocuments;
  }

  addTeam(
    name: string,
    year: string,
    userId: string,
    skillListId: string,
    invitationCode: string,
    teamColor: string,
    teamLogo: string,
    premium: boolean,
    teamVideoCover: string,
    membershipMonthCutOffDay: number
  ) {
    const collection = this.db.collection('teams');
    return collection
      .add({
        name,
        year,
        userId,
        skillListId,
        invitationCode,
        teamColor,
        teamLogo,
        premium,
        teamVideoCover,
        membershipMonthCutOffDay
      })
      .then(() => ({ status: true, message: '' }))
      .catch((e) => ({ status: false, message: e }));
  }

  addTeamInvitationCode(
    name: string,
    year: string,
    userId: string,
    skillListId: string,
    invitationCode: string,
    teamColor: string,
    teamLogo: string,
    premium: boolean,
    teamVideoCover: string,
    membershipMonthCutOffDay: number
  ) {
    const collection = this.db.collection('teams');
    return collection
      .add({
        name,
        year,
        userId,
        skillListId,
        invitationCode,
        teamColor,
        teamLogo,
        premium,
        teamVideoCover,
        membershipMonthCutOffDay
      })
      .then((ref) => ({ status: true, message: '', id: ref.id || null }))
      .catch((e) => ({ status: false, message: e, id: null }));
  }

  editTeam(
    teamId: string,
    name: string,
    year: string,
    userId: string,
    skillListId: string,
    invitationCode: string,
    teamColor: string,
    teamLogo: string,
    premium: boolean,
    teamVideoCover: string,
    membershipMonthCutOffDay: number
  ) {
    const document = this.db.collection('teams').doc(teamId);
    return document
      .update({
        name,
        year,
        userId,
        skillListId,
        invitationCode,
        teamColor,
        teamLogo,
        premium,
        teamVideoCover,
        membershipMonthCutOffDay
      })
      .then(() => ({ status: true, message: '' }))
      .catch((e) => ({ status: false, message: e }));
  }

  editTeamInvitationCode(
    teamId: string,
    name: string,
    year: string,
    userId: string,
    skillListId: string,
    invitationCode: string,
    teamColor: string,
    teamLogo: string,
    premium: boolean,
    teamVideoCover: string,
    membershipMonthCutOffDay: number
  ) {
    const document = this.db.collection('teams').doc(teamId);
    return document
      .update({
        name,
        year,
        userId,
        skillListId,
        invitationCode,
        teamColor,
        teamLogo,
        premium,
        teamVideoCover,
        membershipMonthCutOffDay
      })
      .then(() => ({ status: true, message: '' }))
      .catch((e) => ({ status: false, message: e }));
  }

  deleteTeam(teamId: string) {
    return this.db
      .collection('teams')
      .doc(teamId)
      .delete()
      .then(() => ({ status: true, message: '' }))
      .catch((e) => ({ status: false, message: e }));
  }

  assignDefaultSkillListToTeams(skillListId: string) {
    this.db
      .collection('teams')
      .get()
      .forEach((doc) => {
        doc.docs.forEach((t) => {
          this.db.collection('teams').doc(t.id).update({
            skillListId,
          });
        });
      });
  }

  // Statistics functions
  getPlayerOverallStatistics(playerId: string) {
    return this.db
      .collection('playerstatsoverall', (ref) =>
        ref.where('playerId', '==', playerId)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as OverallStatistics;
            // const playerId = a.payload.doc.id;
            return { playerId, ...data };
          })
        )
      );
  }

  getPlayerEvaluationsBySkillCode(playerId: string, skillCode: string) {
    return this.db
      .collection('evaluations', (ref) =>
        ref
          .where('playerId', '==', playerId)
          .where('skillCode', '==', skillCode)
      )
      .valueChanges();
  }

  getTeamEvaluationsBySkillCode(teamId: string, skillCode: string) {
    return this.db
      .collection('evaluations', (ref) =>
        ref.where('teamId', '==', teamId).where('skillCode', '==', skillCode)
      )
      .valueChanges();
  }

  getTeamOverallStatistics(teamId: string) {
    return this.db
      .collection('teamstatsoverall', (ref) =>
        ref.where('teamId', '==', teamId)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as OverallStatistics;
            // const playerId = a.payload.doc.id;
            return { teamId, ...data };
          })
        )
      );
  }

  getPlayerDailyStatistics(playerId: string) {
    return this.db
      .collection('playerstatsdaily', (ref) =>
        ref.where('playerId', '==', playerId)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as OverallStatistics;
            // const playerId = a.payload.doc.id;
            return { playerId, ...data };
          })
        )
      );
  }

  getTeamDailyStatistics(teamId: string) {
    return this.db
      .collection('teamstatsdaily', (ref) => ref.where('teamId', '==', teamId))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as OverallStatistics;
            // const playerId = a.payload.doc.id;
            return { teamId, ...data };
          })
        )
      );
  }

  getPlayerAbsences(playerId: string) {
    return this.db
      .collection('evaluations', (ref) =>
        ref
          .where('playerId', '==', playerId)
          .where('skillCode', '==', 'AT-E-PAT')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Evaluation;
            // const playerId = a.payload.doc.id;
            return data;
          })
        )
      );
  }

  getPlayerEvaluatedDates(playerId: string) {
    return this.db
      .collection('evaluations', (ref) =>
        ref
          .where('playerId', '==', playerId)
      ).snapshotChanges()
      .pipe(
        map((evaluations) =>
          evaluations.map((a) => {
            const data = a.payload.doc.data() as Evaluation;
            // const playerId = a.payload.doc.id;
            const evalDate = new Date(data.year, data.month - 1, data.day);
            return evalDate;
          })
        )
      );
  }

  getPlayerSkillSummary(playerId: string) {
    return this.db
      .collection('playerskillssummary', (ref) =>
        ref.where('playerId', '==', playerId)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as PlayerSkillSummary;
            // const playerId = a.payload.doc.id;
            return { playerId, ...data };
          })
        )
      );
  }

  getPlayerSkillSummaryMetricSkills(playerId: string) {
    const skillspath = 'playerskillssummary/' + playerId + '/skills';
    return this.db
      .collection(skillspath)
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as SkillSummary;
            // const playerId = a.payload.doc.id;
            return { ... data };
          })
        ),
        map(items => items.filter(item => (item.code.indexOf('ME-Q') > -1 )))
      );
  }

  addUserMap(
    name: string,
    teamId: string,
    userId: string,
    playerId: string,
    year: string,
    paramDefault: boolean,
    role: string
  ) {
    const collection = this.db.collection('usermap');
    playerId ?
    collection
      .add({
        name: name,
        teamId: teamId,
        userId: userId,
        playerId: playerId,
        year: year,
        default: paramDefault,
        role: role,
      })
      :
      collection
      .add({
        name: name,
        teamId: teamId,
        userId: userId,
        year: year,
        default: paramDefault,
        role: role,
      });
  }

  editUserMap(
    usermapId: string,
    name: string,
    teamId: string,
    userId: string,
    playerId: string,
    year: string,
    paramDefault: boolean,
    role: string
  ) {
    const document = this.db.collection('usermap').doc(usermapId);
    playerId !== null ?
    document
      .update({
        name: name,
        teamId: teamId,
        userId: userId,
        playerId: playerId,
        year: year,
        default: paramDefault,
        role: role,
      })
      :
      document
      .update({
        name: name,
        teamId: teamId,
        userId: userId,
        year: year,
        default: paramDefault,
        role: role,
      });
  }

  deleteUserMap(usermapId: string) {
    this.db.collection('usermap').doc(usermapId).delete();
  }

  getUserMap(
    userId: string
  ) {
    return this.db
      .collection('usermap', (ref) => ref.where('userId', '==', userId).where('default', '==', true))
      .get()
      .toPromise()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const userMapId = querySnapshot.docs[0].id;
          return { userMapId, ...querySnapshot.docs[0].data() as UserMap };
        } else {
          return undefined;
        }
      });
  }

  addUserInfo(
    name: string,
    lastName: string,
    language: string,
    userId: string,
    profilePicturePath: string,
    email: string
  ) {
    const collection = this.db.collection('userinfo');
    collection
      .add({
        name: name,
        lastName: lastName,
        userId: userId,
        language: language,
        profilePicturePath: profilePicturePath,
        email: email
      })
      .then(() => {});
  }

  editUserInfo(
    userinfoId: string,
    name: string,
    lastName: string,
    language: string,
    userId: string,
    profilePicturePath: string,
    email: string
  ) {
    const document = this.db.collection('userinfo').doc(userinfoId);
    document
      .update({
        name: name,
        lastName: lastName,
        userId: userId,
        language: language,
        profilePicturePath: profilePicturePath,
        email: email
      })
      .then(() => {});
  }

  deleteUserInfo(userinfoId: string) {
    this.db.collection('userinfo').doc(userinfoId).delete();
  }

  addSupported(playerId: string, userId: string) {
    const collection = this.db.collection('supporteds');
    collection
      .add({
        playerId: playerId,
        userId: userId,
      })
      .then(() => {});
  }

  editSupported(supportedDocId: string, playerId: string, userId: string) {
    const document = this.db.collection('supporteds').doc(supportedDocId);
    document
      .update({
        playerId: playerId,
        userId: userId,
      })
      .then(() => {});
  }

  deleteSupported(supportedDocId: string) {
    this.db.collection('supporteds').doc(supportedDocId).delete();
  }

  getProfilePicture(profilePicPath: string) {
    let profilePicUrl = new Observable<string | null>();
    const ref = this.storage.ref(profilePicPath);
    profilePicUrl = ref.getDownloadURL();
    return profilePicUrl;
  }

  getTeamProfilePicture(profilePicPath: string) {
    let profilePicUrl = new Observable<string | null>();
    const ref = this.storage.ref(profilePicPath);
    profilePicUrl = ref.getDownloadURL();
    return profilePicUrl;
  }

  async getProfilePicturePromise(profilePicPath: string): Promise<string> {
    let profilePicUrl: Promise<string>;
    const ref = this.storage.ref(profilePicPath);
    profilePicUrl = ref.getDownloadURL().toPromise();
    return profilePicUrl;
  }

  async getFileByPath(filePath: string) {
    const ref = this.storage.ref(filePath);
    const fileUrl = await ref.getDownloadURL().toPromise();
    return fileUrl;
  }

  editActiveSkill(skillId: string, active: boolean) {
    const document = this.db.collection('skills').doc(skillId);
    document
      .update({
        active: active,
      })
      .then(() => {});
  }

  getUserInfoByUserId(userId: string) {
    return this.db
      .collection('userinfo', (ref) => ref.where('userId', '==', userId))
      .get()
      .toPromise()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const userInfoId = querySnapshot.docs[0].id;
          return { userInfoId, ...querySnapshot.docs[0].data() as UserInfo };
        } else {
          return undefined;
        }
      });
  }

  getPlayerByCode(code: string) {
    return this.db
      .collection('players', (ref) => ref.where('invitationCode', '==', code))
      .get()
      .toPromise()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const playerId = querySnapshot.docs[0].id;
          return { playerId, ...querySnapshot.docs[0].data() as Player };
        } else {
          return undefined;
        }
      });
  }

  getTeamByCode(code: string) {
    return this.db
      .collection('teams', (ref) => ref.where('invitationCode', '==', code))
      .get()
      .toPromise()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const teamId = querySnapshot.docs[0].id;
          return { teamId, ...querySnapshot.docs[0].data() as Team };
        } else {
          return undefined;
        }
      });
  }

  getPlayerByUserId2(userId: string) {
    return this.db
      .collection('players', (ref) => ref.where('userId', '==', userId))
      .get()
      .toPromise()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const playerId = querySnapshot.docs[0].id;
          return { playerId, ...querySnapshot.docs[0].data() as Player };
        } else {
          return undefined;
        }
      });
  }

  getEvaluationByPlayer(teamId: string, playerId: string) {
    return this.db
      .collection('evaluations', (ref) => ref
        .where('teamId', '==', teamId)
        .where('playerId', '==', playerId)
        )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Evaluation;
            const evaluationId = a.payload.doc.id;
            return { evaluationId, ...data };
          })
        )
      );
  }

  getMessagesForPlayer(audienceId: string) {
    return this.db
    .collection('messages', (ref) => ref
      .where('audience', '==', audienceId)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as AppMessage;
            const mId = a.payload.doc.id;
            data.messageId = mId;
            return data;
          })
        )
      );
  }

  getMessagesWhereAll(groupId: string) {
    return this.db
    .collection('messages', (ref) => ref
      .where('groupId', '==', groupId)
      .where('audience', '==', 'all')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as AppMessage;
            const mId = a.payload.doc.id;
            return { mId, ...data };
          })
        )
      );
  }

  getMessagesWhereRole(groupId: string, role: string) {
    return this.db
    .collection('messages', (ref) => ref
      .where('groupId', '==', groupId)
      .where('audience', '==', role)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as AppMessage;
            const mId = a.payload.doc.id;
            return { mId, ...data };
          })
        )
      );
  }

  addMessage(m: AppMessage) {
    const collection = this.db.collection('messages');
    collection
      .add({
        audience: m.audience,
        userId: m.userId,
        groupId: m.groupId,
        message: m.message,
        subject: m.subject,
        dateTime: m.dateTime
      })
      .then(() => {});
  }

  deleteMessage(messageId: string) {
    this.db.collection('messages').doc(messageId).delete();
  }
  async addEvaluation(teamId: string,
    playerId: string,
    userId: string,
    year: number,
    month: number,
    day: number,
    evaluation: EvaluationItem[]): Promise<any> {
      const batch = this.db.firestore.batch();

      evaluation.forEach(async (e) => {
      if (e.itemId) { // edit existing item
        const doc = this.db.firestore.collection('evaluations').doc(e.itemId);
        if (e.skillCode.startsWith('ME-Q')) {
          e.quantitativeResult = e.quantitativeResult ? parseFloat(e.quantitativeResult.toString()) : EvaluationItemResult.Average;
          batch.update(doc, { skillResult: e.quantitativeResult });
        } else if (e.skillCode.startsWith('AT-')) {
          batch.update(doc, { statusResult: e.statusResult });
        } else {
          batch.update(doc, { skillResult: e.skillResult });
        }
      } else { // new evaluation item
        const newEvaluation = {
          teamId: teamId,
          playerId: playerId,
          userId: userId,
          year: year,
          month: month,
          day: day,
          skillCode: e.skillCode
        };

        if (e.skillCode.startsWith('ME-Q') && e.quantitativeResult !== undefined) {
          newEvaluation['skillResult'] = parseFloat(e.quantitativeResult.toString());
        } else if (e.skillCode.startsWith('AT-') && e.statusResult !== undefined) {
          newEvaluation['statusResult'] = e.statusResult;
        } else {
          newEvaluation['skillResult'] = e.skillResult;
        }

        const doc = this.db.firestore.collection('evaluations').doc();
        batch.set(doc, newEvaluation);
        const document = this.db.firestore.collection('players').doc(playerId);
        document
          .update({
            lastEvaluationDay: day,
            lastEvaluationMonth: month,
            lastEvaluationYear: year,
          })
          .then(() => {});
      }
    });

    return batch
      .commit()
      .then(() => true)
      .catch((err) => false);
    }

    generateStatisticsCF(teamId: string, playerId: string, role: string, today: Date) {
      const generateStatistics = this.functions.httpsCallable('generateStatistics');
      generateStatistics(
        JSON.stringify({
          role: role,
          teamId: teamId,
          playerId: playerId,
          day: today.getDate(),
          month: today.getMonth() + 1,
          year: today.getFullYear()
        })
      );
    }

    generateSkillsSummaryCF(teamId: string, playerId: string, role: string, today: Date) {
      const generateSkillsSumarryCloudFn = this.functions.httpsCallable('generateSkillsSummary');
      generateSkillsSumarryCloudFn(
        JSON.stringify({
          role: role,
          teamId: teamId,
          playerId: playerId,
          day: today.getDate(),
          month: today.getMonth() + 1,
          year: today.getFullYear()
        })
      );
    }

    deleteFileFromCollection(id: string) {
      return this.db.collection('playersmedia').doc(id).delete();
    }

    deleteFileFromStorage(url: string) {
      return this.storage.storage.refFromURL(url).delete();
    }
}
