<nav class="navbar navbar-dark bg-dark">
    <a class="navbar-brand" href="#">
        <img [src]="navLogo" width="30" height="30" class="d-inline-block align-top" alt="">
    </a>
    <!--<ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a i18n class="nav-link" href="https://cantera.app">Back to site</a>
      </li>
    </ul>-->
    <button i18n="@@sign-up-title" mat-raised-button color="accent" class="py-2"
      (click)="goToSignUp()">Sign Up</button>
  </nav>
<div class="container main-div">
  <div class="row">
    <div class="col-md-6 mt-4">
      <!--<mat-icon svgIcon="cantera_logo_full" [inline]="true"></mat-icon>-->
      <img class="img-fluid" [src]="fullLogo">
    </div>
    <div class="col-md-6">
      <mat-card class="mat-elevation-z0">
        <mat-card-header>
          <mat-card-title>
            <h1 i18n>
                Log in
            </h1>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form>
            <div class="row">
              <div class="col-12">
                <mat-form-field class="w-100" color="accent">
                  <input i18n-placeholder matInput placeholder="Email" [(ngModel)]="email" name="email" required>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-form-field  class="w-100" color="accent">
                  <input i18n-placeholder matInput placeholder="Password" [(ngModel)]="password"type="password" name="password" required>
                </mat-form-field>
              </div>
            </div>
          </form>
        <mat-spinner [style.display]="false ? 'block' : 'none'"></mat-spinner>
      </mat-card-content>
      <div class="d-lg-inline-flex d-flex flex-lg-row flex-column px-lg-3">
        <!--<div class="col-lg-6 col-12">-->
          <button i18n mat-raised-button class="loginButtons mr-lg-3" color="accent" (click)="authService.login(email, password)">Login</button>
        <!--</div>
        <div class="col-lg-6 col-12">-->
          <button  i18n="@@login-reset-pass-button" mat-button class="loginButtons" color="primary" (click)="goToResetPassword()">Forgot password?</button>
        <!--</div>-->
      </div>
      </mat-card>
    </div>
  </div>
</div>
