import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import User from 'firebase/compat';
import { FirebaseService } from '../shared/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: User.User;

  constructor(public  afAuth:  AngularFireAuth, public  router:  Router, private firebase: FirebaseService) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
        this.firebase.getEmailAdmin(this.user.email)
          .subscribe(data => {
            const resultQty = data.length;
            if (resultQty > 0) {
              localStorage.setItem('admin', 'true');
            } else {
              localStorage.setItem('admin', 'false');
            }
        });
      } else {
        localStorage.setItem('user', null);
        localStorage.setItem('admin', 'false');
      }
    });
  }

  async  login(email:  string, password:  string) {
    try {
      await (await this.afAuth.app).auth().signInWithEmailAndPassword(email, password);
      this.router.navigate(['home']);
    } catch (e) {
    alert('Error!'  +  e.message);
    }
  }

  async loginAnonymously(): Promise<boolean> {
    try {
      await (await this.afAuth.app).auth().signInAnonymously();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async  signUp(email:  string, password:  string, confirmPassword: string) {
    try {
      if (password !== confirmPassword) {
        alert('Error!' + ' Password do not match.');
        return;
      }
      await (await this.afAuth.app).auth().createUserWithEmailAndPassword(email, password);
      this.router.navigate(['home']);
    } catch (e) {
      alert('Error!'  +  e.message);
    }
  }

  get isLoggedIn(): boolean {
    const  user  =  JSON.parse(localStorage.getItem('user'));
    return  user  !==  null;
  }

  get getUser(): User.User {
    return JSON.parse(localStorage.getItem('user'));
  }

  get getIsAdmin(): boolean {
    if (localStorage.getItem('admin') === 'true') {
      return true;
    } else {
      return false;
    }

  }

  async logout() {
    await (await this.afAuth.app).auth().signOut();
    localStorage.removeItem('user');
    this.router.navigate(['login']);
  }

  async resetPassword(email: string): Promise<boolean> {
    try {
      await (await this.afAuth.app).auth().sendPasswordResetEmail(email);
      return true;
    } catch (error) {
      return false;
    }
  }

}
