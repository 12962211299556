<app-header></app-header>
<div class="main-div">
  <div *ngIf="userRole == 'Coach' && showCoachTeams && !isLoadingResults">
    <div class="container">
      <h1>
        {{ titleCoachTeams }}
      </h1>
      <div>
          <button mat-raised-button (click)="createTeam()" color="accent" i18n="@@home-add-team">Create team</button>
      </div>
    </div>
    <table
      *ngIf="coachList.length > 0"
      mat-table
      [dataSource]="dataSourceCoach"
      matSort
      (matSortChange)="sortData($event)"
      matSortActive="name"
      matSortDirection="asc"
      class="mat-elevation-z8 row"
    >
      <ng-container matColumnDef="name" class="col-6">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@home-team-list-name-col">
          Team name
        </th>
        <td mat-cell class="table-link" *matCellDef="let user">
          <a
            [routerLink]="['/team-dashboard', user.teamId]"
            class="mat-row-link"
            >{{ user.name }}</a
          >
        </td>
      </ng-container>
      <ng-container matColumnDef="year" class="col-2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@home-team-list-year-col">
          Year or age group
        </th>
        <td mat-cell *matCellDef="let user">{{ user.year }}</td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumnsCoach; sticky: true"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsCoach"></tr>
    </table>

    <mat-paginator
      *ngIf="coachList.length > 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
      color="accent"
    >
    </mat-paginator>
    <h2 *ngIf="coachList.length == 0" class="empty-list-title">
      {{ noCoachTitle }}
    </h2>
  </div>
  <div *ngIf="userRole == 'Coach' && showCoachPlayers && !isLoadingResults">
    <div class="container">
      <h1>
        {{ titleCoachPlayers }}
      </h1>
    </div>
    <div class="flex">
      <div>
        <mat-icon>search</mat-icon>
        <mat-form-field fxFlex="40%" color="secondary">
          <input
            matInput
            type="text"
            (keyup)="doFilter($event.target.value)"
            placeholder="Filter"
          />
        </mat-form-field>
        <span class="spacer"></span>
      </div>
    </div>


    <table
      *ngIf="playerList.length > 0"
      mat-table
      [dataSource]="dataSourceCoach"
      matSort
      (matSortChange)="sortData($event)"
      matSortActive="fullName"
      matSortDirection="asc"
      class="mat-elevation-z8 row"
    >
      <ng-container matColumnDef="img">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell class="align-middle" *matCellDef="let player">
          <div *ngIf="player.profilePictureUrl">
            <img [src]="player.profilePictureUrl" class="profile-pic-team-dashboard"/>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@home-player-list-player-name-col">
          Name
        </th>
        <td mat-cell class="table-link" *matCellDef="let player">
          <a [routerLink]="['/player-dashboard',player.playerId]"
            [queryParams]="{'isCoach': true}"
            class="mat-row-link">
            {{ player.fullName }}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="position" class="col-2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@home-player-list-player-position-col">
          Position
        </th>
        <td mat-cell *matCellDef="let player">{{ player.position }}</td>
      </ng-container>
      <ng-container matColumnDef="evaluation" class="col-2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@home-player-list-player-eval-col">
          Training
        </th>
        <td mat-cell *matCellDef="let player">
          <button type="button" (click)="onEvaluate(player.playerId)"
            class="btn btn-outline-dark ml-3"
            i18n="@@home-actions-player-evaluate">Log training</button>
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumnsPlayer; sticky: true"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsPlayer"></tr>
    </table>

    <mat-paginator
      *ngIf="playerList.length > 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
      color="accent"
    >
    </mat-paginator>
    <h2 *ngIf="playerList.length == 0" class="empty-list-title">
      {{ noCoachTitle }}
    </h2>
  </div>
  <div *ngIf="userRole == 'Supporter' && !isLoadingResults">
    <h1>
      {{ titleSupporter }}
    </h1>
    <table
      *ngIf="supportedsList.length > 0"
      mat-table
      [dataSource]="dataSourceSupporter"
      matSort
      (matSortChange)="sortData($event)"
      matSortActive="fullName"
      matSortDirection="asc"
      class="mat-elevation-z8 row"
    >
      <ng-container matColumnDef="fullName" class="col-6">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@home-player-list-name-col">
          Name
        </th>
        <td mat-cell class="table-link" *matCellDef="let player">
          <a
            [routerLink]="['/player-dashboard', player.playerId]"
            class="mat-row-link"
            >{{ player.fullName }}</a
          >
        </td>
      </ng-container>
      <ng-container matColumnDef="position" class="col-2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@home-player-list-position-col">
          Position
        </th>
        <td mat-cell *matCellDef="let player">{{ player.position }}</td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumnsSupporter; sticky: true"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsSupporter"></tr>
    </table>

    <mat-paginator
      *ngIf="supportedsList.length > 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
      color="accent"
    >
    </mat-paginator>
    <h2 *ngIf="supportedsList.length == 0" class="empty-list-title">
      {{ noSupporterTitle }}
    </h2>
  </div>
  <div *ngIf="userRole == 'Player' && !isLoadingResults">
    <h1>
      {{ titlePlayer }}
    </h1>
    <table
      *ngIf="playerList.length > 0"
      mat-table
      [dataSource]="dataSourcePlayer"
      matSort
      (matSortChange)="sortData($event)"
      matSortActive="fullName"
      matSortDirection="asc"
      class="mat-elevation-z8 row"
    >
      <ng-container matColumnDef="fullName" class="col-6">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@home-player-list-player-name-col">
          Name
        </th>
        <td mat-cell class="table-link" *matCellDef="let player">
          <a
            [routerLink]="['/player-dashboard', player.playerId]"
            class="mat-row-link"
            >{{ player.fullName }}</a
          >
        </td>
      </ng-container>
      <ng-container matColumnDef="position" class="col-2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@home-player-list-player-position-col">
          Position
        </th>
        <td mat-cell *matCellDef="let player">{{ player.position }}</td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumnsPlayer; sticky: true"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsPlayer"></tr>
    </table>

    <mat-paginator
      *ngIf="playerList.length > 0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
      color="accent"
    >
    </mat-paginator>
    <h2 *ngIf="playerList.length == 0" class="empty-list-title">
      {{ noPlayerTitle }}
    </h2>
  </div>
</div>
<div
  class="example-loading-shade"
  *ngIf="isLoadingResults && (coachList.length == 0 ||  playerList.length == 0 || supportedsList.length == 0)"
>
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
