import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AppAuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private firebaseService: FirebaseService) { }

  async canActivate() {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['login']);
      return false;
    } else if (this.authService.getUser.isAnonymous) {
      this.authService.logout();
      return false;
    }
    const userInfo = await this.firebaseService.getUserInfoByUserId(this.authService.getUser.uid);
    if (!userInfo) {
      this.router.navigate(['sign-up-profile']);
      return false;
    }

    return true;
  }
}
