<mat-toolbar color="accent" style="margin-bottom: 5%;">
  <span>{{ title }}</span>
  <span class="spacer"></span>
  <button
    class="btn-dialog-close"
    mat-stroked-button
    (click)="onClose()"
    tabIndex="-1"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>
<div class='main'>
  <div *ngIf="data.callingFrom == 'teamDashboard'" class="team-logo-dialog">
    <img
      [src]="data.teamProfilePictureUrl"
      class="logo-team-dashboard img-fluid"
    />
  </div>
  <form [formGroup]="teamForm" class="normal-form">
    <!-- teamGrid -->
    <mat-grid-list
      *ngIf="data.callingFrom == 'teamGrid'"
      cols="2"
      rowHeight="21rem"
    >
      <mat-grid-tile>
        <div class="controles-container column-form">
          <mat-form-field color="accent">
            <input formControlName="name" matInput placeholder="Nombre" />
            <mat-error>Este campo es obligatorio</mat-error>
          </mat-form-field>
          <mat-form-field color="accent">
            <input formControlName="year" matInput placeholder="Categoría" />
            <mat-error>Este campo es obligatorio</mat-error>
          </mat-form-field>
          <mat-form-field color="accent">
            <input
              formControlName="skillListId"
              matInput
              placeholder="Skills List Id"
            />
            <mat-error>Este campo es obligatorio</mat-error>
          </mat-form-field>
          <mat-form-field color="accent">
            <input formControlName="userId" matInput placeholder="User Id" />
            <mat-error>Este campo es obligatorio</mat-error>
          </mat-form-field>
          <mat-form-field color="accent">
            <input
              formControlName="invitationCode"
              matInput
              placeholder="Invitation Code"
            />
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="controles-container column-form">
          <mat-form-field color="accent">
            <input
              formControlName="teamColor"
              matInput
              placeholder="Team Color"
            />
          </mat-form-field>
          <mat-form-field color="accent">
            <input
              formControlName="teamLogo"
              matInput
              placeholder="Team Logo"
            />
          </mat-form-field>
          <mat-checkbox formControlName="premium" class="premium-checkbox"
            >Premium</mat-checkbox
          >
          <mat-form-field color="accent">
            <input
              formControlName="teamVideoCover"
              matInput
              placeholder="Team Video Cover"
            />
          </mat-form-field>
          <mat-form-field color="accent">
            <label class="subscription-label">Se recordará mensualidad el dia de cada mes</label>
            <input matInput type="number" placeholder="Día" formControlName="membershipMonthCutOffDay" min="1" max="31">
            <mat-error>El número debe estar entre 1 y 31</mat-error>
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <!-- header -->
    <mat-grid-list
      *ngIf="data.callingFrom == 'header'"
      cols="2"
      rowHeight="20rem"
    >
      <mat-grid-tile>
        <div class="controles-container column-form">
          <mat-form-field color="accent">
            <input formControlName="name" matInput placeholder="Nombre" />
            <mat-error>Este campo es obligatorio</mat-error>
          </mat-form-field>
          <mat-form-field color="accent">
            <input formControlName="year" matInput placeholder="Categoría" />
            <mat-error>Este campo es obligatorio</mat-error>
          </mat-form-field>
          <mat-form-field color="accent">
            <input
              formControlName="invitationCode"
              matInput
              placeholder="Invitation Code"
            />
          </mat-form-field>
          <mat-form-field color="accent">
              <label class="subscription-label">Se recordará mensualidad el dia de cada mes</label>
              <input matInput type="number" placeholder="Día" formControlName="membershipMonthCutOffDay" min="1" max="31">
              <mat-error>El número debe estar entre 1 y 31</mat-error>
            </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
          <div class="controles-container column-form">
            <div
              class="dropzoneDialog my-1 pt-4"
              appDropzone
              (hovered)="toggleHover($event)"
              (dropped)="onDrop($event)"
              [class.hovering]="isHovering"
            >
              <mat-icon font>folder</mat-icon>
              <h5>Arrastra y suelta el archivo acá</h5>
              <label for="filesInput" class="custom-file-upload">
                <i class="fa fa-cloud-upload"></i> Seleccionar archivo
              </label>
              <input
                style="visibility: hidden;"
                #filesInput
                id="filesInput"
                class="custom-file-input"
                type="file"
                (change)="cambioArchivo($event)"
                accept="image/*"
              />
            </div>
          </div>
        </mat-grid-tile>
    </mat-grid-list>

    <!-- teamDashboard -->
    <mat-grid-list
      *ngIf="data.callingFrom == 'teamDashboard'"
      cols="2"
      rowHeight="15rem"
    >
      <mat-grid-tile>
        <div class="controles-container column-form">
          <mat-form-field color="accent">
            <input formControlName="name" matInput placeholder="Nombre" />
            <mat-error>Este campo es obligatorio</mat-error>
          </mat-form-field>
          <mat-form-field color="accent">
            <input formControlName="year" matInput placeholder="Categoría" />
            <mat-error>Este campo es obligatorio</mat-error>
          </mat-form-field>
          <mat-form-field color="accent">
            <label class="subscription-label">Se recordará mensualidad el dia de cada mes:</label>
            <input matInput type="number" formControlName="membershipMonthCutOffDay" min="1" max="31">
            <mat-error>El número debe estar entre 1 y 31</mat-error>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="controles-container column-form">
          <div
            class="dropzoneDialog my-1 pt-4"
            appDropzone
            (hovered)="toggleHover($event)"
            (dropped)="onDrop($event)"
            [class.hovering]="isHovering"
          >
            <mat-icon font>folder</mat-icon>
            <h5>Arrastra y suelta el archivo acá</h5>
            <label for="filesInput" class="custom-file-upload">
              <i class="fa fa-cloud-upload"></i> Seleccionar archivo
            </label>
            <input
              style="visibility: hidden;"
              #filesInput
              id="filesInput"
              class="custom-file-input"
              type="file"
              (change)="cambioArchivo($event)"
              accept="image/*"
            />
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
  <div class="row text-center" *ngIf="PreSubmitFiles.length > 0">
    <div class="col mx-0">
      <h6>Archivo Seleccionado</h6>
    </div>
  </div>
  <div class="row text-center" *ngFor="let preSubmitFile of PreSubmitFiles">
    <div class="col mx-auto">
      <p>{{ preSubmitFile.name }}</p>
    </div>
  </div>
  <div class="row text-center">
    <div *ngFor="let file of files" class="col">
      <h6>Progreso de subida del archivo de entrenamiento</h6>
      <app-upload-task
        [file]="file"
        [playerId]="data.entityId"
        [teamId]="data.entityId"
        [uploadedBy]="data.name"
        [type]="'teamLogo'"
        (result)="finalizeSubmitFile($event)"
      ></app-upload-task>
    </div>
  </div>
</div>
<div class="button-row" mat-dialog-actions>
  <button
    mat-raised-button
    class="save-button"
    color="accent"
    type="submit"
    [disabled]="teamForm.invalid"
    (click)="onSubmit()"
  >
    Guardar
  </button>
  <!-- <button mat-raised-button color="warn" (click)="onClear()">Borrar</button> -->
</div>