<mat-toolbar color="accent">
    <span>{{ title }}</span>
    <span class="spacer"></span>
    <button
      class="btn-dialog-close"
      mat-stroked-button
      (click)="onClose()"
      tabIndex="-1"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </mat-toolbar>
  <form [formGroup]="userForm" class="normal-form">
    <mat-grid-list cols="2" rowHeight="22rem">
      <mat-grid-tile>
        <div class="controles-container column-form">
          <mat-form-field color="accent">
            <input formControlName="name" matInput placeholder="Name" />
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
          <mat-form-field color="accent">
            <input formControlName="teamId" matInput placeholder="Team ID" />
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
          <mat-form-field color="accent">
            <input formControlName="userId" matInput placeholder="User ID" />
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
          <mat-form-field color="accent">
            <input formControlName="playerId" matInput placeholder="Player ID" />
          </mat-form-field>
          <mat-form-field color="accent">
            <input formControlName="year" matInput placeholder="Year" />
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="controles-container">
          <div class="add-bottom-padding radio-group">
            <label id="role-radio-group-label" class="radio-button-label">Pick the user role:</label>
            <mat-radio-group formControlName="role" aria-labelledby="role-radio-group-label">
              <mat-radio-button class="add-right-padding" value="1"
                >Coach</mat-radio-button
              >
              <mat-radio-button class="add-right-padding" value="2"
                >Player</mat-radio-button
              >
              <mat-radio-button class="add-right-padding" value="3"
                >Supporter</mat-radio-button
              >
            </mat-radio-group>
          </div>
          <div class="add-bottom-padding radio-group">
            <label id="default-radio-group-label" class="radio-button-label">Is this the default user?</label>
            <mat-radio-group formControlName="default" aria-labelledby="default-radio-group-label">
              <mat-radio-button class="add-right-padding" value="1"
                >True</mat-radio-button
              >
              <mat-radio-button class="add-right-padding" value="2"
                >False</mat-radio-button
              >
            </mat-radio-group>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <div class="button-row" mat-dialog-actions>
      <button mat-raised-button color="warn" (click)="onClear()">Clear</button>
      <span class="spacer"></span>
      <button
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="userForm.invalid"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </div>
  </form>
  
