import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderComponent } from './components/header/header.component';


@NgModule({
    declarations: [HeaderComponent],
    imports: [CommonModule, MatIconModule, MatMenuModule,
      MatToolbarModule, MatButtonModule, MatFormFieldModule, MatSelectModule, FormsModule],
    exports: [HeaderComponent]
})
export class CommonUiModule { }
