import { Component, OnInit, LOCALE_ID, Inject, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { flatMap, take } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService } from '../auth/auth.service';
import { Evaluation } from '../models/evaluation';
import { EvaluationItem } from '../models/evaluationItem';
import { EvaluationItemResult } from '../models/evaluationItemResult';
import { Player } from '../models/player';
import { Skill } from '../models/skill';
import { SkillLanguage } from '../models/skillLanguage';
import { SkillList } from '../models/skillList';
import { Team } from '../models/team';
import { UserInfo } from '../models/userInfo';
import { FirebaseService } from '../shared/firebase.service';
import { Category } from '../models/category';
import { UserMap } from '../models/userMap';
import { NotificationService } from '../shared/notification.service';
import { AppMessage } from '../models/app-message';
import { RoleService } from '../shared/role.service';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css']
})
export class EvaluationComponent implements OnInit {

  private teamId: string;
  playerId: string;
  private date: string;
  private day: string;
  private month: string;
  private year: string;
  isLoadingResults = true;
  private player: Player;
  private userInfo: UserInfo;
  private profilePictureUrl: string;
  private age: number;
  public totalAbsences = 0;
  public dateEvaluationList: string[] = [];
  public absencesList: Date[] = [];
  private evaluationList: EvaluationItem[] = [];
  private categories: Category[] = [];
  private attendance = true;
  newMode = false;
  private role: string;
  public lang = 0;
  private evaluationMsg = '';
  private teamName = '';

  playerDashboardLink = '/player-dashboard/';

  constructor(
    private _route: ActivatedRoute,
    private firebase: FirebaseService,
    private authService: AuthService,
    private notification: NotificationService,
    private router: Router,
    private functions: AngularFireFunctions,
    @Inject(LOCALE_ID) public locale: string,
    private roleService: RoleService
  ) { }

  async ngOnInit() {
    this.setLocale();
    this._route.params.subscribe(params => {
      this.playerId = params['playerId'];
      this.playerDashboardLink += this.playerId;

      this.date = params['date'];
      this.newMode = this.date === undefined;
      if (!this.newMode && this.date.length === 8) {
        this.year = this.date.substring(0, 4);
        this.month = this.date.substring(4, 6);
        this.day = this.date.substring(6, 8);
      } else {
        const today = new Date();
        this.year = today.getFullYear().toString();
        this.month = (today.getMonth() + 1).toString();
        this.day = today.getDate().toString();
      }
    });
    const role = await this.roleService.getRole(this.authService.user.uid);
    if (role === 'Coach') {
      this.setCoachOptions();
    }
    this.initializePlayer();
  }

  initializePlayer() {
    this.isLoadingResults = true;
    this.firebase.getPlayer(this.playerId)
      .pipe(
        flatMap((playerData: Player) => {
          this.player = playerData;
          this.teamId = this.player.teamId;
          this.age = this.getAge();
          return forkJoin([
            this.firebase.getUserInfoById(this.authService.user.uid).pipe(take(1)),
            this.firebase.getProfilePicture(this.player.profilePicturePath || 'profilePictures/default.png').pipe(take(1)),
            this.firebase.getTeam(this.teamId).pipe(take(1)),
            this.firebase.getPlayerAbsences(this.playerId).pipe(take(1)),
            this.firebase.getUserMapByTeamAndUser(this.authService.user.uid, this.teamId).pipe(take(1))
          ]);
        })
      )
      .pipe(
        flatMap(([userInfoQuery, url, teamData, absences, userMap]: [any[], string, Team, Evaluation[], UserMap[]]) => {
          this.userInfo = userInfoQuery[0] as UserInfo;
          this.profilePictureUrl = url;
          this.loadAbsences(absences);
          this.role = userMap.length > 0 ? userMap[0].role : undefined;
          this.teamName = teamData.name;
          return this.firebase.getSkillListById(teamData.skillListId);
        })
      )
      .pipe(
        flatMap((skillList: SkillList) => {
          const languageList = [];
          skillList.skills.forEach((s: string) => {
           languageList.push(this.firebase.getSkillLanguages(s).pipe(take(1)));
          });
          return forkJoin([
           this.firebase.getFilteredSkills(skillList.skills).pipe(take(1)),
           this.firebase.getEvaluationByPlayer(this.teamId, this.playerId).pipe(take(1)),
           ...languageList]);
        })
      )
      .subscribe(([skillData, evalData, ...languages]: [Skill[], Evaluation[], SkillLanguage[]]) => {
        this.loadSkillSet(languages, evalData, skillData);
        this.isLoadingResults = false;
      });
  }

  loadSkillSet(languages: SkillLanguage[][], evalData: Evaluation[], skillData: Skill[]) {
    // Count evaluations
    evalData.forEach((e: Evaluation) => {
      if (!this.dateEvaluationList.includes(`${e.year}${e.month}${e.day}`)) {
        this.dateEvaluationList.push(`${e.year}${e.month}${e.day}`);
      }
    });

    // Get evaluation from current/selected date
    const filteredEvalData = evalData.filter((e: Evaluation) => {
      return (e.day === parseInt(this.day, 10) && e.month === parseInt(this.month, 10) && e.year === parseInt(this.year, 10));
    });

    // Build skill set
    languages.forEach((l: SkillLanguage[]) => {
      const currentLang = l[this.lang];
      const currentSkill = skillData.filter(s => s.skillId === currentLang.skillId)[0];
      if (currentSkill !== undefined) {
        const currentEval = filteredEvalData.filter((e: Evaluation) => e.skillCode === currentSkill.code);
        if (currentSkill.type !== 'title') {
          const newItem: EvaluationItem = {
            itemId: currentEval.length > 0 ? currentEval[0].evaluationId : undefined,
            playerId: this.playerId,
            teamId: this.teamId,
            skillCode: currentSkill.code,
            skillName: currentLang.displaySkill,
            categoryName: currentLang.displayCategory,
            type: currentSkill.type,
            skillResult: currentEval.length > 0 ? currentEval[0].skillResult : EvaluationItemResult.Average,
            statusResult: currentEval.length > 0 ? currentEval[0].statusResult : false,
            quantitativeResult: (currentEval.length > 0 && currentSkill.code.startsWith('ME-Q')) ? currentEval[0].skillResult : undefined
          };
          this.evaluationList.push(newItem);
        } else if ((currentSkill.code.startsWith('BE-')
                  || currentSkill.code.startsWith('PH-')
                  || currentSkill.code.startsWith('TA-')
                  || currentSkill.code.startsWith('TE-'))) {
          this.categories.push({
            code: currentSkill.code,
            name: currentLang.displayCategory
          });
        }
      }
    });
  }

  loadAbsences(absences: Evaluation[]) {
    if (absences != null) {
      absences.forEach(absence => {
        if (!absence.statusResult) {
          const absenceDate = new Date(
            absence.year,
            absence.month - 1,
            absence.day
          );
          this.absencesList.push(absenceDate);
          this.totalAbsences++;
          if (absence.year === parseInt(this.year, 10)
              && absence.month === parseInt(this.month, 10)
              && absence.day === parseInt(this.day, 10)) {
            this.attendance = false;
          }
        }
      });
    }
  }

  getAge(): number {
    const birthDate = new Date(
      parseInt(this.player.birthYear, 10), parseInt(this.player.birthMonth, 10) - 1, parseInt(this.player.birthDay, 10));
    const today = new Date();
    if ((birthDate.getMonth > today.getMonth) || (birthDate.getMonth === today.getMonth && birthDate.getDate() >= today.getDate())) {
      return today.getFullYear() - birthDate.getFullYear();
    } else {
      return (today.getFullYear() - birthDate.getFullYear()) - 1;
    }
  }

  getCategoryIcon(category: string) {
    switch (category) {
      case 'PH-T-BNK':
        return '../../assets/images/evaluationPhysicalOutline.svg';
      case 'BE-T-BNK':
        return '../../assets/images/evaluationMentalOutline.svg';
      case 'TE-T-BNK':
        return '../../assets/images/evaluationTechniqueOutline.svg';
      case 'TA-T-BNK':
        return '../../assets/images/evaluationTacticsOutline.svg';
      default:
        return '';
    }
  }

  getCategoryBackground(category: string) {
    switch (category) {
      case 'PH-T-BNK':
        return 'statisticIcon my-auto ml-3 physical-bg';
      case 'BE-T-BNK':
        return 'statisticIcon my-auto ml-3 behavioral-bg';
      case 'TE-T-BNK':
          return 'statisticIcon my-auto ml-3 technical-bg';
      case 'TA-T-BNK':
          return 'statisticIcon my-auto ml-3 tactical-bg';
      default:
        return '';
    }
  }

  getEvaluationByCategory(category: string) {
    return this.evaluationList.filter((e: EvaluationItem) => e.categoryName === category);
  }

  getMetrics() {
    return this.evaluationList.filter((e: EvaluationItem) => e.skillCode.startsWith('ME-Q'));
  }

  saveEvaluation() {
    this.isLoadingResults = true;
    const today = new Date();
    const attendanceSkill = this.evaluationList.filter((e: EvaluationItem) => e.skillCode.startsWith('AT-'));
    if (attendanceSkill.length === 1) {
      attendanceSkill[0].statusResult = this.attendance;
    }

    this.firebase.addEvaluation(
      this.teamId,
      this.playerId,
      this.authService.user.uid,
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate(),
      this.evaluationList
    ).then(res => {
      this.firebase.generateStatisticsCF(this.teamId, this.playerId, this.role, today);
      this.firebase.generateSkillsSummaryCF(this.teamId, this.playerId, this.role, today);
      this.isLoadingResults = false;
      this.notification.success('Evaluation saved');
      this.sendMessage();
      this.router.navigate(['/player-dashboard', this.playerId], {
        queryParams: { 'isCoach': 'true'}
      });
    })
    .catch(e => {
      this.notification.error('There was an error saving the evaluation');
      console.error('ERROR: ', e);
    })
    .finally(() => this.isLoadingResults = false);
  }

  setLocale() {
    if (this.locale.includes('en')) {
      this.lang = 0;
    }
    if (this.locale.includes('es')) {
      this.lang = 1;
    }
  }

  getEvaluationDate(day: number, month: number, year: number): string {
    const eDate = new Date(year, month - 1, day);
    const evalDate = eDate.toLocaleString(this.locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    return (evalDate ? evalDate : '');
  }

  sendMessage() {
    const body: string = this.evaluationMsg;

    if (body && body !== '') {
      const subj = 'Progress note';
      const msjDate = new Date();
      const newMessage: AppMessage = {
        userId: this.authService.user.uid,
        groupId: this.player.teamId,
        message: body,
        audience: this.player.userId ? this.player.userId : this.playerId,
        dateTime: msjDate ,
        subject: subj,
      };
      this.firebase.addMessage(newMessage);

      const  callableFunction = this.functions.httpsCallable('sendMessageNotificationToPlayer');
      const response = callableFunction({
        isUser: this.player.userId ? true : false,
        id: this.player.userId ? this.player.userId : this.playerId,
        teamName: this.teamName,
        message: newMessage.message,
        });
      this.notification.success(this.getSuccessMessage());
    }
  }

  getSuccessMessage(): string {
    return (this.locale.includes('en') ? 'Message sent' : 'Mensaje enviado');
  }

  setCoachOptions() {
    // this.isCoach = true;
    this.playerDashboardLink = '/player-dashboard/' + this.playerId + '?isCoach=true';
  }
}
