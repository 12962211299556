<app-header></app-header>
<div class="main-div">
    <div class="example-loading-shade" *ngIf="isLoading">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>
    <div class="dashboardHeader row">
        <div class="dashboardInfo col-4">
            <h1 class="dashboardTitle">Miembros del equipo</h1>
        </div>
    </div>
    <div class="memberListContainer">
        <h2>Entrenadores</h2>
        <div *ngIf="coachListFull" class="table-responsive">
            <table class="table table-borderless table-striped table-sm" *ngIf="coachList.length>0; else elseBlockCoaches" >
                <tbody>
                    <ng-container *ngFor="let coach of coachList">
                        <tr>
                            <td *ngIf="coach.userInformation.profilePictureUrl">
                                <img [src]="coach.userInformation.profilePictureUrl" class="profile-pic-user-list my-1"/>
                            </td>
                            <td>{{coach.userInformation.name}}&nbsp;{{coach.userInformation.lastName}}</td>
                            <td>{{coach.userInformation.email}}</td>
                            <td *ngIf="isAdmin">{{coach.userInformation.userId}}</td>
                        </tr>

                    </ng-container>
                </tbody>
            </table>
            <ng-template #elseBlocCoaches>
                <p>No hay entrenadores en el equipo</p>
            </ng-template>
        </div>
    </div>
    <div class="memberListContainer">
        <h2>Atletas</h2>
        <div *ngIf="playerListFull" class="table-responsive">
            <table class="table table-borderless table-striped table-sm" *ngIf="playerList.length>0; else elseBlockPlayers" >
                <tbody>
                    <ng-container *ngFor="let player of playerList">
                        <tr>
                            <td *ngIf="player.userInformation.profilePictureUrl">
                                <img [src]="player.userInformation.profilePictureUrl" class="profile-pic-user-list my-1"/>
                            </td>
                            <td>{{player.userInformation.name.trim()}}&nbsp;{{player.userInformation.lastName.trim()}}</td>
                            <td>{{player.userInformation.email}}</td>
                            <td *ngIf="isAdmin">{{player.userInformation.userId}}</td>
                        </tr>

                    </ng-container>
                </tbody>
            </table>
            <ng-template #elseBlockPlayers>
                <p>No hay usuarios atletas en el equipo</p>
            </ng-template>
        </div>
    </div>
    <div class="memberListContainer">
        <h2>Seguidores (familiares)</h2>
        <div *ngIf="supporterListFull" class="table-responsive">
            <table class="table table-borderless table-striped table-sm" *ngIf="supporterList.length>0; else elseBlockSupporters" >
                <tbody>
                    <ng-container *ngFor="let supporter of supporterList">
                        <tr>
                            <td *ngIf="supporter.userInformation.profilePictureUrl">
                                <img [src]="supporter.userInformation.profilePictureUrl" class="profile-pic-user-list my-1"/>
                            </td>
                            <td>{{supporter.userInformation.name}}&nbsp;{{supporter.userInformation.lastName}}</td>
                            <td>{{supporter.userInformation.email}}</td>
                            <td *ngIf="isAdmin">{{supporter.userInformation.userId}}</td>
                        </tr>

                    </ng-container>
                </tbody>
            </table>
            <ng-template #elseBlockSupporters>
                <p>No hay usuarios seguidores en el equipo</p>
            </ng-template>
        </div>
    </div>
    

</div>
