import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { User } from '../models/user';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RoleService {
    private defaultRole = 'Player';
    private biggerRole: string;
    constructor(private firebaseService: FirebaseService) {}

    async getRole(userId: string): Promise<string> {
        const currentRoles = [this.defaultRole];
        try {
            const teams = await this.firebaseService.getMappedTeams(userId).pipe(first()).toPromise();
            teams.forEach(team => {
                const userTeam: User = team as User;
                if (userTeam.role &&  !currentRoles.includes(userTeam.role)) {
                    currentRoles.push(userTeam.role);
                }
            });
            this.biggerRole = this.defineUserRole(currentRoles);
            return this.biggerRole;
        } catch (e) {
            console.log(e);
            return this.defaultRole;
        }
    }

    private defineUserRole(roles: string[]): string {
        if (roles.includes('Coach')) {
            return 'Coach';
        }
        if (roles.includes('Supporter')) {
            return 'Supporter';
        }
        return this.defaultRole;
    }
}
