<app-header></app-header>
<div class="main-div">
  <div class="example-loading-shade" *ngIf="isLoadingPlayerResults">
    <mat-spinner *ngIf="isLoadingPlayerResults"></mat-spinner>
  </div>
  <ng-container *ngTemplateOutlet="dashboardHeader"></ng-container>
  <ng-template #dashboardHeader>
    <div class="row dashboardHeader">
      <div
        *ngIf="teamProfilePictureUrl"
        class="my-md-auto col-md-3 mr-md-auto pb-md-auto"
      >
        <img
          [src]="teamProfilePictureUrl"
          class="logo-team-dashboard img-fluid"
        />
      </div>
      <div class="col-md-9 dashboardInfo mt-3 my-md-auto col-12">
        <div class="row">
          <div class="col-12">
            <p class="mt-3 dashboardName" i18n="@@team-dashboard-title">Team stats</p>
            <h1 class="dashboardTitle">{{ teamName }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2 mt-2">
            <span class="headerLabel" i18n="@@team-dashboard-header-year">Year: </span>
            <span class="headerValue">{{ teamYear }}</span>
          </div>
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2" *ngIf="inviteCode">
            <span class="headerLabel" i18n="@@team-dashboard-header-code">Team code: </span>
            <span class="headerValue">{{ inviteCode }}</span>
            <button mat-icon-button ngxClipboard (cbOnSuccess)="copiedCode($event)" [cbContent]="inviteCode"
            aria-label="copy athlete code to clipboard"
            i18n-aria-label="@@player-dashboard-header-code-button-aria-label">
              <mat-icon aria-hidden="true" class="sm-icon-button align-self-center">content_copy</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="actions">
    <app-dashboard-actions-panel [entityId]="teamId" [type]="'team'" [editable]="canEdit" (result)="update($event)">
    </app-dashboard-actions-panel>
  </div>
  <div class="dashboardCharts" *ngIf="showTeamStats">
    <h2 i18n="@@team-dashboard-stats-header">Stats</h2>
    <div class="d-flex flex-wrap flex-md-row justify-content-md-around">
      <div
        class="col-md-5 col-lg-4 dashboardThird my-2 py-3"
        *ngIf="validOverall"
      >
        <h3 class="lightText text-center" i18n="@@team-dashboard-stats-overall-label">Overall stats</h3>
        <app-category-dashboard
          [behavioral]="overallScoreBehavioral"
          [physical]="overallScorePhysical"
          [technical]="overallScoreTechnical"
          [tactical]="overallScoreTactical"
        ></app-category-dashboard>
      </div>
      <div
        class="col-md-5 col-lg-4 dashboardThird my-2 py-3"
        *ngIf="validEvolution"
      >
        <h3 class="lightText text-center" i18n="@@team-dashboard-stats-evolution-label">Evolution</h3>
        <app-evolution-dashboard
          [evolutionValues]="evolutionValues"
          [evolutionDates]="evolutionDates"
        ></app-evolution-dashboard>
      </div>
    </div>
    <hr />
  </div>
  <div class="dashboardSkillCategoryDetails" *ngIf="showTeamStats">
    <h2 i18n="@@team-dashboard-skills-header">Skills</h2>
    <div class="row">
      <div class="col-sm-6 col-lg-3 skillCatDetail">
        <app-skill-category-details
          [category]="'Físicas'"
          [teamId]="teamId"
          [detailType]="'team'"
        >
        </app-skill-category-details>
      </div>
      <div class="col-sm-6 col-lg-3 skillCatDetail">
        <app-skill-category-details
          [category]="'Mentalidad'"
          [teamId]="teamId"
          [detailType]="'team'"
        >
        </app-skill-category-details>
      </div>
      <div class="col-sm-6 col-lg-3 skillCatDetail">
        <app-skill-category-details
          [category]="'Táctica'"
          [teamId]="teamId"
          [detailType]="'team'"
        >
        </app-skill-category-details>
      </div>
      <div class="col-sm-6 col-lg-3">
        <app-skill-category-details
          [category]="'Técnica'"
          [teamId]="teamId"
          [detailType]="'team'"
        >
        </app-skill-category-details>
      </div>
    </div>
  </div>
  <div class="dashboardSkillCategoryDetails">
    <div class="row">
      <div class="col-auto">
        <h2 class="text-wrap" i18n="@@team-dashboard-training-header">Training drills</h2>
      </div>
      <div class="col" >
        <button type="button" (click)="onNewDrill()" class="btn btn btn-outline-dark" style="
        margin-bottom: 0.5rem;" i18n="@@team-dashboard-training-add">
          Add drill
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col skillCatDetail">
        <app-drill-category-list
        [teamId]="teamId"
        [playerId]=null
        [isTeamList]=true
        [editable]="isCoach">
        </app-drill-category-list>
      </div>
    </div>
  </div>
  <div class="dashboardList">
    <h2 i18n="@@team-dashboard-athletes-list-header">Team athletes</h2>
    <div class="example-loading-shade" *ngIf="isLoadingPlayerResults">
      <mat-spinner *ngIf="isLoadingPlayerResults"></mat-spinner>
    </div>
    <div class="table-responsive">
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" i18n="@@team-dashboard-athletes-list-name-col">Name</th>
            <th scope="col" i18n="@@team-dashboard-athletes-list-position-col" class="d-none d-md-table-cell">Position</th>
            <th scope="col" i18n="@@team-dashboard-athletes-list-birth-col" class="d-none d-md-table-cell">Birth date***</th>
            <th scope="col" i18n="@@team-dashboard-athletes-list-laterality-col" class="d-none d-md-table-cell">Laterality</th>
            <th scope="col" i18n="@@team-dashboard-athletes-list-physical-col" class="d-none d-md-table-cell">Physical</th>
            <th scope="col" i18n="@@team-dashboard-athletes-list-behavioral-col" class="d-none d-md-table-cell">Behavioral</th>
            <th scope="col" i18n="@@team-dashboard-athletes-list-tactical-col" class="d-none d-md-table-cell">Tactical</th>
            <th scope="col" i18n="@@team-dashboard-athletes-list-technical-col" class="d-none d-md-table-cell">Technical</th>
          </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let player of playerList">
                <tr>
                  <td class="align-middle">
                    <div *ngIf="player.profilePictureUrl">
                      <img [src]="player.profilePictureUrl" class="profile-pic-team-dashboard"/>
                    </div>
                  </td>
                  <td class="align-middle">
                    <a [routerLink]="['/player-dashboard',player.playerId]"
                    [queryParams]="{'isCoach': true}"
                    class="mat-row-link">
                      {{ player.firstName.trim() }} {{ player.lastName.trim() }}
                    </a>
                  </td>
                  <td class="align-middle d-none d-md-table-cell">{{ player.position }}</td>
                  <td class="align-middle d-none d-md-table-cell">{{ player.birthDay }} / {{ player.birthMonth }} /
                    {{ player.birthYear }}</td>
                  <td class="align-middle d-none d-md-table-cell">{{ player.strongFoot }}</td>
                  <td *ngIf="player.overallStatistics; else noScore" class="align-middle d-none d-md-table-cell">{{ player.overallStatistics.scorePhysical }}</td>
                  <td *ngIf="player.overallStatistics; else noScore" class="align-middle d-none d-md-table-cell">{{ player.overallStatistics.scoreBehavioral }}</td>
                  <td *ngIf="player.overallStatistics; else noScore" class="align-middle d-none d-md-table-cell">{{ player.overallStatistics.scoreTactical }}</td>
                  <td *ngIf="player.overallStatistics; else noScore" class="align-middle d-none d-md-table-cell">{{ player.overallStatistics.scoreTechnical }}</td>
                  <ng-template #noScore><td class="align-middle d-none d-md-table-cell"></td></ng-template>
                </tr>
            </ng-container>
        </tbody>
    </table>
    </div>
  </div>
</div>

