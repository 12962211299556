<app-header></app-header>
<div class="container main-div">
  <div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="!isLoadingResults" class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb actionsBg">
              <li class="breadcrumb-item">
                      <a [href]="playerDashboardLink"
                              class="mat-row-link"
                              i18n="@@uploader-breadcrumb-player-dashboard-link">
                              Player information
                      </a>
              </li>
              <li class="breadcrumb-item active"
                      aria-current="page"
                      i18n="@@uploader-breadcrumb-evaluation-label">
                      Upload
              </li>
      </ol>
  </nav>
    <div class="row mt-3">
      <h1 i18n="@@uploader-title">Upload media</h1>
    </div>
    <div class="row">
      <div
        class="dropzone col-11"
        appDropzone
        (hovered)="toggleHover($event)"
        (dropped)="onDrop($event)"
        [class.hovering]="isHovering"
      >
        <mat-icon font class="big-icon">folder</mat-icon>
        <h2 class="text-center d-none d-md-block" i18n="@@uploader-instructions-title">Drag and drop your media here or</h2>
        <label for="filesInput" class="custom-file-upload mb-3">
          <mat-icon font>cloud_upload</mat-icon>&nbsp;
          <span i18n="@@uploader-upload-button">Select media</span>
        </label>
        <input
          #filesInput
          id="filesInput"
          class="custom-file-input"
          type="file"
          (change)="cambioArchivo($event)"
          multiple
        />
      </div>
    </div>

    <h2 i18n="@@uploader-uploaded-title">Uploaded Files</h2>
    <div *ngIf="files.length===0">
      <p  i18n="@@uploader-uploaded-files-empty-label">Your selected files will appear here</p>
    </div>
    <div *ngFor="let file of files">
      <app-upload-task
        [file]="file"
        [playerId]="playerId"
        [teamId]="teamId"
        [uploadedBy]="uploadedBy"
        [type]="'media'"
      ></app-upload-task>
    </div>
    <div *ngIf="files.length>0" class="mt-2">
      <p>
        <span i18n="@@uploader-uploaded-files-completed-label">Once done you can</span>&nbsp;
        <a class="mat-row-link" [href]="playerDashboardLink" i18n="@@uploader-uploaded-files-completed-link">go back</a>
      </p>
    </div>
  </div>
</div>