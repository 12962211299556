<mat-toolbar color="accent">
  <span><h1 mat-dialog-title>Confirmar</h1></span>
  <span class="spacer"></span>
  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>
<div mat-dialog-content>
  <p>&iquest; Estás seguro de querer {{ actionName }} {{ changingObjectName }}?</p>
</div>
<div mat-dialog-actions>
  <button mat-button color="accent" (click)="onClose()">No</button>
  <button mat-button color="accent" (click)="onConfirm()">Borrar</button>
</div>
