import { Component, OnInit } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { finalize } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { Player } from "../models/player";
import { UserInfo } from "../models/userInfo";
import { FirebaseService } from "../shared/firebase.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  public firstName: string;
  public lastName: string;
  public type: string;
  public preferredPosition: string;
  public strongerSide: string;
  public userId: string;
  public email: string;
  public language: string;
  public profilePictureUrl: string;
  public profilePicturePath: string;
  public step: number;
  public code: string;
  public userInfo: UserInfo;
  public player: Player;
  public birthday?: Date;
  public disableRole = true;
  public file: any;

  public showResetPasswordResult = false;
  public resetPasswordResult = false;
  public isProfilePhotoChanged = false;

  paramDir = "profilePictures";

  constructor(
    public authService: AuthService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private firebaseService: FirebaseService,
    private storage: AngularFireStorage,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "cantera_logo_full",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/images/canteraLogo_full_black.svg"
      )
    );
    this.profilePictureUrl = "assets/images/default.png";
  }

  ngOnInit() {
    this.step = 1;
    this.authService.afAuth.authState.subscribe(async (user) => {
      this.userId = user.uid;
      this.email = user.email;
      this.language = "1";
      const userMap = await this.firebaseService.getUserMap(this.userId);
      this.userInfo = await this.firebaseService.getUserInfoByUserId(
        this.userId
      );
      if (this.userInfo.profilePicturePath) {
        this.profilePicturePath = this.userInfo.profilePicturePath;
        this.firebaseService
          .getProfilePicture(this.userInfo.profilePicturePath)
          .subscribe((url) => {
            this.profilePictureUrl = url;
          });
      }

      if (userMap.role === "Player") {
        this.type = "PL";
        this.player = await this.firebaseService.getPlayerByUserId2(
          this.userId
        );
        this.firstName = this.player.firstName;
        this.lastName = this.player.lastName;
        this.preferredPosition = this.player.position;
        this.strongerSide = this.player.strongFoot;
        this.birthday = this.getPlayerBirth(this.player);
      } else {
        if (userMap.role === "Coach") {
          this.disableRole = true;
          this.type = "CO";
        } else {
          this.type = "PA";
        }
        this.firstName = this.userInfo.name;
        this.lastName = this.userInfo.lastName;
      }
    });
  }

  get isPlayer(): boolean {
    return this.type === "PL";
  }

  get isParent(): boolean {
    return this.type === "PA";
  }

  getPlayerBirth(p: Player): Date {
    let birth: Date;
    birth = new Date(
      Number(p.birthYear),
      Number(p.birthMonth) - 1,
      Number(p.birthDay)
    );
    return birth;
  }

  setPlayerBirth() {
    if (this.birthday) {
      this.player.birthDay = this.birthday.getDate().toString();
      this.player.birthMonth = (this.birthday.getMonth() + 1).toString();
      this.player.birthYear = this.birthday.getFullYear().toString();
    }
    return true;
  }

  async saveStorage() {
    const name = Date.now();
    const filePath = `${this.paramDir}/${name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, this.file);

    task
      .snapshotChanges()
      .pipe(
        finalize(async () => {
          this.profilePictureUrl = await fileRef.getDownloadURL().toPromise();
          this.profilePicturePath = filePath;
          this.isProfilePhotoChanged = false;
        })
      )
      .subscribe(() => {});
  }

  async save() {
    try {
      await this.firebaseService.editUserInfo(
        this.userInfo.userInfoId,
        this.firstName,
        this.lastName,
        this.userInfo.language,
        this.userId,
        this.profilePicturePath,
        this.userInfo.email
      );
      if (this.isPlayer) {
        this.setPlayerBirth();
        await this.firebaseService.editPlayer(
          this.player.playerId,
          this.firstName,
          this.lastName,
          this.player.teamId,
          this.userId,
          this.preferredPosition,
          this.strongerSide,
          this.player.birthDay,
          this.player.birthMonth,
          this.player.birthYear,
          this.player.invitationCode || "",
          this.profilePicturePath
        );
      }
      this.router.navigate(["home"]);
    } catch (e) {
      alert("Error!" + e.message);
    }
  }

  async onFileChangeEvent(e: Event) {
    this.isProfilePhotoChanged = true;
    this.file = (e.target as any).files[0];
    await this.saveStorage();
  }

  async resetPassword() {
    this.showResetPasswordResult = false;
    await this.authService
      .resetPassword(this.email)
      .then((result) => {
        if (result) {
          this.showResetPasswordResult = true;
          this.resetPasswordResult = true;
        } else {
          this.showResetPasswordResult = true;
          this.resetPasswordResult = false;
        }
      })
      .catch((e) => {
        this.showResetPasswordResult = true;
        this.resetPasswordResult = false;
      });
  }
}
