import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-skill-list-dialog-form',
  templateUrl: './skill-list-dialog-form.component.html',
  styleUrls: ['./skill-list-dialog-form.component.css']
})
export class SkillListDialogFormComponent {

  public title: string;

  public skillListForm = new FormGroup({
    action: new FormControl('new'),
    name: new FormControl('', Validators.required)
  });

  constructor(private notification: NotificationService,
    public dialogRef: MatDialogRef<SkillListDialogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if (this.data) {
        this.title = 'Edit Skill List';
        this.populateForm(this.data);
      } else {
        this.title = 'New Skill List';
      }
    }

  initializeFormGroup() {
    this.skillListForm.setValue({
      action: 'new',
      name: ''
    });
  }

  onClear() {
    this.skillListForm.reset();
    this.initializeFormGroup();
    this.notification.success('Cleared successfully');
  }

  onClose() {
    this.skillListForm.reset();
    this.initializeFormGroup();
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.skillListForm.value);
  }

  populateForm(skillList) {
    this.initializeFormGroup();
    this.skillListForm.setValue({
      action: 'edit',
      name: skillList.name
    });
  }

}
