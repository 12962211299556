import { Component, OnInit, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import {
  MatDialog
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { OverallStatistics } from '../models/overallStatistics';
import { Player } from '../models/player';
import { Team } from '../models/team';
import { FirebaseService } from '../shared/firebase.service';
import { NotificationService } from '../shared/notification.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-team-dashboard',
  templateUrl: './team-dashboard.component.html',
  styleUrls: ['./team-dashboard.component.css']
})
export class TeamDashboardComponent implements OnInit {
  // @Input() user: User;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public title = 'Estadísticas del equipo';
  public teamName: string;
  public teamYear: string;
  public inviteCode: string;
  public teamProfilePictureUrl: string;
  public canEdit = true;
  private team: Team;
  public teamId: string;
  private teamList: Team[];

  private teamNotFound = 'Error: Equipo no encontrado';
  private copiedCodeSuccess = 'Código copiado';

  isLoadingPlayerResults = true;
  private displayedColumns: string[] = [
    'profilePicture',
    // 'select',
    'fullName',
    // , 'lastName'
    'position',
    'birth',
    'strongFoot',
    'physical',
    'behavioral',
    'tactical',
    'technical'
  ];
  /* private dataSource = new MatTableDataSource();
  private initialSelection = [];
  private allowMultiSelect = true;
  private selection = new SelectionModel<any>(
    this.allowMultiSelect,
    this.initialSelection
  ); */
  public playerList: Player[];
  public isCoach = true;

  private overallStats: OverallStatistics;
  private strengthScoreBehavioral: number;
  private strengthScorePhysical: number;
  private strengthScoreTactical: number;
  private strengthScoreTechnical: number;
  public validStrengths = false;

  private overallScoreBehavioral: number;
  private overallScorePhysical: number;
  private overallScoreTactical: number;
  private overallScoreTechnical: number;
  public validOverall = false;

  private improvementScoreBehavioral: number;
  private improvementScorePhysical: number;
  private improvementScoreTactical: number;
  private improvementScoreTechnical: number;
  public validImprovements: boolean;

  public validEvolution: boolean;
  private evolutionStats: OverallStatistics[];
  private evolutionValues: number[];
  private evolutionDates: string[];

  public showTeamStats = true;

  constructor(
    private _route: ActivatedRoute,
    private firebase: FirebaseService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private notification: NotificationService,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit() {
    this.showTeamStats = environment.teamDashboard.showStats;
    this.setLocalizedMessages();
    this._route.params.subscribe(params => {
      this.teamId = params['teamId'];
    });

    // obtener datos del equipo
    this.firebase.getTeam(this.teamId).subscribe(data => {
      this.team = data;
      if (this.team != null) {
        this.teamName = this.team.name;
        this.teamYear = this.team.year.toString();
        this.inviteCode = this.team.invitationCode;
        this.firebase
            .getTeamProfilePicture(this.team.teamLogo)
            .subscribe(url => {
              this.teamProfilePictureUrl = url;
        });
      } else {
        this.teamName = this.teamNotFound;
        this.teamYear = '';
      }
    });

    this.firebase.getTeamPlayers(this.teamId).subscribe(data => {
      this.isLoadingPlayerResults = true;
      this.playerList = [];

      data.forEach(p => {
        this.playerList.push(p as Player);
      });

      this.playerList.forEach(player => {
        if (player.profilePicturePath) {
          this.firebase
            .getProfilePicture(player.profilePicturePath)
            .subscribe(url => {
              player.profilePictureUrl = url;
            });
        } else {
          this.firebase
            .getProfilePicture('profilePictures/default.png')
            .subscribe(url => {
              player.profilePictureUrl = url;
            });
        }

        this.firebase
          .getPlayerOverallStatistics(player.playerId)
          .subscribe(stats => {
            const overallPlayerStats = stats[0];
            if (overallPlayerStats != null) {
              player.overallStatistics = overallPlayerStats;
            }
          });
      });

      if (this.playerList.length > 0) {
        this.playerList.sort((a, b) => (
          (this.getPlayerFullName(a).toLowerCase() > this.getPlayerFullName(b).toLowerCase()) ? 1 : -1));
      }

      /* this.dataSource = new MatTableDataSource(this.playerList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.selection = new SelectionModel<Player>(
        this.allowMultiSelect,
        this.initialSelection
      ); */
    });

    this.firebase.getTeamOverallStatistics(this.teamId).subscribe(data => {
      this.isLoadingPlayerResults = true;

      this.overallStats = data[0];
      if (this.overallStats != null) {
        this.strengthScoreBehavioral = this.overallStats.strengthScoreBehavioral;
        this.strengthScorePhysical = this.overallStats.strengthScorePhysical;
        this.strengthScoreTactical = this.overallStats.strengthScoreTactical;
        this.strengthScoreTechnical = this.overallStats.strengthScoreTechnical;

        this.improvementScoreBehavioral = this.overallStats.improvementScoreBehavioral;
        this.improvementScorePhysical = this.overallStats.improvementScorePhysical;
        this.improvementScoreTactical = this.overallStats.improvementScoreTactical;
        this.improvementScoreTechnical = this.overallStats.improvementScoreTechnical;

        this.overallScoreBehavioral = this.overallStats.scoreBehavioral;
        this.overallScorePhysical = this.overallStats.scorePhysical;
        this.overallScoreTactical = this.overallStats.scoreTactical;
        this.overallScoreTechnical = this.overallStats.scoreTechnical;
      }
      this.validateNullStatsData();
      this.isLoadingPlayerResults = false;
    });

    // calcular evolucion
    this.firebase.getTeamDailyStatistics(this.teamId).subscribe(data => {
      this.isLoadingPlayerResults = true;

      if (data != null) {
        this.evolutionStats = data;

        // ordenar por fecha
        this.evolutionStats.sort(this.compareDates);

        // enviar los valores
        // enviar las fechas
        this.evolutionValues = [];
        this.evolutionDates = [];
        this.evolutionStats.forEach(stat => {
          this.evolutionValues.push(stat.overallScore);
          this.evolutionDates.push(
            stat.day + '/' + stat.month + '/' + stat.year
          );
        });
      }

      this.validateNullEvolution();
      this.isLoadingPlayerResults = false;
    });
  }

  validateNullStatsData() {
    if (
      this.strengthScoreBehavioral != null ||
      this.strengthScorePhysical != null ||
      this.strengthScoreTactical != null ||
      this.strengthScoreTechnical != null
    ) {
      this.validStrengths = true;
    }

    if (
      this.improvementScoreBehavioral != null ||
      this.improvementScorePhysical != null ||
      this.improvementScoreTactical != null ||
      this.improvementScoreTactical != null
    ) {
      this.validImprovements = true;
    }

    if (
      this.overallScoreBehavioral != null ||
      this.overallScorePhysical != null ||
      this.overallScoreTactical != null ||
      this.overallScoreTechnical != null
    ) {
      this.validOverall = true;
    }
  }

  validateNullEvolution() {
    if (this.evolutionStats != null) {
      this.validEvolution = true;
    }
  }

  compareDates(a: OverallStatistics, b: OverallStatistics) {
    const dateA = new Date(a.year, a.month - 1, a.day);
    const dateB = new Date(b.year, b.month - 1, b.day);
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  onNewDrill(): void {
    this.router.navigate(['/create-drill-admins'], {
      queryParams: {
        type: 'team',
        id: this.teamId,
        name: this.teamName
      }
    });
    /*
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    // dialogConfig.height = '600px';
    dialogConfig.data = {
      type: 'team',
      id: this.teamId,
      name: this.teamName
    };
    const dialogRef = this.dialog.open(CreateDrillAdminsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.type === 'link') {
          this.firebase.addPlayerDrill(
            result.id,
            result.linkDrillName,
            result.linkDrillCategory,
            result.linkDrillLink,
            result.lang,
            result.active
          );
          this.notification.success('Entrenamiento agregado');
        }
        if (result.type === 'file') {
        }
      }
    }); */
  }

  update(result) {
    this.firebase.getTeam(this.teamId).subscribe(data => {
      this.team = data;
      if (this.team != null) {
        this.teamName = this.team.name;
        this.teamYear = this.team.year.toString();
        this.firebase
            .getTeamProfilePicture(this.team.teamLogo)
            .subscribe(url => {
              this.teamProfilePictureUrl = url;
        });
      } else {
        this.teamName = this.teamNotFound;
        this.teamYear = '';
      }
    });
  }

  copiedCode(e: Event) {
    this.notification.success(this.copiedCodeSuccess);
  }

  setLocalizedMessages() {
    if (this.locale.includes('en')) {
      this.title = 'Team stats';
      this.teamNotFound = 'Team not found';
      this.copiedCodeSuccess = 'Code copied to clipboard!';
    }
  }

  getPlayerFullName(a: Player): string {
    return a.firstName.trim() + ' ' + a.lastName.trim();
  }
}
