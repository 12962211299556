import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Evaluation } from '../models/evaluation';
import { EvaluationItem } from '../models/evaluationItem';
import { EvaluationItemResult } from '../models/evaluationItemResult';
import { Player } from '../models/player';
import { Skill } from '../models/skill';
import { SkillLanguage } from '../models/skillLanguage';
import { UserInfo } from '../models/userInfo';
import { UserMap } from '../models/userMap';
import { FirebaseService } from '../shared/firebase.service';
import { Category } from '../models/category';
import { flatMap, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ChartOptions, ChartType, ChartDataSets, ChartPluginsOptions } from 'chart.js';
import { Label, Color } from 'ng2-charts';


@Component({
  selector: 'app-skill-score-detail',
  templateUrl: './skill-score-detail.component.html',
  styleUrls: ['./skill-score-detail.component.css']
})
export class SkillScoreDetailComponent implements OnInit {

  playerId: string;
  skillCode: string;
  skillCategory: string;
  isCoach = false;
  isLoadingResults = true;
  validParams = false;
  lang = 0;
  player: Player;
  skillScores: Evaluation[];
  totalEvaluations = 0;
  totalPossiblePoints = 0;
  totalActualPoints = 0;
  skillScoredTimes = 0;
  skillPercentage = 0;
  playerName = '';
  selectedSkill: Skill;
  displayedSkillName = '';
  displayedSkillCategory = '';

  private maxPositiveScore = 2;
  private maxNegativeScore = -2;

  // chart variables
  chartData: number[] = [];
  public lineChartData: ChartDataSets[] = [
    { data: [],
      label: '',
      fill: false,
      lineTension: 0,
    },
  ];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales : {
      xAxes: [{
        ticks: {
          fontFamily: '\'Roboto\', sans-serif',
        },
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        ticks: {
          min: -2,
          max: 2,
          stepSize: 1,
          display: true,
          beginAtZero: false,
          fontFamily: '\'Roboto\', sans-serif'
        },
        gridLines: {
          display: true,
        }
      }]
    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
    },
  ];
  public lineChartLegend = false;
  public lineChartType = 'line';
  public lineChartPlugins: ChartPluginsOptions = {};

  constructor(
    private _route: ActivatedRoute,
    private firebase: FirebaseService,
    private authService: AuthService,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnInit() {
    this.setLocale();
    this._route.params.subscribe(params => {
      this.playerId = params['playerId'];
      this.skillCode = params['skillId'];
    });
    this._route.queryParams.subscribe(queryParams => {
      if (queryParams['isCoach'] === 'true') {
        this.isCoach = true;
      } else {
        this.isCoach = false;
      }
    });
    this.validateParams();
    if (this.validParams) {
      // console.log(this.playerId);
      // console.log(this.skillCode);
      console.log(this.isCoach);
      this.initializePlayer();
    }
  }

  setLocale() {
    if (this.locale.includes('en')) {
      this.lang = 0;
    }
    if (this.locale.includes('es')) {
      this.lang = 1;
    }
  }

  validateParams() {
    if (this.playerId &&
    this.skillCode &&
    this.playerId !== '' &&
    this.skillCode !== '') {
      this.validParams = true;
      this.skillCategory = this.skillCode.slice(0, 2);
    }
  }

  initializePlayer() {
    this.isLoadingResults = true;
    this.firebase.getPlayer(this.playerId)
      .pipe(
        flatMap((playerData: Player) => {
          this.player = playerData;
          this.playerName = this.player.firstName + ' ' + this.player.lastName;
          // console.log(this.player);
          return forkJoin([
            this.firebase.getPlayerEvaluationsBySkillCode(this.playerId, this.skillCode).pipe(take(1)),
            this.firebase.getSkillsByCode(this.skillCode).pipe(take(1))
          ]);
        })
      ).pipe(
        flatMap(([evaluations, skillInfo]: [Evaluation[], Skill[]]) => {
        // this.loadAbsences(evaluations);
          this.loadScoreData(evaluations);
          this.selectedSkill = skillInfo[0];
          // console.log(this.selectedSkill);
          return this.firebase.getSkillLanguages(this.selectedSkill.skillId).pipe(take(1));
        })
      ).subscribe((languages: SkillLanguage[]) => {

        this.loadSkillData(languages);
        this.isLoadingResults = false;
      });
  }

  loadScoreData(scores: Evaluation[]) {
    // console.log(scores);
    let average = 0;
    let newAvg = 0;
    this.skillScores = scores;
    this.totalEvaluations = this.skillScores.length;
    this.totalPossiblePoints = this.totalEvaluations * 2;

    this.skillScores.forEach(s => {
      this.totalActualPoints += s.skillResult;
      if (s.skillResult !== 0) {
        this.skillScoredTimes++;
      }
    });
    if (this.totalEvaluations > 0) {
        average = this.totalActualPoints / this.skillScoredTimes;
        newAvg = ( (average - this.maxNegativeScore) / (this.maxPositiveScore - this.maxNegativeScore) ) * (4 - 0) + 0;
        this.skillPercentage = (newAvg / 4);
    }

    // prepare the chart
    this.chartData = [];
    this.lineChartLabels = [];
    this.skillScores.sort(this.compareDates);
    this.skillScores.forEach(e => {
      this.chartData.push(e.skillResult);
      this.lineChartLabels.push(this.getEvaluationShortDate(e));
    });
    this.lineChartData[0].data = this.chartData;
    this.lineChartData[0].label = this.getChartDataLabel();
  }

  loadSkillData(skLang: SkillLanguage[]) {
    if (this.lang === 0) {
      const l = skLang.filter(lang => lang.langId === 'en');
      this.selectedSkill.enDisplayCategory = l[0].displayCategory;
      this.selectedSkill.enDisplaySkill = l[0].displaySkill;
      this.displayedSkillName = this.selectedSkill.enDisplaySkill;
      this.displayedSkillCategory = this.selectedSkill.enDisplayCategory;
    } else {
      const l = skLang.filter(lang => lang.langId === 'es');
      this.selectedSkill.esDisplayCategory = l[0].displayCategory;
      this.selectedSkill.esDisplaySkill = l[0].displaySkill;
      this.displayedSkillName = this.selectedSkill.esDisplaySkill;
      this.displayedSkillCategory = this.selectedSkill.esDisplayCategory;
    }
    // console.log('selectedSkill', this.selectedSkill);
  }

  compareDates(a: any, b: any) {
    const dateA = new Date(a.year, a.month - 1, a.day);
    const dateB = new Date(b.year, b.month - 1, b.day);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  getEvaluationShortDate(evaluation: Evaluation): string {
    const eDate = new Date(evaluation.year, evaluation.month - 1, evaluation.day);
    const evalDate = eDate.toLocaleString(this.locale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    return (evalDate ? evalDate : '');
  }

  getEvaluationDate(evaluation: Evaluation): string {
    const eDate = new Date(evaluation.year, evaluation.month - 1, evaluation.day);
    const evalDate = eDate.toLocaleString(this.locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    return (evalDate ? evalDate : '');
  }

  getChartDataLabel(): string {
    return (this.lang === 0) ? 'Score' : 'Puntaje';
  }

  getPlayerDashboardLink(): string {
    let playerLink = `/player-dashboard/${this.playerId}`;
    playerLink += this.isCoach ? '?isCoach=true' : '';
    return playerLink;
  }

  getCategoryIcon() {
    switch (this.skillCategory) {
      case 'PH':
        return '../../assets/images/evaluationPhysicalOutline.svg';
      case 'BE':
        return '../../assets/images/evaluationMentalOutline.svg';
      case 'TE':
        return '../../assets/images/evaluationTechniqueOutline.svg';
      case 'TA':
        return '../../assets/images/evaluationTacticsOutline.svg';
      default:
        return '';
    }
  }

  getCategoryBackground() {
    switch (this.skillCategory) {
      case 'PH':
        return 'statisticIcon my-auto physical-bg';
      case 'BE':
        return 'statisticIcon my-auto behavioral-bg';
      case 'TE':
          return 'statisticIcon my-auto technical-bg';
      case 'TA':
          return 'statisticIcon my-auto tactical-bg';
      default:
        return '';
    }
  }

}
