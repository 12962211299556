import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { ChartsModule } from 'ng2-charts';
import { MetricsListComponent } from './metrics-list/metrics-list.component';
import bootstrap from 'bootstrap';
import { MetricDetailComponent } from './metric-detail/metric-detail.component';


@NgModule({
  declarations: [MetricsListComponent, MetricDetailComponent],
  imports: [
    CommonModule,
    MatCardModule,
    ChartsModule,
  ],
  exports: [
    MetricsListComponent,
    MetricDetailComponent
  ]
})
export class ViewMetricsModule { }
