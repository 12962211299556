import { Component, Input, OnInit, LOCALE_ID, Inject  } from '@angular/core';
import { Evaluation } from '../models/evaluation';
import { OverallStatistics } from '../models/overallStatistics';
import { Skill } from '../models/skill';
import { FirebaseService } from '../shared/firebase.service';

interface SkillStat {
  skillName: string;
  skillId: string;
  score: number;
  avg: number;
  evalCount: number; // times the skill has been evaluated (result != 0)
  avgPct: number;
  normalizedAvg: number;
  normalizedPct: number;
}

@Component({
  selector: 'app-skill-category-details',
  templateUrl: './skill-category-details.component.html',
  styleUrls: ['./skill-category-details.component.css']
})
export class SkillCategoryDetailsComponent implements OnInit {
  @Input() public category: string;
  @Input() private playerId: string;
  @Input() private detailType: string;
  @Input() private teamId: string;
  @Input() private isCoach = false;

  private categoryIcon: string;
  private skillCategory: string;

  public validEvolution = false;
  private evolutionStats: OverallStatistics[];
  private evolutionValues: number[];
  private evolutionDates: string[];

  private skillList: Skill[];

  private evaluationsList: Evaluation[] = [];

  public skillStatsFull = false;
  private skillStats: SkillStat[];
  public isLoadingTeamResults: boolean;

  private maxPositiveScore = 2;
  private maxNegativeScore = -2;

  constructor(private firebase: FirebaseService,
    @Inject(LOCALE_ID) public locale: string) {}

  ngOnInit() {
    this.isLoadingTeamResults = true;
    switch (this.category) {
      case 'Físicas':
        this.categoryIcon = '../assets/images/evaluationPhysicalOutline.svg';
        this.skillCategory = 'Physical';
        break;
      case 'Mentalidad':
        this.categoryIcon = '../assets/images/evaluationMentalOutline.svg';
        this.skillCategory = 'Behavioral';
        break;
      case 'Táctica':
        this.categoryIcon = '../assets/images/evaluationTacticsOutline.svg';
        this.skillCategory = 'Tactical';
        break;
      case 'Técnica':
        this.categoryIcon = '../assets/images/evaluationTechniqueOutline.svg';
        this.skillCategory = 'Technical';
        break;
    }

    if (this.detailType === 'player') {
      this.firebase.getPlayerDailyStatistics(this.playerId).subscribe(data => {
        if (data != null) {
          this.evolutionStats = data;
          // sort by dates
          this.evolutionStats.sort(this.compareDates);
          // enviar los valores
          // enviar las fechas
          this.evolutionValues = [];
          this.evolutionDates = [];
          this.evolutionStats.forEach(stat => {
            switch (this.category) {
              case 'Físicas':
                this.evolutionValues.push(stat.scorePhysical);
                this.evolutionDates.push(
                  stat.day + '/' + stat.month + '/' + stat.year
                );
                break;
              case 'Mentalidad':
                this.evolutionValues.push(stat.scoreBehavioral);
                this.evolutionDates.push(
                  stat.day + '/' + stat.month + '/' + stat.year
                );
                break;
              case 'Táctica':
                this.evolutionValues.push(stat.scoreTactical);
                this.evolutionDates.push(
                  stat.day + '/' + stat.month + '/' + stat.year
                );
                break;
              case 'Técnica':
                this.evolutionValues.push(stat.scoreTechnical);
                this.evolutionDates.push(
                  stat.day + '/' + stat.month + '/' + stat.year
                );
                break;
            }
          });
        }
        this.validateNullEvolution();

      });
    } else {
      this.firebase.getTeamDailyStatistics(this.teamId).subscribe(data => {
        if (data != null) {
          this.evolutionStats = data;
          // sort by dates
          this.evolutionStats.sort(this.compareDates);
          // enviar los valores
          // enviar las fechas
          this.evolutionValues = [];
          this.evolutionDates = [];
          this.evolutionStats.forEach(stat => {
            switch (this.category) {
              case 'Físicas':
                this.evolutionValues.push(stat.scorePhysical);
                this.evolutionDates.push(
                  stat.day + '/' + stat.month + '/' + stat.year
                );
                break;
              case 'Mentalidad':
                this.evolutionValues.push(stat.scoreBehavioral);
                this.evolutionDates.push(
                  stat.day + '/' + stat.month + '/' + stat.year
                );
                break;
              case 'Táctica':
                this.evolutionValues.push(stat.scoreTactical);
                this.evolutionDates.push(
                  stat.day + '/' + stat.month + '/' + stat.year
                );
                break;
              case 'Técnica':
                this.evolutionValues.push(stat.scoreTechnical);
                this.evolutionDates.push(
                  stat.day + '/' + stat.month + '/' + stat.year
                );
                break;
            }
          });
        }
        this.validateNullEvolution();
      });
    }

    this.firebase
      .getSkillsByCategory(this.skillCategory)
      .subscribe(skillData => {
        this.skillList = [];
        skillData.forEach(s => {
          this.skillList.push(s as Skill);
        });
        this.skillList.forEach(sk => {
          this.firebase.getSkillLanguages(sk.skillId).subscribe(lang => {
            lang.forEach(l => {
              if (l.langId === 'en') {
                sk.enDisplayCategory = l.displayCategory;
                sk.enDisplaySkill = l.displaySkill;
              } else {
                sk.esDisplayCategory = l.displayCategory;
                sk.esDisplaySkill = l.displaySkill;
              }
            });
          });
          if (this.detailType === 'player') {
            this.firebase
              .getPlayerEvaluationsBySkillCode(this.playerId, sk.code)
              .subscribe(evaluation => {
                evaluation.forEach(e => {
                  this.evaluationsList.push(e as Evaluation);
                });
                this.setSkillStats();
                this.isLoadingTeamResults = false;
              });
          } else {
            this.firebase
              .getTeamEvaluationsBySkillCode(this.teamId, sk.code)
              .subscribe(evaluation => {
                evaluation.forEach(e => {
                  this.evaluationsList.push(e as Evaluation);
                });
                this.setSkillStats();
                this.isLoadingTeamResults = false;
              });
          }
        });
      });
  }

  validateNullEvolution() {
    if (this.evolutionStats != null) {
      this.validEvolution = true;
    }
  }

  compareDates(a: OverallStatistics, b: OverallStatistics) {
    const dateA = new Date(a.year, a.month - 1, a.day);
    const dateB = new Date(b.year, b.month - 1, b.day);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  async setSkillStats() {
    this.skillStats = [];
    await this.asyncForEach(this.skillList, async (sk: Skill) => {
      let score = 0;
      let skillEvalCount = 0;
      let average = 0;
      let avgPercentage = 0;
      let newAvg = 0;
      let normPct = 0;
      await this.asyncForEach(this.evaluationsList, (e: Evaluation) => {
        if (sk.code === e.skillCode && e.skillResult !== 0) {
          score = score + e.skillResult;
          skillEvalCount++;
        }
      });
      const skDisplayName = (this.locale.includes('es')) ? sk.esDisplaySkill : sk.enDisplaySkill;
      if (skillEvalCount > 0) {
        average = score / skillEvalCount;
        newAvg = ( (average - this.maxNegativeScore) / (this.maxPositiveScore - this.maxNegativeScore) ) * (4 - 0) + 0;
        avgPercentage = (average >= 0) ? (average / this.maxPositiveScore) : ((average / this.maxNegativeScore) * -1);
        normPct = (newAvg / 4);
      }

      const skillStat: SkillStat = await {
        skillName: skDisplayName,
        skillId: sk.code,
        score: score,
        evalCount: skillEvalCount,
        avg: average,
        avgPct: avgPercentage,
        normalizedAvg: newAvg,
        normalizedPct: normPct
      };
      if (skillStat.skillName) {
        this.skillStats.push(skillStat as SkillStat);
      }
    });
    if (this.skillList.length - 1 === this.skillStats.length) {
      this.skillStats.sort((a, b) => (
        (a.evalCount < b.evalCount) ||
        (a.evalCount === b.evalCount && a.normalizedAvg < b.normalizedAvg) ? 1 : -1));
      this.skillStatsFull = true;
    }
  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  getCategoryLocale(cat: string): string {
    if (this.locale.includes('es')) {
      return cat;
    }

    switch (cat) {
      case 'Físicas':
        return 'Physical';
        break;
      case 'Mentalidad':
        return 'Behavioral';
        break;
      case 'Táctica':
        return 'Tactical';
        break;
      case 'Técnica':
        return 'Technical';
        break;
    }

  }

  getDetailDashboardLink(skCode: string): string {
    let link = `/skill-history/${this.playerId}/${skCode}`;
    link += this.isCoach ? '?isCoach=true' : '';
    return link;
  }

}
