<!-- <nav class="navbar navbar-dark bg-dark">
    <a class="navbar-brand" href="#">
        <img src="../../assets/images/Cantera_logo_white.svg" height="32" width="auto" class="d-inline-block align-middle logo pr-2" alt="Cantera" loading="lazy">
    entrenamiento en casa
    </a>
</nav> -->
<div class="example-loading-shade center" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
    <div class="teamBar px-3 py-2 mb-3" [style.background-color]="teamColor"
        *ngIf="validDrill && !isLoadingResults"> <!-- team style -->
        <img [src]="teamLogo" class="teamLogo rounded-circle mr-2"/> <!-- team logo -->
        {{teamName}}
    </div>
    <div *ngIf="!isLoadingResults" class="container">
        <div class="main-div" *ngIf="validDrill && !isLoadingResults; else elseBlock">
            <div class="row my-3"> <!-- training title -->
                <h1 class="font-weight-normal ml-3">{{drill.drillName}}</h1>
            </div>
            <div class="row"> <!-- drill media -->
                <div class="col text-center" *ngIf="isLink">
                    <!-- show link to resource -->
                    <a class="py-3 px-5 linkRectangle d-inline-block align-middle rounded"
                    [href]="drill.drillMediaURL" [hreflang]="drill.linkLanguage"
                    i18n="@@drill-go-to">
                        Go to media
                    </a>
                </div>
                <div class="col text-center" *ngIf="isVideo">
                    <!-- show link to resource -->
                    <div style="position:relative;padding-top:56.25%;" *ngIf="isMp4">
                        <video style="position:absolute;top:0;left:0;width:100%;height:100%;" controls
                        [poster]="teamVideoCover" >
                        <source [src]="drill.drillMediaURL" type="video/mp4">
                        <span i18n="@@drill-video-not-supported">Video not supported.</span>
                        </video>
                    </div>
                    <div style="position:relative;padding-top:56.25%;" *ngIf="isMov">
                        <video style="position:absolute;top:0;left:0;width:100%;height:100%;" controls
                        [poster]="teamVideoCover" [src]="drill.drillMediaURL">
                        <span i18n="@@drill-video-not-supported">Video not supported.</span>
                        </video>
                    </div>
                </div>
                <div class="col text-center" *ngIf="isImg">
                    <!-- embed image -->
                    <img class="img-fluid" [src]="drill.drillMediaURL" />
                </div>
            </div>
            <h2 class="h3 mt-4 mb-2" i18n="@@drill-coach-comments-heading">Coach's comments:</h2>
            <div class="row ml-1"><!-- drill text -->
                <p class="comments">{{drill.drillText}}</p>
            </div>
        </div>
        <ng-template #elseBlock>
            <div class="container center main-div">
                <div class="row text-center">
                    <div class="col" *ngIf="!validDrill" i18n="@@drill-drill-not-found">
                        Drill not found
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
