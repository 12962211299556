<div *ngIf="!canceled">
  <p class="h6">{{ file.name }}</p>
  <div *ngIf="percentage | async as pct">
    <progress [value]="pct" max="100"></progress>
    {{ pct | number }}%
  </div>
  <div *ngIf="snapshot | async as snap">
    <button (click)="task.pause()" [disabled]="!isActive(snap)" i18n="@@upload-task-pause-button">Pause</button>
    <!--<button (click)="task.cancel()" [disabled]="!isActive(snap)">
      Cancelar
    </button> -->
    <button class="ml-2" (click)="task.resume()" [disabled]="!(snap?.state === 'paused')" i18n="@@upload-task-resunme-button">
      Resume
    </button>
  </div>
</div>
