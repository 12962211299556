import { Component, OnInit, Input, LOCALE_ID, Inject } from '@angular/core';
import { FirebaseService } from '../../shared/firebase.service';
import { AppMessage } from 'src/app/models/app-message';
import { flatMap, switchMap, take } from 'rxjs/operators';
import { UserInfo } from 'src/app/models/userInfo';
import { forkJoin, from } from 'rxjs';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-view-messages',
  templateUrl: './view-messages.component.html',
  styleUrls: ['./view-messages.component.css']
})
export class ViewMessagesComponent implements OnInit {
  @Input() audience: string;
  @Input() groupId?: string;
  @Input() type: string;

  isTeam: boolean;
  isLoadingResults = false;
  receivedMessages: AppMessage[] = [];
  receivedMessagesWithAuthors: AppMessage[] = [];
  receivedMessagesAuthors: string[] = [];
  authorImgs: string[] = [];
  constructor(private firebase: FirebaseService, @Inject(LOCALE_ID) public locale: string) { }

  ngOnInit() {
    (this.type === 'team') ? this.isTeam = true : this.isTeam = false;

    if (this.isTeam) {
      console.log('addTeam messages logic');
    } else { // messages for players
      this.firebase.getMessagesForPlayer(this.audience)
      .pipe(take(1))
      .subscribe((msgs: AppMessage[]) => {
        this.isLoadingResults = true;
        if (msgs != null) {
          msgs.forEach(msg => {
            // console.log(msg.dateTime);
            if (msg.message && msg.dateTime) {
              this.firebase.getUserInfoById(msg.userId)
              .pipe(take(1))
              .subscribe((info: any[]) => {
                const completeMsg = msg;
                const author = info[0] as UserInfo;
                // console.log(author);
                // completeMsg.dateTime = new Date(msg.dateTime.valueOf());
                completeMsg.authorInfo = author;
                if (!author.profilePictureUrl) {
                  this.firebase.getProfilePicture(author.profilePicturePath || 'profilePictures/default.png')
                  .pipe(take(1))
                  .subscribe((pic: string) => {
                    completeMsg.authorInfo.profilePictureUrl = pic;
                    this.receivedMessagesWithAuthors.push(completeMsg);

                    this.receivedMessagesWithAuthors.sort((a, b) => {
                      return a.dateTime > b.dateTime ? -1 : a.dateTime < b.dateTime ? 1 : 0;
                    });
                  });
                } else {
                  this.receivedMessagesWithAuthors.push(completeMsg);
                  this.receivedMessagesWithAuthors.sort((a, b) => {
                    return a.dateTime > b.dateTime ? -1 : a.dateTime < b.dateTime ? 1 : 0;
                  });
                }
              });
            }
          });
        }

        // const completeMessages$ = from(this.receivedMessagesWithAuthors);
        // completeMessages$.subscribe(())

        /* the following is not executed beacause of concurrency issues*/
        /* this.receivedMessagesWithAuthors.sort((a, b) => {
          return a.dateTime > b.dateTime ? -1 : a.dateTime < b.dateTime ? 1 : 0;
        });*/

        this.isLoadingResults = false;
      });
    }
  }

  getUserEmailLink(email: string): string {
    return 'mailto:' + email + '?subject=Reply to evaluation';
  }

}
