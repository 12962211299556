<app-header></app-header>
<div class="container main-div">
  <div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <div>
    <div>
      <h1>
        {{ title }}
        <mat-icon>search</mat-icon>
      </h1>
    </div>

    <div class="flex">
      <span>
        <mat-form-field fxFlex="40%" color="accent">
          <input
            matInput
            type="text"
            (keyup)="doFilter($event.target.value)"
            placeholder="Filter"
          />
        </mat-form-field>
      </span>
    </div>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 row"
    >
      <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let &quot;data-row&quot;">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(data - row) : null"
            [checked]="selection.isSelected(data - row)"
            [aria-label]="checkboxLabel(data - row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
        <td mat-cell *matCellDef="let skill">{{ skill.category }}</td>
      </ng-container>

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
        <td mat-cell *matCellDef="let skill">{{ skill.code }}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let skill">{{ skill.type }}</td>
      </ng-container>

      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Icon</th>
        <td mat-cell *matCellDef="let skill">{{ skill.icon }}</td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
        <td mat-cell *matCellDef="let skill">{{ skill.active }}</td>
      </ng-container>

      <ng-container matColumnDef="en-displayCatgory">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="displayCatCol"
        >
          En Display Category
        </th>
        <td mat-cell *matCellDef="let skill" class="displayCatCol">
          {{ skill.enDisplayCategory }}
        </td>
      </ng-container>

      <ng-container matColumnDef="en-displaySkill">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="displaySkillCol"
        >
          En Display Skill
        </th>
        <td mat-cell *matCellDef="let skill" class="displaySkillCol">
          {{ skill.enDisplaySkill }}
        </td>
      </ng-container>

      <ng-container matColumnDef="es-displayCatgory">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="displayCatCol"
        >
          Es Display Category
        </th>
        <td mat-cell *matCellDef="let skill" class="displayCatCol">
          {{ skill.esDisplayCategory }}
        </td>
      </ng-container>

      <ng-container matColumnDef="es-displaySkill">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-heade
          class="displaySkillCol"
        >
          Es Display Skill
        </th>
        <td mat-cell *matCellDef="let skill" class="displaySkillCol">
          {{ skill.esDisplaySkill }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="actionsCol"
        >
          Inactive / Active
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            *ngIf="row.active"
            (click)="onEditActive(row)"
          >
            <mat-icon>toggle_on</mat-icon>
          </button>
          <button
            mat-icon-button
            *ngIf="!row.active"
            (click)="onEditActive(row)"
          >
            <mat-icon>toggle_off</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
      color="accent"
    >
    </mat-paginator>
  </div>
</div>
