<div class="my-3" style="display: block">
  <canvas
    baseChart
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [legend]="lineChartLegend"
    [chartType]="lineChartType"
  ></canvas>
</div>
