<ng-container *ngFor="let evaluation of evaluationList | orderBy:'skillName'">
      <div class="row padded">
            <div class="col-md-6 m-md-auto mb-2">
                  <label class="m-0">{{evaluation.skillName}}</label>
            </div>
            <div class="col-md-6 d-flex flex-row justify-content-between">
                  <img
                        *ngIf="(mode && !attendance) ? true : evaluation.skillResult !== poorEval"
                        src="assets/images/eval_very_disatisfied_outline.png"
                        stretch="fill"
                        class="m-y-10 mx-2 img-rounded img-responsive eval-score-icon"
                        (click)="changeEvaluation(evaluation, poorEval)" />
                  <img
                        *ngIf="(mode && !attendance) ? false : evaluation.skillResult === poorEval"
                        src="assets/images/eval_very_disatisfied_selected.png"
                        stretch="fill"
                        class="m-y-10 mx-2 img-rounded img-responsive eval-score-icon"
                        (click)="changeEvaluation(evaluation, neutralEval)" />
                  <img
                        *ngIf="(mode && !attendance) ? true : evaluation.skillResult !== fairEval"
                        src="assets/images/eval_disatisfied_outline.png"
                        stretch="fill"
                        class="m-y-10 mx-2 img-rounded img-responsive eval-score-icon"
                        (click)="changeEvaluation(evaluation, fairEval)" />
                  <img
                        *ngIf="(mode && !attendance) ? false : evaluation.skillResult === fairEval"
                        src="assets/images/eval_disatisfied_selected.png"
                        stretch="fill"
                        class="m-y-10 mx-2 img-rounded img-responsive eval-score-icon"
                        (click)="changeEvaluation(evaluation, neutralEval)" />
                  <img
                        *ngIf="(mode && !attendance) ? true : evaluation.skillResult !== goodEval"
                        src="assets/images/eval_satisfied_outline.png"
                        stretch="fill"
                        class="m-y-10 mx-2 img-rounded img-responsive eval-score-icon"
                        (click)="changeEvaluation(evaluation, goodEval)" />
                  <img
                        *ngIf="(mode && !attendance) ? false : evaluation.skillResult === goodEval"
                        src="assets/images/eval_satisfied_selected.png"
                        stretch="fill"
                        class="m-y-10 mx-2 img-rounded img-responsive eval-score-icon"
                        (click)="changeEvaluation(evaluation, neutralEval)" />
                  <img
                        *ngIf="(mode && !attendance) ? true : evaluation.skillResult !== excellentEval"
                        src="assets/images/eval_very_satisfied_outline.png"
                        stretch="fill"
                        class="m-y-10 mx-2 img-rounded img-responsive eval-score-icon"
                        (click)="changeEvaluation(evaluation, excellentEval)" />
                  <img
                        *ngIf="(mode && !attendance) ? false : evaluation.skillResult === excellentEval"
                        src="assets/images/eval_very_satisfied_selected.png"
                        stretch="fill"
                        class="m-y-10 mx-2 img-rounded img-responsive eval-score-icon"
                        (click)="changeEvaluation(evaluation, neutralEval)" />
            </div>
      </div>
      <div>
            <mat-divider></mat-divider>
      </div>
</ng-container>
