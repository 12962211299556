import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatDialog,


  MatDialogConfig
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { PlayerDrill } from '../models/playerDrill';
import { PlayerDrillDialogFormComponent } from '../player-drill-dialog-form/player-drill-dialog-form.component';
import { FirebaseService } from '../shared/firebase.service';
import { NotificationService } from '../shared/notification.service';


@Component({
  selector: 'app-player-drills',
  templateUrl: './player-drills.component.html',
  styleUrls: ['./player-drills.component.css']
})
export class PlayerDrillsComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public displayedColumns: string[] = [
    'select',
    'playerId',
    'drillName',
    'drillCategory',
    'drillLink',
    'linkLanguage',
    'playerDrillId',
    'active',
    'actions'
  ];
  public dataSource = new MatTableDataSource();
  private initialSelection = [];
  private allowMultiSelect = true;
  private selection = new SelectionModel<any>(
    this.allowMultiSelect,
    this.initialSelection
  );

  public isLoadingResults = true;

  private playerDrillList: PlayerDrill[];

  public title = 'Player Drills';

  private playerIdFilter = new FormControl();
  private drillNameFilter = new FormControl();
  private drillCategoryFilter = new FormControl();
  private drillLinkFilter = new FormControl();
  private linkLanguageFilter = new FormControl();
  public playerDrillIdFilter = new FormControl();
  private filteredValues = {
    playerId: '',
    drillName: '',
    drillCategory: '',
    drillLink: '',
    linkLanguage: '',
    playerDrillId: ''
  };

  constructor(
    private firebase: FirebaseService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.firebase.getPlayerDrills().subscribe(data => {
      this.playerDrillList = [];
      data.forEach(p => {
        this.playerDrillList.push(p as PlayerDrill);
      });
      // console.log(this.playerDrillList.length);
      // console.log(this.playerDrillList[0].playerId);
      this.dataSource = new MatTableDataSource(this.playerDrillList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.selection = new SelectionModel<PlayerDrill>(
        this.allowMultiSelect,
        this.initialSelection
      );

      this.playerIdFilter.valueChanges.subscribe(playerIdFilterValue => {
        this.filteredValues['playerId'] = playerIdFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.drillNameFilter.valueChanges.subscribe(drillNameFilterValue => {
        this.filteredValues['drillName'] = drillNameFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.drillCategoryFilter.valueChanges.subscribe(drillCategoryFilterValue => {
        this.filteredValues['drillCategory'] = drillCategoryFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.drillLinkFilter.valueChanges.subscribe(drillLinkFilterValue => {
        this.filteredValues['drillLink'] = drillLinkFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.linkLanguageFilter.valueChanges.subscribe(linkLanguageFilterValue => {
        this.filteredValues['linkLanguage'] = linkLanguageFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.playerDrillIdFilter.valueChanges.subscribe(playerDrillIdFilterValue => {
        this.filteredValues['playerDrillId'] = playerDrillIdFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.setupFilter();
      this.isLoadingResults = false;
    });

  }

  setupFilter() {
    this.dataSource.filterPredicate = (d: PlayerDrill, filter: string) => {
      const searchString = JSON.parse(filter);
      if (!d.playerId) {
        d.playerId = '';
      }
      if (!d.drillName) {
        d.drillName = '';
      }
      if (!d.drillCategory) {
        d.drillCategory = '';
      }
      if (!d.drillLink) {
        d.drillLink = '';
      }
      if (!d.linkLanguage) {
        d.linkLanguage = '';
      }
      return (
        d.playerId
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.playerId.toLowerCase()) !== -1 &&
        d.drillName
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.drillName.toLowerCase()) !== -1 &&
        d.drillCategory
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.drillCategory.toLowerCase()) !== -1 &&
        d.playerDrillId
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.playerDrillId.toLowerCase()) !== -1 &&
        d.drillLink
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.drillLink.toLowerCase()) !== -1 &&
        d.linkLanguage
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.linkLanguage.toLowerCase()) !== -1
      );
    };
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PlayerDrill): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${
      this.selection.isSelected(row) ? 'deselect' : 'select'
    } playerDrill ${row.playerId + ' ' + row.playerDrillId}`;
  }

  /**Function called when the add player button is clicked */
  onNewPlayerDrill(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '45%';
    const dialogRef = this.dialog.open(PlayerDrillDialogFormComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result.playerId);
        this.firebase.addPlayerDrillLink(
          result.playerId,
          result.drillName,
          result.drillCategory,
          result.drillLink,
          result.linkLanguage,
          result.active
        );

        this.notification.success('Player Drill added');
      }
    });
  }

  onEditPlayerDrill(row) {
    const dialogRef = this.dialog.open(PlayerDrillDialogFormComponent, {
      width: '45%',
      disableClose: true,
      autoFocus: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.firebase.editPlayerDrill(
          row.playerDrillId,
          result.playerId,
          result.drillName,
          result.drillCategory,
          result.drillLink,
          result.linkLanguage,
          result.active
        );
        this.notification.success('Player edited');
      }
    });
  }

  onDeletePlayerDrill(row) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '30%',
      disableClose: true,
      autoFocus: true,
      data: { action: 'delete player drill', object: row }
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.firebase.deletePlayerDrill(row.playerDrillId);
        this.notification.success('Player Drill deleted');
      }
    });
  }

}
