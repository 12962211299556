import { Component, Input, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';

@Component({
  selector: 'app-category-dashboard',
  templateUrl: './category-dashboard.component.html',
  styleUrls: ['./category-dashboard.component.css']
})
export class CategoryDashboardComponent implements OnInit {
  @Input() physical: number;
  @Input() behavioral: number;
  @Input() tactical: number;
  @Input() technical: number;
  scoreLabel = 'Puntaje';

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales : {
      xAxes: [{
        ticks: {
            min: 0,
            fontColor: '#fbfbfb',
            fontFamily: '\'Roboto\', sans-serif'
        },
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        type: 'category',
        ticks: {
          fontColor: '#fbfbfb',
          fontFamily: '\'Roboto\', sans-serif'
        },
        gridLines: {
          display: false
        }
      }]
    }
  };
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = false;

  public barChartData: ChartDataSets[] = [
    { data: [1, 3, 2.5, 0.5]
    , backgroundColor: ['#00ff87' // fisicas
      , '#d200e5' // mentales
      , '#ff015b' // tacticas
      , '#05f1ff' // tecnicas
      ]
    , label: this.scoreLabel
    , hoverBackgroundColor: ['#00B862'// fisicas
    , '#83008F' // mentales
    , '#B80040' // tacticas
    , '#00AEB8' // tecnicas
      ]
    , hoverBorderColor: '#7f7f7f'
    }
/* ,
    { data: [3]},
    { data: [2.5], backgroundColor: '#FFFFFF'},
    { data: [0.5]}, */
  ];

  public barChartLabels: string[] = ['Físicas', 'Mentales', 'Tácticas', 'Técnicas'];

  public barChartColors: Color[] = [
    { backgroundColor: '#00ff87' }, // fisicas
    { backgroundColor: '#d200e5' }, // mentales
    { backgroundColor: '#ff015b' }, // tacticas
    { backgroundColor: '#05f1ff' }, // tecnicas
  ];

  constructor(@Inject(LOCALE_ID) public locale: string) { }

  ngOnInit() {
    this.setLocalizedMessages();
    this.setMinimumValueXaxis();
    this.barChartData[0].label = this.scoreLabel;
    this.barChartData[0].data = [
      this.physical
      , this.behavioral
      , this.tactical
      , this.technical
    ];
  }

  setLocalizedMessages() {
    if (this.locale.includes('en')) {
      this.scoreLabel = 'Score';
      this.barChartLabels = ['Physical', 'Behavioral', 'Tactical', 'Technical'];
    }
  }

  setMinimumValueXaxis() {
    const min = Math.min(this.physical, this.behavioral, this.tactical, this.technical);
    if (min < 0 ) {
      this.barChartOptions.scales.xAxes[0].ticks.min = min;
    }
  }

}
