<mat-toolbar color="accent">
    <span>{{ title }}</span>
    <span class="spacer"></span>
    <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
  </mat-toolbar>
    <form [formGroup]="skillListForm" class="normal-form">
      <mat-grid-list cols="1" rowHeight="15rem">
        <mat-grid-tile>
          <div class="column-form">
            <mat-form-field color="accent">
              <input formControlName="name" matInput placeholder="Name">
              <mat-error>This field is mandatory.</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
    <div class="button-row" mat-dialog-actions>
      <button mat-raised-button color="accent" type="submit" [disabled]="skillListForm.invalid" (click)="onSubmit()">
        Submit
      </button>
      <button mat-raised-button color="warn" (click)="onClear()">Clear</button>
    </div>
  