<mat-toolbar color="accent">
  <span>{{ title }}</span>
  <span class="spacer"></span>
  <button
    class="btn-dialog-close"
    mat-stroked-button
    (click)="onClose()"
    tabIndex="-1"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>
<form [formGroup]="userInfoForm" class="normal-form">
  <mat-grid-list cols="2" rowHeight="21rem">
    <mat-grid-tile>
      <div class="controles-container column-form">
        <mat-form-field color="accent">
          <input formControlName="name" matInput placeholder="Name" />
          <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>
        <mat-form-field color="accent">
          <input formControlName="lastName" matInput placeholder="Last Name" />
          <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>
        <mat-form-field color="accent">
          <input formControlName="userId" matInput placeholder="User ID" />
          <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>
        <mat-form-field color="accent">
          <input
            formControlName="profilePicturePath"
            matInput
            placeholder="Profile picture path"
          />
          <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>
        <mat-form-field color="accent">
          <input formControlName="email" matInput placeholder="Email" />
          <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="add-bottom-padding radio-group">
        <label id="language-radio-group-label" class="radio-button-label"
          >Pick the language:</label
        >
        <mat-radio-group
          formControlName="language"
          aria-labelledby="language-radio-group-label"
        >
          <mat-radio-button class="add-right-padding" value="1"
            >En</mat-radio-button
          >
          <mat-radio-button class="add-right-padding" value="2"
            >Es</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="button-row" mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="onClear()">Clear</button>
    <span class="spacer"></span>
    <button
      mat-raised-button
      color="accent"
      type="submit"
      [disabled]="userInfoForm.invalid"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </div>
</form>
