import { Component, OnInit, Inject } from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-dialog-form',
  templateUrl: './user-dialog-form.component.html',
  styleUrls: ['./user-dialog-form.component.css']
})
export class UserDialogFormComponent {

  public title: string;

  public userForm = new FormGroup({
    action: new FormControl('new'),
    name: new FormControl('', Validators.required),
    role: new FormControl('1', Validators.required),
    teamId: new FormControl('', Validators.required),
    userId: new FormControl('', Validators.required),
    playerId: new FormControl(null),
    year: new FormControl('', Validators.required),
    default: new FormControl('1', Validators.required)
  });

  constructor(private notification: NotificationService,
              public dialogRef: MatDialogRef<UserDialogFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                if (this.data) {
                  this.title = 'Edit User';
                  this.populateForm(this.data);
                } else {
                  this.title = 'New User';
                }
              }


  initializeFormGroup() {
    this.userForm.setValue({
      action: 'new',
      name: '',
      role: '1',
      teamId: '',
      userId: '',
      playerId: '',
      year: '',
      default: '1'
    });
  }

  onClear() {
    this.userForm.reset();
    this.initializeFormGroup();
    this.notification.success('Cleared successfully');
  }

  onClose() {
    this.userForm.reset();
    this.initializeFormGroup();
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.title === 'Edit User') {
      this.dialogRef.close({usermapId: this.data.usermapId, ... this.userForm.value});
    } else {
      this.dialogRef.close(this.userForm.value);
    }
  }

  populateForm(user) {
    this.initializeFormGroup();
    this.userForm.setValue({
      action: 'edit',
      name: user.name,
      role: this.checkRole(user.role),
      teamId: user.teamId,
      userId: user.userId,
      playerId: user.playerId ? user.playerId : null,
      year: user.year,
      default: this.checkDefault(user.default)
    });
  }

  checkDefault(userDefault: boolean) {
    if (userDefault) {
      return '1';
    } else {
      return '2';
    }
  }

  checkRole(role: string) {
    if (role === 'Coach') {
      return '1';
    } else if (role === 'Player') {
      return '2';
    } else {
      return '3';
    }
  }

}
