import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { PlayerDrill } from '../models/playerDrill';
import { Team } from '../models/team';
import { FirebaseService } from '../shared/firebase.service';

@Component({
  selector: 'app-drill',
  templateUrl: './drill.component.html',
  styleUrls: ['./drill.component.css'],
})
export class DrillComponent implements OnInit {
  drillId: string;
  isLoadingResults = true;
  drill: PlayerDrill;
  team: Team;
  validDrill: boolean;
  isTeam: boolean;
  isLink: boolean;
  isVideo: boolean;
  isMov: boolean;
  isMp4: boolean;
  isImg: boolean;
  isText: boolean;

  teamLogo = environment.versions.teamLogo;
  teamVideoCover = environment.versions.teamVideoCover;
  teamColor = environment.versions.teamColor;
  teamName = environment.versions.teamName;

  constructor(
    private firebase: FirebaseService,
    private storage: AngularFireStorage,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.drillId = params['id'];
    });

    if (!this.authService.isLoggedIn) {
      await this.authService.loginAnonymously().then((res) => {
        if (res) {
          // console.log('logged anonymously');
        } else {
          // console.log('failed anonymous login');
          this.validDrill = false;
          return;
        }
      });
    }

    this.firebase.getPlayerDrillFromId(this.drillId).subscribe((data) => {
      // console.log(data);
      this.drill = data;
      if (this.drill) {
        this.validDrill = true;
        this.drill.playerDrillId = this.drillId;
      } else {
        this.validDrill = false;
        this.isLoadingResults = false;
        return;
      }
      // qué tipo de drill es
      switch (this.drill.drillType) {
        case 'customLink': {
          this.isLink = true;
          break;
        }
        case 'customMedia': {
          this.defineMediaType();
          break;
        }
      }
      // ver si es un equipo y si es traer datos como corresponda
      this.firebase.getTeam(this.drill.playerId).subscribe(async (dataTeam) => {
        this.team = dataTeam;
        if (this.team) {
          // console.log('is team' + this.drill.playerId);
          this.teamName = this.team.name;
          if (this.team.premium) {
            this.teamColor = this.team.teamColor;
            await this.getTeamFieldsURL();
          }
        }
        // si es jugador entonces se mantienen los defaults, idealmente, buscamos el id del team del player
        this.isLoadingResults = false;
      });
    });
  }

  async getTeamFieldsURL() {
    // team logo
    // Reference to storage bucket
    let ref = this.storage.ref(this.team.teamLogo);
    this.teamLogo = await ref.getDownloadURL().toPromise();
    ref = this.storage.ref(this.team.teamVideoCover);
    this.teamVideoCover = await ref.getDownloadURL().toPromise();
  }

  defineMediaType() {
    const format = this.drill.drillMediaFormat.toLowerCase();
    switch (format) {
      case 'pdf': {
        this.isLink = true;
        break;
      }
      case 'quicktime': {
        this.isVideo = true;
        this.isMov = true;
        break;
      }
      case 'mp4': {
        this.isVideo = true;
        this.isMp4 = true;
        break;
      }
      case 'png':
      case 'jpg':
      case 'gif':
      case 'jpeg': {
        this.isImg = true;
        break;
      }
      case 'text': {
        this.isText = true;
        break;
      }
      default: {
        this.isLink = true;
        break;
      }
    }
  }
}
