import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import bootstrap from 'bootstrap';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HeaderComponent } from 'src/app/common-ui/components/header/header.component';
import { CommonUiModule } from 'src/app/common-ui/common-ui.module';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CreateMessageComponent } from './create-message/create-message.component';
import { ViewMessagesComponent } from './view-messages/view-messages.component';



@NgModule({
  declarations: [CreateMessageComponent, ViewMessagesComponent],
  imports: [
    CommonModule,
    CommonUiModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    FormsModule, ReactiveFormsModule
    // HeaderComponent,
  ],
  exports: [
    CreateMessageComponent,
    ViewMessagesComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MessagesModule { }
