<!-- <div class="row">
    <div
        *ngIf="category === 'Físicas'"
        class="statisticIcon my-auto ml-3"
        style="background-color: #00ff87;"
    >
        <img
            [src]="categoryIcon"
            class="statisticImage"
            width="40"
            height="40"
        />
    </div>
    <div
        *ngIf="category === 'Mentalidad'"
        class="statisticIcon my-auto ml-3"
        style="background-color: #e74cf5;"
    >
        <img
            [src]="categoryIcon"
            class="statisticImage"
            width="40"
            height="40"
        />
    </div>
    <div
        *ngIf="category === 'Táctica'"
        class="statisticIcon my-auto ml-3"
        style="background-color: #ff015b;"
    >
        <img
            [src]="categoryIcon"
            class="statisticImage"
            width="40"
            height="40"
        />
    </div>
    <div
        *ngIf="category === 'Técnica'"
        class="statisticIcon my-auto ml-3"
        style="background-color: #05f1ff;"
    >
        <img
            [src]="categoryIcon"
            class="statisticImage"
            width="40"
            height="40"
        />
    </div>
    <div class="col-lg-3 categoryDashboardTitle my-auto pl-0 col-4 col-sm-10 mt-sm-3 my-md-auto col-md-3">
        <span class="categoryDashboardLabel">{{ category }}</span>
    </div>
</div> -->
<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingTeamResults"></mat-spinner>
</div>
<div *ngIf="drillListFull" class="table-responsive mt-3">
    <table class="table table-borderless table-striped table-sm table-hover">
        <tbody *ngIf="drillList.length>0">
            <ng-container *ngFor="let drill of drillList">
                <tr>
                <td class="align-middle">
                    <div class="row mx-auto">
                        <div class="statisticIcon ml-3" [style.background-color]="getCategoryColor(drill.drillCategory)">
                            <img [src]="getCategoryIcon(drill.drillCategory)" class="statisticImage" width="40" height="40"/>
                        </div>
                        <div class="col">
                            <a [href]="drill.drillLink" [hreflang]="drill.linkLanguage" target="_blank" class="">
                                {{drill.drillName}} <mat-icon>launch</mat-icon>
                            </a>
                        </div>
                        <div class="col-1" *ngIf="editable && isDeletableDrill(drill.playerId)">
                            <button mat-icon-button aria-label="borrar entrenamiento" (click)="onDeleteDrill(drill)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <!--
                            <button mat-icon-button aria-label="drill options" [matMenuTriggerFor]="menuDrill">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menuDrill="matMenu" xPosition="before">
                                <button mat-menu-item>
                                    <mat-icon aria-hidden="true">edit</mat-icon>
                                    <span>Editar</span>
                                </button>
                                <button mat-menu-item (click)="onDeleteDrill(drill)">
                                    <mat-icon aria-hidden="true">delete</mat-icon>
                                    <span>Borrar</span>
                                </button>
                            </mat-menu>
                            -->
                        </div>
                    </div>
                </td>
                <!--
                    *ngIf="userRole == 'Coach'"
                <td class="text-right">{{skill.score}}</td>
                -->
                </tr>
            </ng-container>
        </tbody>
        <tbody *ngIf="drillList.length==0">
            <tr>
                <td i18n="@@drill-list-not-found" class="text-center">
                    No training assigned
                </td>
            </tr>
        </tbody>
    </table>
</div>
