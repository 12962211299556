import { Pipe, PipeTransform } from '@angular/core';
import { Skill } from 'src/app/models/skill';

@Pipe({
  name: 'categoryFilter'
})
export class CategoryPipe implements PipeTransform {

  transform(items: Skill[], category: string): any[] {
    if (!items || items.length === 0) {
      return items;
    }
    return items.filter((item: Skill) =>  item.category === category);
    }

}
