<app-header></app-header>
<div class="container main-div">
    <div class="example-loading-shade" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <div>
        <div>
            <h1>
                {{ title }}
                <mat-icon>search</mat-icon>
            </h1>
        </div>

        <div class="flex">
            <span class="spacer"></span>
            <span>
                <button mat-raised-button color="accent" (click)="onNewPlayerDrill()">
                    <mat-icon>add</mat-icon>
                    Add player drill
                </button>
            </span>
        </div>

        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            class="mat-elevation-z8 row"
        >
        <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

        <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                    color="accent"
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()"
                >
                </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                <mat-checkbox
                    color="accent"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)"
                >
                </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="playerId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="headerContainer">
                    <p class="bold">
                    Player ID
                    </p>
                    <mat-form-field class="longFilter" color="accent">
                    <input
                        matInput
                        class="form-field"
                        [formControl]="playerIdFilter"
                        placeholder="Filter by player Id"
                    />
                    </mat-form-field>
                </div>
                </th>
                <td mat-cell *matCellDef="let playerDrill">{{ playerDrill.playerId }}</td>
            </ng-container>

            <ng-container matColumnDef="drillName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="headerContainer">
                    <p class="bold">
                    Drill Name
                    </p>
                    <mat-form-field class="longFilter" color="accent">
                    <input
                        matInput
                        class="form-field"
                        [formControl]="drillNameFilter"
                        placeholder="Filter by drill name"
                    />
                    </mat-form-field>
                </div>
                </th>
                <td mat-cell *matCellDef="let playerDrill">{{ playerDrill.drillName }}</td>
            </ng-container>

            <ng-container matColumnDef="drillCategory">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="headerContainer">
                    <p class="bold">
                    Drill Category
                    </p>
                    <mat-form-field class="longFilter" color="accent">
                    <input
                        matInput
                        class="form-field"
                        [formControl]="drillCategoryFilter"
                        placeholder="Filter by drill category"
                    />
                    </mat-form-field>
                </div>
                </th>
                <td mat-cell *matCellDef="let playerDrill">{{ playerDrill.drillCategory }}</td>
            </ng-container>

            <ng-container matColumnDef="drillLink">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="headerContainer">
                    <p class="bold">
                    Drill Link
                    </p>
                    <mat-form-field class="longFilter" color="accent">
                    <input
                        matInput
                        class="form-field"
                        [formControl]="drillLinkFilter"
                        placeholder="Filter by link"
                    />
                    </mat-form-field>
                </div>
                </th>
                <td mat-cell *matCellDef="let playerDrill">{{ playerDrill.drillLink }}</td>
            </ng-container>

            <ng-container matColumnDef="linkLanguage">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="headerContainer">
                    <p class="bold">
                    Drill language
                    </p>
                    <mat-form-field class="longFilter" color="accent">
                    <input
                        matInput
                        class="form-field"
                        [formControl]="linkLanguageFilter"
                        placeholder="Filter by language"
                    />
                    </mat-form-field>
                </div>
                </th>
                <td mat-cell *matCellDef="let playerDrill">{{ playerDrill.linkLanguage }}</td>
            </ng-container>

            <ng-container matColumnDef="playerDrillId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="headerContainer">
                    <p class="bold">
                    Player Drill Id
                    </p>
                    <mat-form-field class="mediumFilter" color="accent">
                    <input
                        matInput
                        class="form-field"
                        [formControl]="playerDrillIdFilter"
                        placeholder="Filter by player drill id"
                    />
                    </mat-form-field>
                </div>
                </th>
                <td mat-cell *matCellDef="let playerDrill">{{ playerDrill.playerDrillId }}</td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                <td mat-cell *matCellDef="let playerDrill">{{ playerDrill.active }}</td>
            </ng-container>


            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let row">
                <button mat-icon-button (click)="onEditPlayerDrill(row)">
                    <mat-icon>launch</mat-icon>
                </button>
                <button mat-icon-button (click)="onDeletePlayerDrill(row)">
                    <mat-icon color="warn">delete_outline</mat-icon>
                </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
            [pageSize]="10"
            [pageSizeOptions]="[10, 25, 50, 100]"
            color="accent"
        >
        </mat-paginator>
    </div>
</div>
