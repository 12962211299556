<app-header></app-header>
<div class="container main-div">
  <div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <div>
    <div>
      <h1>
        {{ title }}
        <mat-icon>search</mat-icon>
      </h1>
    </div>

    <div class="flex">
      <span>
        <mat-form-field fxFlex="40%" color="accent">
          <input
            matInput
            type="text"
            (keyup)="doFilter($event.target.value)"
            placeholder="Filter"
          />
        </mat-form-field>
      </span>
      <span class="spacer"></span>
      <span>
        <button mat-raised-button color="accent" (click)="onNewUser()">
          <mat-icon>add</mat-icon>
          Add user
        </button>
      </span>
    </div>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 row"
    >
      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let &quot;data-row&quot;">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(data - row) : null"
            [checked]="selection.isSelected(data - row)"
            [aria-label]="checkboxLabel(data - row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let user">{{ user.name }}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let user">{{ user.role }}</td>
      </ng-container>

      <ng-container matColumnDef="teamId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Team ID</th>
        <td mat-cell *matCellDef="let user">{{ user.teamId }}</td>
      </ng-container>

      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
        <td mat-cell *matCellDef="let user">{{ user.userId }}</td>
      </ng-container>

      <ng-container matColumnDef="playerId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Player ID</th>
        <td mat-cell *matCellDef="let user">{{ user.playerId }}</td>
      </ng-container>

      <ng-container matColumnDef="year">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Year</th>
        <td mat-cell *matCellDef="let user">{{ user.year }}</td>
        s
      </ng-container>

      <ng-container matColumnDef="default">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Default</th>
        <td mat-cell *matCellDef="let user">{{ user.default }}</td>
        s
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button (click)="onEditUser(row)">
            <mat-icon>launch</mat-icon>
          </button>
          <button mat-icon-button (click)="onDeleteUser(row)">
            <mat-icon color="warn">delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
      color="accent"
    >
    </mat-paginator>
  </div>
</div>
