import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Skill } from 'src/app/models/skill';
import { SkillList } from 'src/app/models/skillList';
import { FirebaseService } from 'src/app/shared/firebase.service';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-skill-list-edit',
  templateUrl: './skill-list-edit.component.html',
  styleUrls: ['./skill-list-edit.component.css']
})
export class SkillListEditComponent implements OnInit {

  public title: string;
  public isLoadingResults: boolean;
  private id: string;
  private skillList: SkillList;
  private skillCollection: Skill[];
  private categories: string[] = [];

  public skillListEditForm = new FormGroup({
    action: new FormControl('edit'),
    name: new FormControl('', Validators.required)
  });

  constructor(
    private firebaseService: FirebaseService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
      this.title = 'Edit Skill List';
    }

  ngOnInit() {
    this.isLoadingResults = true;
    this.activatedRoute.params.subscribe(params => {
      this.id = params['skillListId'];
      this.firebaseService.getSkillListById(this.id).subscribe(
        list => {
          this.skillList = list;
          this.populateForm(this.skillList);
          this.firebaseService.getSkills().subscribe(
            data => {
              this.skillCollection = data;
              this.displayData();
            },
            error => {
              console.error(error);
              this.isLoadingResults = false;
            }
          );
        },
        error => {
          console.error(error);
          this.isLoadingResults = false;
        }
      );
    });
  }

  private displayData() {
    this.skillCollection = this.skillCollection.filter(s => s.active);
    this.skillCollection.forEach(s => {
        s.isSelected = (this.skillList.skills && this.skillList.skills.indexOf(s.skillId) >= 0);
        this.firebaseService.getSkillLanguages(s.skillId).subscribe(
          lang => {
            lang.forEach(l => {
              if (l.langId === 'en') {
                s.enDisplayCategory = l.displayCategory;
                s.enDisplaySkill = l.displaySkill;
              } else {
                s.esDisplayCategory = l.displayCategory;
                s.esDisplaySkill = l.displaySkill;
              }
            });
          },
          error => {
            console.log(error);
            this.isLoadingResults = false;
          });
    });
    setTimeout(() => {
      this.categories = this.skillCollection
        .filter(x => x.type === 'title')
        .map(x => x.enDisplayCategory)
        .sort((a, b) => {
          if (a > b) { return 1; }
          if (a < b) { return -1; }
          return 0;
        });
      // this.skillCollection = this.skillCollection.filter(s => s.type !== 'title' );
      this.skillCollection.sort((a, b) => {
        if (a.enDisplaySkill > b.enDisplaySkill) { return 1; }
        if (a.enDisplaySkill < b.enDisplaySkill) { return -1; }
        return 0;
      });
      this.isLoadingResults = false;
    }, 1000);
  }

  private populateForm(skillList) {
    this.skillListEditForm.setValue({
      action: 'edit',
      name: skillList.name
    });
  }

  onSubmit() {
    this.isLoadingResults = true;
    const activeSkills = this.skillCollection
      .filter(s => s.isSelected)
      .map(s => s.skillId);
    const name = this.skillListEditForm.get('name').value;
    this.firebaseService.editSkillList(this.id, name, activeSkills)
    .then(
      r => {
        if (r.status) {
          this.notificationService.success('Skill list edited');
          this.router.navigate(['skill-list']);
        } else {
          console.error(r.message);
          this.notificationService.error('Skill list could not be edited');
        }
      })
    .finally(() => this.isLoadingResults = false);
  }

  onCancel() {
    this.router.navigate(['skill-list']);
  }

  getSkillName(skill: Skill) {
    return skill.type === 'title' ? skill.enDisplayCategory : skill.enDisplaySkill;
  }

}
