import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

const defaultLanguage = 'en';
const languageKey = 'language';
const languages = [['en', 'English'], ['es', 'Español']];

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  get defaultLanguage(): string {
    return defaultLanguage;
  }

  get language(): string {
    return localStorage.getItem(languageKey) || defaultLanguage;
  }

  set language(language: string) {
    localStorage.setItem(languageKey, language);
    this.document.location.reload();
  }

  get languages(): string[][] {
    return languages;
  }
}
