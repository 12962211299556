import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { NotificationService } from '../shared/notification.service';

export interface Position {
  posValue: string;
  posViewValue: string;
}

@Component({
  selector: 'app-player-drill-dialog-form',
  templateUrl: './player-drill-dialog-form.component.html',
  styleUrls: ['./player-drill-dialog-form.component.css']
})
export class PlayerDrillDialogFormComponent {
  public title: string;

  public playerDrillForm = new FormGroup({
    action: new FormControl('new'),
    playerId: new FormControl('', Validators.required),
    drillName: new FormControl('', Validators.required),
    drillCategory: new FormControl('', Validators.required),
    drillLink:  new FormControl('', Validators.required),
    linkLanguage: new FormControl('', Validators.required),
    active: new FormControl(true, Validators.required)
  });


  constructor(
    private notification: NotificationService,
    public dialogRef: MatDialogRef<PlayerDrillDialogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.title = 'Edit Player Drill';
      this.populateForm(this.data);
    } else {
      this.title = 'New Player Drill';
    }
  }

  initializeFormGroup() {
    this.playerDrillForm.setValue({
      action: 'new',
      playerId: '',
      drillName: '',
      drillCategory: '',
      drillLink: '',
      linkLanguage: 'es',
      active: true
    });
  }

  onClose() {
    this.playerDrillForm.reset();
    this.initializeFormGroup();
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.playerDrillForm.value);
  }

  populateForm(playerDrill) {
    this.initializeFormGroup();
    if (playerDrill.drillLink && playerDrill.drillName) {
      this.playerDrillForm.setValue({
        action: 'edit',
        playerId: playerDrill.playerId,
        drillName: playerDrill.drillName,
        drillCategory: playerDrill.drillCategory,
        drillLink: playerDrill.drillLink,
        linkLanguage: playerDrill.linkLanguage,
        active: playerDrill.active
      });
    } else {
      this.playerDrillForm.setValue({
        action: 'edit',
        playerId: playerDrill.playerId,
        drillName: null,
        drillCategory: playerDrill.drillCategory,
        drillLink: null,
        linkLanguage: playerDrill.linkLanguage,
        active: playerDrill.active
      });
    }
  }

}
