import { Component, OnInit, Input, LOCALE_ID, Inject  } from '@angular/core';
import { FirebaseService } from '../../shared/firebase.service';
import { OverallStatistics } from '../../models/overallStatistics';
import { Player } from '../../models/player';
import { PlayerSkillSummary, SkillSummary } from 'src/app/models/playerSkillSummary';
import { Evaluation } from 'src/app/models/evaluation';
import { Skill } from 'src/app/models/skill';
import { SkillLanguage } from 'src/app/models/skillLanguage';


@Component({
  selector: 'app-metrics-list',
  templateUrl: './metrics-list.component.html',
  styleUrls: ['./metrics-list.component.css']
})
export class MetricsListComponent implements OnInit {
  @Input() playerId: string;
  @Input() evaluationMetricList?: Evaluation[];
  @Input() fromEvaluation?: boolean;

  playerSkillScoreSummary: PlayerSkillSummary;
  playerMetricSkillSummary: SkillSummary[] = [];
  showDetail = false;
  selectedSkillCode = '';
  clickedSkillName = '';
  selectedSkill: Skill;



  constructor(private firebase: FirebaseService, @Inject(LOCALE_ID) public locale: string) { }

  ngOnInit() {
    if (this.fromEvaluation &&
      (this.evaluationMetricList.length > 0) &&
      (this.playerMetricSkillSummary.length === 0)) {
        this.getMetricEvaluationData();
    } else
    if (!this.fromEvaluation && this.playerId &&
      (this.playerMetricSkillSummary.length === 0)) {
      this.getSummaryMetricData();
    }
  }


  getMetricEvaluationData() {
    this.evaluationMetricList.forEach(e => {
      const ss: SkillSummary = {
        code : e.skillCode,
        overallScore : e.skillResult,
        strengthScore : 0,
        weaknessScore : 0
      };
      this.playerMetricSkillSummary.push(ss);
    });
    this.playerMetricSkillSummary.forEach(metricSkill => {
      this.firebase.getSkillsByCode(metricSkill.code).subscribe(skill => {
        metricSkill.skill = skill[0] as Skill;
        this.firebase.getSkillLanguages(metricSkill.skill.skillId).subscribe(skillLanguage => {
          metricSkill.language = skillLanguage;
        });
      });
    });
    // console.log(this.playerMetricSkillSummary);
  }

  getSummaryMetricData() {
    this.firebase.getPlayerSkillSummaryMetricSkills(this.playerId).subscribe(data => {
      this.playerMetricSkillSummary = data;
      this.playerMetricSkillSummary.forEach(metricSkill => {
        this.firebase.getSkillsByCode(metricSkill.code).subscribe(skill => {
          metricSkill.skill = skill[0] as Skill;
          this.firebase.getSkillLanguages(metricSkill.skill.skillId).subscribe(skillLanguage => {
            metricSkill.language = skillLanguage;
          });
        });
      });
      //  console.log(this.playerMetricSkillSummary);
    });
  }

  getMetricName(metricSkill: SkillSummary): string {
    // console.log(this.locale);
    if (metricSkill.language) {
      if (this.locale.includes('en')) {
        return metricSkill.language.filter(lang => lang.langId === 'en')[0].displaySkill;
      } else {
        return metricSkill.language.filter(lang => lang.langId === 'es')[0].displaySkill;
      }
    } else {
      return metricSkill.code;
    }
  }

  showDetailClick(metricSkill: SkillSummary) {
    if (this.fromEvaluation) {
      this.showDetail = false;
      return;
    }
    this.showDetail = !this.showDetail;

    if (this.selectedSkillCode !== '' && this.selectedSkillCode !== metricSkill.code) {
      this.showDetail = !this.showDetail; // if it's a different skill then show the component with the newer skill
    }
    if (this.showDetail) {
      this.selectedSkillCode = metricSkill.code;
      this.selectedSkill = metricSkill.skill;
      this.clickedSkillName = this.getMetricName(metricSkill);
    }
  }

}
