<app-header></app-header>
<div class="container">
    <div class="example-loading-shade" *ngIf="isLoadingResults">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <!-- <div> -->
    <div>
    <h1>
        {{ title }}
    </h1>
    </div>   
    <form [formGroup]="skillListEditForm">
        <mat-grid-list cols="4" rowHeight="100px">    
            <mat-grid-tile colspan="3">
                <div class="name">
                    <mat-form-field color="accent" style="width: 25em;">
                        <input formControlName="name" matInput placeholder="Name">
                        <mat-error>This field is mandatory.</mat-error>
                    </mat-form-field>
                </div>
            </mat-grid-tile>      
            <mat-grid-tile colspan="1">
                <div class="submit" mat-dialog-actions>
                    <button mat-raised-button color="accent" type="submit" [disabled]="skillListEditForm.invalid" (click)="onSubmit()">
                        Save changes
                    </button>
                    <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
                </div>  
            </mat-grid-tile>
        </mat-grid-list>
    </form> 
    <mat-divider></mat-divider>  
    <div class="cb-wrapper" *ngIf="!this.isLoadingResults">
        <ng-container *ngFor="let category of categories">
            <h2>
                {{ category }}
            </h2>
            <ng-container *ngFor="let skill of skillCollection | categoryFilter:category">
                <mat-checkbox [(ngModel)]="skill.isSelected">
                    {{getSkillName(skill)}} ({{skill.code}})
                    <strong *ngIf="skill.type === 'title'">[Header]</strong>
                </mat-checkbox>
            </ng-container> 
        </ng-container>          
    </div>
</div>
