import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { NotificationService } from '../shared/notification.service';

@Component({
  selector: 'app-supported-dialog-form',
  templateUrl: './supported-dialog-form.component.html',
  styleUrls: ['./supported-dialog-form.component.css']
})
export class SupportedDialogFormComponent {
  public title: string;

  public supportedForm = new FormGroup({
    action: new FormControl('new'),
    playerId: new FormControl('', Validators.required),
    userId: new FormControl('', Validators.required)
  });

  constructor(
    private notification: NotificationService,
    public dialogRef: MatDialogRef<SupportedDialogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.title = 'Edit Supported';
      this.populateForm(this.data);
    } else {
      this.title = 'New Supported';
    }
  }

  initializeFormGroup() {
    this.supportedForm.setValue({
      action: 'new',
      playerId: '',
      userId: ''
    });
  }

  onClear() {
    this.supportedForm.reset();
    this.initializeFormGroup();
    this.notification.success('Cleared successfully');
  }

  onClose() {
    this.supportedForm.reset();
    this.initializeFormGroup();
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.title === 'Edit Supported') {
      this.dialogRef.close({
        supportedDocId: this.data.supportedDocId,
        ...this.supportedForm.value
      });
    } else {
      this.dialogRef.close(this.supportedForm.value);
    }
  }

  populateForm(supported) {
    this.initializeFormGroup();
    this.supportedForm.setValue({
      action: 'edit',
      playerId: supported.playerId,
      userId: supported.userId
    });
  }
}
