import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {
  public invitationCode: string;
  public today = new Date();

  constructor(private firebaseService: FirebaseService) {}


  /**
   * This method triggers an algorithm to create a unique invitation code is implemented. The algorithm gets the total of miliseconds that
   * have passed since January first 1970 and convert that number to 36 base. Then it joins the last two characters of the 36 base number,
   * a random two character code that can contain 2 letters (both can by uppercase or lowercase), the first to characters of the team id,
   * a random code that contains an uppercase letter and a number and the fourth and fifth character of the
   * 36 base number. Then by using the startsWithP function it checks if the code starts with "p".
   * If it does, then it changes the first character for another random one and by using the
   * codeIsNotRepeatedCode checks that the new generated code is not already in the DB, and if it is then
   * the function is called again. If it doesn't start with "p" then by using the
   * codeIsNotRepeatedCode checks that the generated code is not already in the DB, and if it is then
   * the function is called again.
   * @returns If the generated code is not repeated returns the code.
   */
  public async generateInvitationCode(): Promise<string> {
    const randomAlpha = await this.randomAlpha(2);
    const randomAlphaNumericUppercase = await this.randomAlphaNumericUppercase(
      2
    );
    const invitationCode =
      (await this.today
        .getTime()
        .toString(36)
        .slice(-2)) +
      randomAlpha +
      'DB' +
      randomAlphaNumericUppercase +
      this.today
        .getTime()
        .toString(36)
        .substring(3, 5);
    if (!invitationCode.startsWith('p')) {
      if (this.codeIsNotRepeated(invitationCode)) {
        return invitationCode;
      } else {
        return this.generateInvitationCode();
      }
    } else {
      if (this.codeIsNotRepeated(this.randomAlphaNumericLowerCaseNoP(1) + invitationCode.slice(1))) {
        return this.randomAlphaNumericLowerCaseNoP(1) + invitationCode.slice(1);
      } else {
        return this.generateInvitationCode();
      }
    }
  }

  public async generatePlayerInvitationCode(): Promise<string> {
    const randomAlpha = await this.randomAlpha(2);
    const randomAlphaNumericUppercase = await this.randomAlphaNumericUppercase(
      2
    );
    const invitationCode = 'p' +
      (await this.today
        .getTime()
        .toString(36)
        .slice(-2)) +
      randomAlpha +
      'DB' +
      randomAlphaNumericUppercase +
      this.today
        .getTime()
        .toString(36)
        .substring(3, 5);
    if (this.playerCodeIsNotRepeated(invitationCode)) {
        return invitationCode;
    } else {
      return this.generatePlayerInvitationCode();
    }

  }

  /**
   * This function checks all the teams info in the DB to see if a code is already saved inside a team info.
   * @param code The code to compare to all the rest.
   * @returns Returns true if the code is not repeated, false if it is.
   */
  private async codeIsNotRepeated(code: string): Promise<boolean> {
    const teamsInfoList = await this.firebaseService.getTeams().toPromise();
    const teamsListLength = teamsInfoList.length;
    for (let i = 0; i < teamsListLength; i++) {
      if (code === teamsInfoList[i].invitationCode) {
        return false;
      }
    }
    return true;
  }


  /**
   * This function checks all the teams info in the DB to see if a code is already saved inside a team info.
   * @param code The code to compare to all the rest.
   * @returns Returns true if the code is not repeated, false if it is.
   */
   private async playerCodeIsNotRepeated(code: string): Promise<boolean> {
    const playersInfoList = await this.firebaseService.getPlayers().toPromise(); // getTeams().toPromise();
    const playersListLength = playersInfoList.length;
    for (let i = 0; i < playersListLength; i++) {
      if (code === playersInfoList[i].invitationCode) {
        return false;
      }
    }
    return true;
  }

  /**
   * This method is used to generate an specific lenght random code of upper and lower case letters.
   * @param length The desired lenght for the code.
   * @returns The generated random code.
   */
  private randomAlpha(length: number) {
    let code = '';
    const char_list = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxy';
    for (let i = 0; i < length; i++) {
      code += char_list.charAt(Math.floor(Math.random() * char_list.length));
    }
    return code;
  }

 /**
   * This method is used to generate an specific lenght random code of lower case letters without "p".
   * @param length The desired lenght for the code.
   * @returns The generated random code.
   */
  private randomAlphaNumericLowerCaseNoP(length: number) {
    let code = '';
    const char_list = '1234567890abcdefghijklmnoqrstuvwxy';
    for (let i = 0; i < length; i++) {
      code += char_list.charAt(Math.floor(Math.random() * char_list.length));
    }
    return code;
  }

  /**
   * This method is used to generate an specific lenght random code of upper case letters and numbers.
   * @param length The desired lenght for the code.
   * @returns The generated random code.
   */
  private randomAlphaNumericUppercase(length: number) {
    let code = '';
    const char_list = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (let i = 0; i < length; i++) {
      code += char_list.charAt(Math.floor(Math.random() * char_list.length));
    }
    return code;
  }
}
