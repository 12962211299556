<div class="d-flex flex-sm-column flex-md-row flex-md-wrap actionsBg py-2 px-1">
    <div class="col-m-2 py-1 d-flex ml-1">
        <mat-icon aria-hidden="true" class="panelIcon align-self-center">build</mat-icon>
        <span class="px-2 panelTitle align-self-center"
        i18n="@@dashboard-actions-title">Actions</span>
    </div>
    <ng-container *ngIf="type === 'team'">
        <ng-container *ngTemplateOutlet="team"></ng-container>
    </ng-container>
    <ng-container *ngIf="type === 'player'">
        <ng-container *ngTemplateOutlet="player"></ng-container>
    </ng-container>
</div>
<ng-template #team>
    <!--<div class="col-1">-->
        <button type="button" (click)="onEdit()"
        class="btn btn btn-outline-dark ml-3"
        i18n="@@dashboard-actions-team-edit">Edit</button>
    <!--</div>
    <div class="col">-->
        <button type="button" (click)="goToMembers()"
        class="btn btn btn-outline-dark ml-3"
        i18n="@@dashboard-actions-team-view-members">View team members</button>
    <!--</div>-->
</ng-template>
<ng-template #player>
    <ng-container *ngIf="isCoach">
        <button type="button" (click)="onEvaluate()"
        class="btn btn btn-outline-dark ml-3"
        i18n="@@dashboard-actions-player-evaluate">Log training</button>
    </ng-container>
</ng-template>