import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { FirebaseService } from '../shared/firebase.service';
import { User } from '../models/user';
import { Team } from '../models/team';
import { Player } from '../models/player';
import { UserInfo } from '../models/userInfo';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NotificationService } from '../shared/notification.service';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';

export interface TeamMember {
  mappedUser: User;
  userInformation: UserInfo;
}

@Component({
  selector: 'app-team-user-list',
  templateUrl: './team-user-list.component.html',
  styleUrls: ['./team-user-list.component.css']
})
export class TeamUserListComponent implements OnInit {
  isAdmin: boolean;
  userEmail: string;

  teamId: string;
  editable: boolean;
  isLoading = true;
  userList: User[] = [];
  userInfoList: UserInfo[] = [];

  coachList: TeamMember[] = [];
  coachListFull = false;
  playerList: TeamMember[] = [];
  playerListFull = false;
  supporterList: TeamMember[] = [];
  supporterListFull = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private firebase: FirebaseService,
    private authService: AuthService,
    ) {}

  ngOnInit() {
    this.checkAdminAccount();
    this.route.queryParams.subscribe(params => {
      this.teamId = params['id'];
      this.editable = params['editable'];

      this.loadTeamUsers();

    });

    // await this.loadTeamUserInfo();
    // await this.buildCoachList();
    // await this.buildPlayerList();


  }

  checkAdminAccount() {
    this.authService.afAuth.authState.subscribe(user => {
      this.userEmail = user.email;
      // console.log(this.userEmail);

      this.firebase.getEmailAdmin(this.userEmail)
      .subscribe(data => {
        this.isAdmin = false;
        const resultQty = data.length;
        // console.log(resultQty);
        if (resultQty > 0) {
          this.isAdmin = true;
        }
        // console.log(this.isAdmin);
      });

    });
  }

  async loadTeamUsers() {
    this.firebase.getTeamUsers(this.teamId).subscribe(data => {
      this.isLoading = true;
      this.userList = [];
      this.userInfoList = [];

      data.forEach(u => {
        this.userList.push(u as User);
      });
      // console.log('userlist ' + this.userList.length);
      this.loadTeamUserInfo();

      this.isLoading = false;
    });

  }

  async loadTeamUserInfo() {
    let tempInfo: UserInfo;
    this.userInfoList = [];
    let teamMember: TeamMember;


    this.userList.forEach(u => {
      this.firebase.getUserInfoById(u.userId).subscribe(data => {
        tempInfo = data[0] as UserInfo;
        if (!tempInfo.email) {
          tempInfo.email = '';
        }

        teamMember = {
          mappedUser: u,
          userInformation: tempInfo
        };
        this.userInfoList.push(tempInfo);
        switch (u.role) {
          case 'Coach': {
            this.coachList.push(teamMember);
            break;
          }
          case 'Player': {
            this.playerList.push(teamMember);
            break;
          }
          case 'Supporter': {
            this.supporterList.push(teamMember);
            break;
          }
        }

      });
    });

    // console.log(this.coachList.length);
    this.coachListFull = true;
    this.playerListFull = true;
    this.supporterListFull = true;
  }

}
