import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild, LOCALE_ID, Inject  } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { PlayerDrill } from '../models/playerDrill';
import { User } from '../models/user';
import { FirebaseService } from '../shared/firebase.service';
import { NotificationService } from '../shared/notification.service';
/* import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog'; */

export interface Category {
  catValue: string;
  catViewValue: string;
}

@Component({
  selector: 'app-create-drill-admins',
  templateUrl: './create-drill-admins.component.html',
  styleUrls: ['./create-drill-admins.component.css']
})
export class CreateDrillAdminsComponent implements OnInit {
  @ViewChild('filesInput')
  filesInputVariable: ElementRef;
  private sort: MatSort;
  private displayedColumns: string[] = ['select', 'drillName'];
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  URL_REGEXP = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
  isHovering: boolean;
  PreSubmitFiles: File[] = [];
  files: File[] = [];

  private userTeams: User[] = [];
  private drillList: PlayerDrill[] = [];
  private isteamSelected = false;

  private dataSource = new MatTableDataSource([]);
  private initialSelection = [];
  private allowMultiSelect = true;
  private selection = new SelectionModel<any>(
    this.allowMultiSelect,
    this.initialSelection
  );

  public title = 'Add training drill to ';
  public isTeam: boolean;
  private entityName: string;
  private entityId: string;
  private drillLang = 'en';
  private drillActive = true;
  private uploadingLink = false;
  private internalDrillLink = 'drill?id=';
  private addedTrainingSuccessLabel = ' drill(s) copied';
  private drillAddedSuccessLabel = 'Drill added';



  categories: Category[] = [
    // { catValue: '--0', catViewValue: '-' },
    { catValue: 'PH', catViewValue: 'Physical' },
    { catValue: 'BE', catViewValue: 'Behavioral' },
    { catValue: 'TA', catViewValue: 'Tactical' },
    { catValue: 'TE', catViewValue: 'Technical' }
  ];

  public linkDrillForm = new FormGroup({
    action: new FormControl('new'),
    type: new FormControl('link'),
    lang: new FormControl(this.drillLang),
    active: new FormControl(this.drillActive),
    linkDrillName: new FormControl('', Validators.required),
    linkDrillLink:  new FormControl('', [Validators.pattern(this.URL_REGEXP), Validators.required]),
    linkDrillCategory: new FormControl( Validators.required),
    linkDrillText: new FormControl('')
  });

  public instructionsDrillForm = new FormGroup({
    action: new FormControl('new'),
    type: new FormControl('customMedia'),
    lang: new FormControl(this.drillLang),
    active: new FormControl(this.drillActive),
    instructionsDrillName: new FormControl('', Validators.required),
    instructionsDrillCategory: new FormControl( Validators.required),
    instructionsDrillText: new FormControl('', Validators.required)
  });

  public fileDrillForm = new FormGroup({
    action: new FormControl('new'),
    type: new FormControl('link'),
    lang: new FormControl(this.drillLang),
    active: new FormControl(this.drillActive),
    fileDrillName: new FormControl('', Validators.required),
    fileDrillCategory: new FormControl('', Validators.required),
    fileDrillText: new FormControl('')
  });

  constructor(
    private firebase: FirebaseService,
    private notification: NotificationService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private functions: AngularFireFunctions,
    @Inject(LOCALE_ID) public locale: string
    // public dialogRef: MatDialogRef<CreateDrillAdminsComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.internalDrillLink = environment.baseURL + this.internalDrillLink;
    // console.log(this.internalDrillLink);
  }

  ngOnInit() {
    this.setLocale();
    this.dataSource = new MatTableDataSource(this.drillList);
    this.route.queryParams.subscribe(params => {
      if (params['type'] === 'team') {
        this.isTeam = true;
      } else {
        this.isTeam = false;
      }

      this.entityId = params['id'];
      this.entityName = params['name'];
      this.title += this.entityName;
      this.linkDrillForm.addControl('id', new FormControl(this.entityId));
      this.fileDrillForm.addControl('id', new FormControl(this.entityId));
      this.instructionsDrillForm.addControl('id', new FormControl(this.entityId));

      this.firebase.getMappedTeams(this.authService.getUser.uid).subscribe((teams: User[]) => {
        teams.forEach((team: User) => {
          if (team.teamId !== this.entityId) {
            this.userTeams.push(team as User);
          }
        });
      });

      if (!this.entityId) {
        this.router.navigate(['/home']);
      }
    });
  }

  initializeFormGroup() {
    this.linkDrillForm.setValue({
      linkDrillName: '',
      linkDrillLink: '',
      linkDrillCategory: '',
      linkDrillText: '',
      id: '',
      action: 'new',
      type: new FormControl('link'),
      lang: new FormControl(this.drillLang),
      active: new FormControl(this.drillActive)
    });
    this.fileDrillForm.setValue({
      fileDrillName: '',
      fileDrillLink: '',
      fileDrillCategory: '',
      fileDrilltext: '',
      id: '',
      action: 'new',
      type: new FormControl('file'),
      lang: new FormControl(this.drillLang),
      active: new FormControl(this.drillActive)
    });
    this.instructionsDrillForm.setValue({
      instructionsDrillName: '',
      instructionsDrillCategory: '',
      instructionsDrillText: '',
      instructionsDrillLink: '',
      id: '',
      action: 'new',
      type: new FormControl('customMedia'),
      lang: new FormControl(this.drillLang),
      active: new FormControl(this.drillActive)
    });
  }

  onClose() {
    this.linkDrillForm.reset();
    this.initializeFormGroup();
    // this.dialogRef.close();
  }

  onSubmitLink() {
    // this.dialogRef.close(this.linkDrillForm.value);]
    if (this.linkDrillForm.get('linkDrillText').value === '') {
      // console.log('addLinkDrillNoComments');
      this.firebase.addPlayerDrillLink(
        this.linkDrillForm.get('id').value,
        this.linkDrillForm.get('linkDrillName').value,
        this.linkDrillForm.get('linkDrillCategory').value,
        this.linkDrillForm.get('linkDrillLink').value,
        this.linkDrillForm.get('lang').value,
        this.linkDrillForm.get('active').value
      );
    } else {
      // console.log('addLinkDrillWithComments');
      this.addLinkDrillWithComments();
    }

    this.notification.success(this.drillAddedSuccessLabel);
    if (this.isTeam) {
      const today = new Date();
      const callableFunction = this.functions.httpsCallable('sendNewDrillNotificationToTeam');
      const response = callableFunction(
        { teamId: this.entityId,
          date: today.getTime(),
          userIdRequester: this.authService.getUser.uid
        }
      );
    }
    this.navigateAfterSubmit();
  }

  onSubmitFile() {

    for (let i = 0; i < 1; i++) {
      this.files.push(this.PreSubmitFiles[i]);
    }
  }

  finalizeSubmitFile(result: any) {
    if (this.fileDrillForm.get('fileDrillText').value === '') {
      this.addFileDrillNoComments(result);

    } else {
      this.addFileDrillWithComments(result);
    }
    this.notification.success(this.drillAddedSuccessLabel);
    if (this.isTeam) {
      const today = new Date();
      const callableFunction = this.functions.httpsCallable('sendNewDrillNotificationToTeam');
      const response = callableFunction(
        { teamId: this.entityId,
          date: today.getTime(),
          userIdRequester: this.authService.getUser.uid
        }
      );
    }
    this.navigateAfterSubmit();
  }

  async onSubmitInstructions() {
    // se obtiene el id
    const docId = await this.firebase.createId();
    // console.log(docId);
    // se reemplaza el drilllink con el link nuevo
    const newDrillLink = this.internalDrillLink + docId;
    // console.log(newDrillLink);

    // se crea el documento
    const newDrill: PlayerDrill = {
      playerDrillId: docId,
      playerId: this.instructionsDrillForm.get('id').value,
      drillName: this.instructionsDrillForm.get('instructionsDrillName').value,
      drillCategory: this.instructionsDrillForm.get('instructionsDrillCategory').value,
      drillLink: newDrillLink,
      linkLanguage: this.instructionsDrillForm.get('lang').value,
      active: true,
      drillText: this.instructionsDrillForm.get('instructionsDrillText').value,
      drillMediaURL: '',
      drillMediaPath: '',
      drillMediaFormat: 'text',
      drillType: 'customMedia'
      };

    // se agrega el documento a la coleccion
    this.firebase.addCustomDrill(newDrill);
    this.notification.success(this.drillAddedSuccessLabel);
    if (this.isTeam) {
      const today = new Date();
      const callableFunction = this.functions.httpsCallable('sendNewDrillNotificationToTeam');
      const response = callableFunction(
        { teamId: this.entityId,
          date: today.getTime(),
          userIdRequester: this.authService.getUser.uid
        }
      );
    }
    this.navigateAfterSubmit();
  }

  async addLinkDrillWithComments() {
    // se obtiene el id
    const docId = await this.firebase.createId();
    // se reemplaza el drilllink con el link nuevo
    const newDrillLink = this.internalDrillLink + docId;

    // se crea el documento
    const newDrill: PlayerDrill = {
      playerDrillId: docId,
      playerId: this.linkDrillForm.get('id').value,
      drillName: this.linkDrillForm.get('linkDrillName').value,
      drillCategory: this.linkDrillForm.get('linkDrillCategory').value,
      drillLink: newDrillLink,
      linkLanguage: this.linkDrillForm.get('lang').value,
      active: true,
      drillText: this.linkDrillForm.get('linkDrillText').value,
      drillMediaURL: this.linkDrillForm.get('linkDrillLink').value,
      drillMediaPath: '',
      drillMediaFormat: 'link',
      drillType: 'customLink'
      };

    // se agrega el documento a la coleccion
    this.firebase.addCustomDrill(newDrill);

  }

  addFileDrillNoComments(result: any) {
    this.firebase.addPlayerDrillLink(
      this.fileDrillForm.get('id').value,
      this.fileDrillForm.get('fileDrillName').value,
      this.fileDrillForm.get('fileDrillCategory').value,
      result.downloadURL,
      this.fileDrillForm.get('lang').value,
      this.fileDrillForm.get('active').value
    );
  }

  async addFileDrillWithComments(result: any) {
    // se obtiene el id
    const docId = await this.firebase.createId();
    // se reemplaza el drilllink con el link nuevo
    const newDrillLink = this.internalDrillLink + docId;
    // se crea el documento
    const newDrill: PlayerDrill = {
      playerDrillId: docId,
      playerId: this.fileDrillForm.get('id').value,
      drillName: this.fileDrillForm.get('fileDrillName').value,
      drillCategory: this.fileDrillForm.get('fileDrillCategory').value,
      drillLink: newDrillLink,
      linkLanguage: this.fileDrillForm.get('lang').value,
      active: true,
      drillText: this.fileDrillForm.get('fileDrillText').value,
      drillMediaURL: result.downloadURL,
      drillMediaPath: result.path,
      drillMediaFormat: result.format,
      drillType: 'customMedia'
      };
    // se agrega el documento a la coleccion
    this.firebase.addCustomDrill(newDrill);

  }

  async onCopyDrills() {
    await this.asyncForEach(this.selection.selected, (copiedDrill: PlayerDrill) => {
      copiedDrill.playerId = this.entityId;
      if (copiedDrill.drillText) {
        this.firebase.addCustomCopiedDrill(copiedDrill);
      } else {
        this.firebase.addCustomCopiedDrillNoText(copiedDrill);
      }
      if (this.isTeam) {
        const today = new Date();
        const callableFunction = this.functions.httpsCallable('sendNewDrillNotificationToTeam');
        const response = callableFunction(
          { teamId: this.entityId,
            date: today.getTime(),
            userIdRequester: this.authService.getUser.uid
          }
        );
      }
      this.notification.success(this.selection.selected.length + this.addedTrainingSuccessLabel );
      this.navigateAfterSubmit();
    });
  }

  navigateAfterSubmit() {
    if (this.isTeam) {
      this.router.navigate(['/team-dashboard', this.entityId]);
    } else {
      this.router.navigate(['/player-dashboard', this.entityId], {
        queryParams: { 'isCoach': 'true'}
      });
    }
  }
  /* * * * * * * * *
  * uploader code  *
  * * * * * * * *  */
  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < 1; i++) {
      this.PreSubmitFiles.push(files.item(i));
    }
  }

  cambioArchivo(event: any) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.PreSubmitFiles.push(event.target.files.item(i));
    }
    this.resetInput();
  }

  resetInput() {
    this.filesInputVariable.nativeElement.value = '';
  }

  /* * * * * * * * * * * * * * * * * *
  * copy drills table and form code **
  * * * * * * * * * * * * * * * * * **/

  async teamSelected(event: any) {
    this.drillList = [];
    this.dataSource = new MatTableDataSource(this.drillList);
    this.selection = new SelectionModel<any>(
    this.allowMultiSelect,
    this.initialSelection
  );
    if (event.isUserInput) {
      if (event.source.value !== 'none') {
        this.isteamSelected = true;
        this.firebase
        .getPlayerDrillsWhereTeamId(event.source.value)
        .subscribe(async (tDrill) => {
          await this.asyncForEach(tDrill, (tD) => {
            this.drillList.push(tD as PlayerDrill);
          });
          this.dataSource = new MatTableDataSource(this.drillList);
        });
      } else {
        this.isteamSelected = false;
      }
    }
  }

  setDataSourceAttributes() {
    this.dataSource.sort = this.sort;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PlayerDrill): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} drill ${
      row.drillName
    }`;
  }

  /* * * * * * *
  * other code  *
  * * * * * * * */
  populateForm(drill) {

  }

  onTabChange() {
    this.uploadingLink = !this.uploadingLink;
  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }


  setLocale() {
    if (this.locale.includes('es')) {
      this.setSpanishText();
    }
  }

  setSpanishText() {
    this.title = 'Agregar Entrenamiento a ';
    this.addedTrainingSuccessLabel = ' entrenamiento(s) copiado(s)';
    this.drillLang = 'es';
    this.drillAddedSuccessLabel = 'Entrenamiento agregado';
    this.categories[0].catViewValue = 'Físico';
    this.categories[1].catViewValue = 'Mental / Psicológico';
    this.categories[2].catViewValue = 'Táctico';
    this.categories[3].catViewValue = 'Técnico';
  }

}
