import { Component, Input, OnInit } from '@angular/core';
import { EvaluationItem } from 'src/app/models/evaluationItem';
import { EvaluationItemResult } from 'src/app/models/evaluationItemResult';

@Component({
  selector: 'app-eval-panel',
  templateUrl: './eval-panel.component.html',
  styleUrls: ['./eval-panel.component.css']
})
export class EvalPanelComponent implements OnInit {

  @Input()
  evaluationList: EvaluationItem[];
  @Input()
  mode: boolean;
  @Input()
  attendance: boolean;
  poorEval: number = EvaluationItemResult.Poor;
  fairEval: number = EvaluationItemResult.Fair;
  neutralEval: number = EvaluationItemResult.Average;
  goodEval: number = EvaluationItemResult.Good;
  excellentEval: number = EvaluationItemResult.Excellent;

  constructor(
  ) { }

  ngOnInit() {
  }

  changeEvaluation(e: EvaluationItem, status: EvaluationItemResult) {
    if (this.mode) {
      e.skillResult = status;
    }
  }
}
