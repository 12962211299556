import { Component, OnInit, Input, LOCALE_ID, Inject } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';

@Component({
  selector: 'app-category-evolution-dashboard',
  templateUrl: './category-evolution-dashboard.component.html',
  styleUrls: ['./category-evolution-dashboard.component.css']
})
export class CategoryEvolutionDashboardComponent implements OnInit {
  @Input() evolutionValues: number[];
  @Input() evolutionDates: string[];

  private lineColor = '#343945';
  scoreLabel = 'Puntaje';

  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            min: 0,
            display: false,
            fontColor: '#343945',
            fontFamily: '\'Roboto\', sans-serif'
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: false,
            maxTicksLimit: 4,
            beginAtZero: true,
            fontColor: '#000000',
            fontFamily: '\'Roboto\', sans-serif'
          },
          gridLines: {
            zeroLineWidth: 1,
            zeroLineColor: '#a9a9a9',
            lineWidth: 0,
            drawBorder: false
          }
        }
      ]
    },
    elements: {
      line: {
        tension: 0 // disables bezier curves
      },
      point: {
        radius: 0
      }
    },
    legend: {
      display: false
   },
   tooltips: {
      enabled: false
   },
   hover: {
    intersect: false
  }

  };
  public lineChartType: ChartType = 'line';
  public lineChartLegend = false;

  public lineChartData: ChartDataSets[] = [
    {
      data: [],
      backgroundColor: '#ffffff', // evolucion
      label: 'Puntaje',
      borderColor: this.lineColor,
      pointHoverRadius: 0
    }
  ];

  public lineChartLabels: string[] = [];

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  ngOnInit() {
    this.setLocalizedMessages();
    this.lineChartData[0].label = this.scoreLabel;
    this.lineChartData[0].data = this.evolutionValues.slice(-10);
    this.lineChartLabels = this.evolutionDates.slice(-10);
    this.calculateYaxisLimits(); // -> this was substituted with begintatzero property
  }

  setLocalizedMessages() {
    if (this.locale.includes('en')) {
      this.scoreLabel = 'Score';
    }
  }

  calculateYaxisLimits() {
    const min = Math.min(...this.evolutionValues);
    const max = Math.max(...this.evolutionValues);

    if (Math.abs(max) >= Math.abs(min)) {
      this.lineChartOptions.scales.yAxes[0].ticks.min = -Math.abs(max) - 1;
      this.lineChartOptions.scales.yAxes[0].ticks.max = Math.abs(max) + 1;
    } else {
      this.lineChartOptions.scales.yAxes[0].ticks.min = -Math.abs(min) - 1;
      this.lineChartOptions.scales.yAxes[0].ticks.max = Math.abs(min) + 1;
    }
  }
}
