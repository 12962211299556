import { enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
declare const require;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [
    {
      provide: TRANSLATIONS,
      useFactory: () => {
        const locale = localStorage.getItem('language');
        const translations = locale ?
          require(`raw-loader!./locale/messages.${locale}.xlf`) :
          require(`raw-loader!./locale/messages.en.xlf`);
        return translations;
      },
      deps: []
    },
    {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'}
  ]
});
