import { Component, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { Supported } from '../models/supported';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig
} from '@angular/material/dialog';
import { SupportedDialogFormComponent } from '../supported-dialog-form/supported-dialog-form.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationService } from '../shared/notification.service';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-supporteds',
  templateUrl: './supporteds.component.html',
  styleUrls: ['./supporteds.component.css']
})
export class SupportedsComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public displayedColumns: string[] = ['playerId', 'userId', 'actions'];
  public dataSource = new MatTableDataSource();
  private initialSelection = [];
  private allowMultiSelect = true;
  private selection = new SelectionModel<any>(this.allowMultiSelect, this.initialSelection);

  private supportedsList: Supported[];

  public title = 'Supporteds';

  public isLoadingResults = true;

  constructor(private firebase: FirebaseService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private notification: NotificationService) {}

  ngOnInit() {
    this.firebase.getAllSupporteds().subscribe(data => {
      this.supportedsList = [];
      data.forEach(s => {
        this.supportedsList.push(s as Supported);
      });
      this.dataSource = new MatTableDataSource(this.supportedsList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.selection = new SelectionModel<Supported>(this.allowMultiSelect, this.initialSelection);
      this.isLoadingResults = false;
    });
  }

  doFilter(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

    /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Supported): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} userInfo ${row.playerId}`;
  }

  onNewSupported(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '55%';
    const dialogRef = this.dialog.open(SupportedDialogFormComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.firebase.addSupported(result.playerId, result.userId);
        this.notification.success('User info added');
      }
    });
  }

  onEditSupported(row) {
    const dialogRef = this.dialog.open(SupportedDialogFormComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.firebase.editSupported(result.supportedDocId, result.playerId, result.userId);
        this.notification.success('User info edited');
      }
    });
  }

  onDeleteSupported(row) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '30%',
      disableClose: true,
      autoFocus: true,
      data: { action: 'delete user info', object: row }
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.firebase.deleteSupported(row.supportedDocId);
        this.notification.success('User info deleted');
      }
    });
  }

}
