import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMessage } from 'src/app/models/app-message';
// import { UserInfo } from 'src/app/models/userInfo';
import { Player } from 'src/app/models/player';
import { Team } from 'src/app/models/team';
import { FirebaseService } from '../../shared/firebase.service';
import { NotificationService } from '../../shared/notification.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService } from '../../auth/auth.service';
import { flatMap, map } from 'rxjs/operators';
// import { HeaderComponent } from 'src/app/header/header.component';
// import { MatLabel, MatOption, MatSelect, MatFormField, MatError } from '@angular/material';

@Component({
  selector: 'app-create-message',
  templateUrl: './create-message.component.html',
  styleUrls: ['./create-message.component.css']
})

export class CreateMessageComponent implements OnInit {

  public isTeam: boolean;
  entityName: string;
  private entityId: string;

  private isLoadingResults = true;

  private player: Player;
  private team: Team;
  myUserId: string;

  public messagesForm = new FormGroup({
    action: new FormControl('new'),
    messageBody: new FormControl('', Validators.required),
    messageSubject: new FormControl('',  Validators.required)
  });

  constructor(private firebase: FirebaseService
    , private notification: NotificationService
    , private authService: AuthService
    , private router: Router
    , private route: ActivatedRoute
    , private functions: AngularFireFunctions
    , @Inject(LOCALE_ID) public locale: string) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      params['type'] === 'team' ? this.isTeam = true : this.isTeam = false;

      this.entityId = params['id'];
      this.entityName = params['name'];

      if (!this.entityId) {
        this.notification.error(this.getErrorMessage());
        this.router.navigate(['/home']);
      }

      this.myUserId = this.authService.getUser.uid;
    });
    this.isLoadingResults = true;
    if (!this.isTeam) {
      this.firebase.getPlayer(this.entityId)
        .pipe(
          flatMap((playerData: Player) => {
            // console.log(playerData);
            return this.firebase.getTeam(playerData.teamId)
            .pipe(map(playersTeam => ({ playerData, playersTeam})));
          })
        )
      .subscribe( ({ playerData, playersTeam }) => {
        // console.log(playerData);
        // console.log(playersTeam);
        this.player = playerData as Player;
        this.team = playersTeam as Team;
        this.isLoadingResults = false;
      });
    }
  }

  initializeFormGroup() {
    this.messagesForm.setValue({
      messageSubject: '',
      messageBody: '',
      action: 'new',
    });
  }

  async onSubmitMessage() {
    if (this.isTeam) {
      await this.sendTeamMessage();
    } else {
      await this.sendPlayerMessage();
    }
    this.navigateAfterSubmit();
  }

  async sendTeamMessage() {
    const body: string = this.messagesForm.get('messageBody').value;
    const newMessage: AppMessage = {
      userId: this.myUserId,
      groupId: this.entityId,
      message: body,
      audience: 'all',
      dateTime: new Date(),
      subject: this.messagesForm.get('messageSubject').value,
    };
    this.firebase.addMessage(newMessage);

    const  callableFunction = this.functions.httpsCallable('sendMessageNotificationToTeam');
    const response = callableFunction({
      teamId: this.entityId,
      teamName: this.entityName,
      message: body,
    });

    this.notification.success(this.getSuccessMessage());

  }

  async sendPlayerMessage() {
    const body: string = this.messagesForm.get('messageBody').value;
    const subj = this.messagesForm.get('messageSubject').value;
    const msjDate = new Date();
    const newMessage: AppMessage = {
      userId: this.myUserId,
      groupId: this.player.teamId,
      message: body,
      audience: this.player.userId ? this.player.userId : this.entityId,
      dateTime: msjDate ,
      subject: subj,
    };
    // console.log(newMessage);
    this.firebase.addMessage(newMessage);

    const  callableFunction = this.functions.httpsCallable('sendMessageNotificationToPlayer');
    const response = callableFunction({
      isUser: this.player.userId ? true : false,
      id: this.player.userId ? this.player.userId : this.entityId,
      teamName: this.team.name,
      message: newMessage.message,
      });
    this.notification.success(this.getSuccessMessage());
  }

  getSuccessMessage(): string {
    return (this.locale.includes('en') ? 'Message sent' : 'Mensaje enviado');
  }

  getErrorMessage(): string {
    return (this.locale.includes('en') ? 'Can\'t send message' : 'El mensaje no puede enviarse');
  }

  navigateAfterSubmit() {
    if (this.isTeam) {
      this.router.navigate(['/team-dashboard', this.entityId]);
    } else {
      this.router.navigate(['/player-dashboard', this.entityId], {
        queryParams: { 'isCoach': 'true'}
      });
    }
  }

}
