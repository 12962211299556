import { SelectionModel } from '@angular/cdk/collections';
import { ViewChild, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Directive()
export class Table {
    public title: string;
    @ViewChild(MatSort, { static: true })
    protected sort: MatSort;
    @ViewChild(MatPaginator, { static: true })
    protected paginator: MatPaginator;
    public isLoadingResults: boolean;
    public dataSource: MatTableDataSource<any>;
    protected dataSet: any;
    protected selection: SelectionModel<any>;
    protected dialogConfig: MatDialogConfig;

    constructor(title: string, dataSet: any, initialSelection: any, allowMultiSelect: boolean) {
      this.selection = new SelectionModel<any>(allowMultiSelect, initialSelection);
      this.isLoadingResults = true;
      this.dataSet = dataSet;
      this.title = title;
      this.dialogConfig = new MatDialogConfig();
      this.dialogConfig .disableClose = true;
      this.dialogConfig .autoFocus = true;
    }

    doFilter(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource ? this.dataSource.data.length : 0;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} ${this.title.toLowerCase()} ${row.name}`;
    }
  }
