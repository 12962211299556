<app-header></app-header>
<div class="container main-div">
    <div class="dashboardHeader row">
        <div class="dashboardInfo col">
            <h1 class="dashboardTitle">
                <span i18n="@@create-message-title">Send message to</span>
                <span> {{entityName}}</span>
            </h1>
        </div>
    </div>
    <form [formGroup]="messagesForm" class="normal-form py-4">
        <div class="controles-container">
            <div class="row">
                <mat-form-field color="accent" class="col-md-4"  floatLabel="always">
                    <mat-label i18n="@@create-message-subject-label">Subject</mat-label>
                    <mat-select formControlName="messageSubject">
                        <mat-option value="development recommendation"
                        i18n="@@create-message-subject-options-dev-recommendation">
                            Development recommendation
                        </mat-option>
                        <mat-option value="Intake"
                        i18n="@@create-message-subject-options-intake">
                            Intake
                        </mat-option>
                        <mat-option value="Progress note"
                        i18n="@@create-message-subject-options-progress-note">
                            Progress note
                        </mat-option>
                        <mat-option value="position evaluation"
                        i18n="@@create-message-subject-options-position-evaluation">
                            Position evaluation
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="col" color="accent">
                    <mat-label  i18n="@@create-message-body-label">Message body</mat-label>
                    <textarea matInput cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8"
                    formControlName="messageBody"></textarea>
                    <mat-error  i18n="@@create-message-body-error">Please fill this field</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="button-row" mat-dialog-actions>
            <button mat-raised-button color="accent" type="submit" [disabled]="messagesForm.invalid" (click)="onSubmitMessage()" class="py-2">
                Send Message
            </button>
        </div>
    </form>
</div>
