import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../shared/firebase.service';
import { Team } from '../../models/team';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TeamDialogFormComponent } from '../team-dialog-form/team-dialog-form.component';
import { NotificationService } from '../../shared/notification.service';
import { DeleteConfirmationDialogComponent } from '../../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { Table } from '../../models/table';


@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent extends Table implements OnInit {

  displayedColumns: string[] = ['select', 'name', 'year', 'skillslist', 'invitationCode', 'teamId',
                                'userId', 'premium', 'actions'];

  constructor(private firebaseService: FirebaseService,
              private authService:  AuthService,
              private router: Router,
              public dialog: MatDialog,
              private notificationService: NotificationService) {
      super('Teams', new Array<Team>(), [], true);
    }

  ngOnInit() {
    this.firebaseService.getTeams().subscribe(
      data => {
        this.dataSet = [...data];
        this.dataSource = new MatTableDataSource(this.dataSet);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isLoadingResults = false;
      },
      error => {
        console.error(error);
        this.notificationService.error('Teams data could not be fetched');
        this.isLoadingResults = false;
      }
    );
  }

  onAdd() {
    this.dialogConfig.width = '40%';
    this.dialogConfig.data = { callingFrom: 'teamGrid' };
    const dialogRef = this.dialog.open(TeamDialogFormComponent, this.dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoadingResults = true;
        if (result.invitationCode === '') { result.invitationCode = null; }
        if (result.teamColor === '') { result.teamColor = '#FD2A5C'; }
        if (result.teamLogo === '') { result.teamLogo = 'teams/logos/default.png'; }
        if (result.teamVideoCover === '') { result.teamVideoCover = 'teams/videoCovers/defaultVideoCover.jpg'; }
        if (!result.skillListId || result.skillListId === '') { result.skillListId = 'default'; }
        if (result.invitationCode) {
          this.firebaseService.addTeamInvitationCode(result.name, result.year, result.userId,
                                                      result.skillListId, result.invitationCode,
                                                      result.teamColor, result.teamLogo, result.premium,
                                                      result.teamVideoCover, result.membershipMonthCutOffDay)
            .then(
              r => {
                if (r.status) {
                  this.notificationService.success('Team added');
                } else {
                  console.error(r.message);
                  this.notificationService.error('Team could not be added');
                }
              })
            .finally(() => this.isLoadingResults = false);
        } else {
          this.firebaseService.addTeam(result.name, result.year, result.userId, result.skillListId,
                                        result.invitationCode, result.teamColor, result.teamLogo,
                                        result.premium, result.teamVideoCover, result.membershipMonthCutOffDay)
            .then(
              r => {
                if (r.status) {
                  this.notificationService.success('Team added');
                } else {
                  console.error(r.message);
                  this.notificationService.error('Team could not be added');
                }
              })
            .finally(() => this.isLoadingResults = false);
        }
      }
    });
  }

  onEdit(row: Team) {
    if (!row.skillListId) { row.skillListId = ''; }
    const dialogRef = this.dialog.open(TeamDialogFormComponent, {
      width: '40%',
      disableClose: true,
      autoFocus: true,
      data: {callingFrom: 'teamGrid', ...row} }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.invitationCode === '') { result.invitationCode = null; }
        if (result.teamColor === '') { result.teamColor = '#FD2A5C'; }
        if (result.teamLogo === '') { result.teamLogo = 'teams/logos/default.png'; }
        if (result.teamVideoCover === '') { result.teamVideoCover = 'teams/videoCovers/defaultVideoCover.jpg'; }
        if (!result.skillListId || result.skillListId === '') { result.skillListId = 'default'; }
        if (result.invitationCode) {
            this.firebaseService.editTeamInvitationCode(
              row.id, result.name, result.year, result.userId, result.skillListId, result.invitationCode,
              result.teamColor, result.teamLogo, result.premium, result.teamVideoCover, result.membershipMonthCutOffDay)
            .then(
                r => {
                  if (r.status) {
                    this.notificationService.success('Team edited');
                  } else {
                    console.error(r.message);
                    this.notificationService.warn('Team could not be edited');
                  }
                })
            .finally(() => this.isLoadingResults = false);
        } else {
          this.firebaseService.editTeam(row.id, result.name, result.year, result.userId, result.skillListId,
                                        result.invitationCode, result.teamColor, result.teamLogo,
                                        result.premium, result.teamVideoCover, result.membershipMonthCutOffDay)
          .then(
            r => {
              if (r.status) {
                this.notificationService.success('Team edited');
              } else {
                console.error(r.message);
                this.notificationService.error('Team could not be edited');
              }
            })
          .finally(() => this.isLoadingResults = false);
        }
      }
    });
  }

  onDelete(row: Team) {
    this.dialogConfig.width = '30%';
    this.dialogConfig.data = {action: 'delete team', object: row};
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.isLoadingResults = true;
        this.firebaseService.deleteTeam(row.id)
          .then(
            r => {
              if (r.status) {
                this.notificationService.success('Team deleted');
              } else {
                console.error(r.message);
                this.notificationService.error('Team could not be deleted');
              }
            })
          .finally(() => this.isLoadingResults = false);
      }
    });
  }
}
