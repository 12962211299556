import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-team-dialog-form',
  templateUrl: './team-dialog-form.component.html',
  styleUrls: ['./team-dialog-form.component.css']
})
export class TeamDialogFormComponent {
  @ViewChild('filesInput')
  filesInputVariable: ElementRef;

  public title: string;
  isHovering: boolean;
  PreSubmitFiles: File[] = [];
  files: File[] = [];
  disabledFields: string[];
  defaultValueList: any[];

  public teamForm = new FormGroup({
    action: new FormControl('new'),
    name: new FormControl('', Validators.required),
    userId: new FormControl('', Validators.required),
    year: new FormControl('', Validators.required),
    skillListId: new FormControl(''),
    invitationCode: new FormControl(null),
    teamColor: new FormControl(''),
    teamLogo: new FormControl(''),
    teamVideoCover: new FormControl(''),
    premium: new FormControl(false, Validators.required),
    membershipMonthCutOffDay: new FormControl(1, Validators.compose(
      [Validators.min(1), Validators.required, Validators.max(31)]))
  });

  constructor(private notification: NotificationService,
              public dialogRef: MatDialogRef<TeamDialogFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                if (this.data.name) {
                  this.title = 'Editar Equipo';
                  this.populateForm(this.data);
                } else {
                  this.title = 'Nuevo Equipo';
                }
                this.disabledFields = this.data.disabledFields || [];
                this.defaultValueList = this.data.defaultValueList || [];
                this.applyRules();
              }


  initializeFormGroup() {
    this.teamForm.setValue({
      action: 'new',
      name: '',
      userId: '',
      year: '',
      skillListId: '',
      invitationCode: null,
      teamColor: '',
      teamLogo: '',
      teamVideoCover: '',
      premium: false,
      membershipMonthCutOffDay: 1

    });
  }

  applyRules() {
    if (this.disabledFields.length > 0) {
      this.disabledFields.forEach(field => this.teamForm.controls[field].disable());
    }
    if (this.defaultValueList.length > 0) {
      this.defaultValueList.forEach(input => this.teamForm.controls[input.field].setValue(input.value));
    }
  }

  onClear() {
    this.teamForm.reset();
    this.initializeFormGroup();
    this.notification.success('Borrado con éxito');
  }

  onClose() {
    this.teamForm.reset();
    this.initializeFormGroup();
    this.dialogRef.close();
  }

  async onSubmit() {
    if (this.PreSubmitFiles.length > 0) {
      for (let i = 0; i < 1; i++) {
        this.files.push(this.PreSubmitFiles[i]);
      }
    } else {
      this.dialogRef.close(this.teamForm.value);
    }
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < 1; i++) {
      this.PreSubmitFiles.push(files.item(i));
    }
  }

  cambioArchivo(event: any) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.PreSubmitFiles.push(event.target.files.item(i));
    }
    this.resetInput();
  }

  resetInput() {
    this.filesInputVariable.nativeElement.value = '';
  }

  populateForm(team) {
    this.initializeFormGroup();
    this.teamForm.setValue({
      action: 'edit',
      name: team.name,
      userId: team.userId ? team.userId : null,
      year: team.year,
      skillListId: team.skillListId ? team.skillListId : '',
      invitationCode: team.invitationCode ? team.invitationCode : null,
      teamColor: team.teamColor,
      teamLogo: team.teamLogo,
      teamVideoCover: team.teamVideoCover,
      premium: team.premium,
      membershipMonthCutOffDay: team.membershipMonthCutOffDay ? team.membershipMonthCutOffDay : 1
    });
  }

  finalizeSubmitFile(result: any) {
    console.log('Path: ', result.path);
    this.teamForm.value.teamLogo = result.path;
    this.dialogRef.close(this.teamForm.value);
  }

}
